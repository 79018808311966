import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';

import { driverTheme } from 'common/theme/index';

import { ResetCSS } from 'common/assets/css/style';

import { DrawerProvider } from 'common/contexts/DrawerContext';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import Navbar from 'containers/Navbar';
import MainDriver from 'containers/MainDriver';
import FeaturesDriver from 'containers/FeaturesDriver';
import FeaturesAdvanced from 'containers/FeaturesAdvanced';
import Partner from 'containers/Partner';
import ContactForm from 'containers/Contact';
import Footer from 'containers/Footer';

import Seo from 'components/seo';

import Separator from 'common/components/Separator';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/index.style.js';

const Driver = ({ location }) => {
  const Data = useStaticQuery(graphql`
    query DriverPageData {
      driverJson {
        seoData {
          driver {
            title
            description
          }
        }
      }
    }
  `);

  const { title, description } = Data?.driverJson?.seoData?.driver;

  // optional configuration
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: '20px',
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  return (
    <ThemeProvider theme={driverTheme}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Seo
          pageUrl={location.pathname}
          customTitle={title}
          customDescription={description}
        />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <ContentWrapper>
            <MainDriver />
            <Separator
              color={'#FFC876'}
              angle={-6}
              height={'30%'}
              marginTop={'-7.5%'}
            />
            <FeaturesDriver />
            <Partner background={'#FFFFFF'} textColor={'#000000'} />
            <FeaturesAdvanced driver />
            <ContactForm />
            <Footer driver />
          </ContentWrapper>
        </AppWrapper>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default Driver;
