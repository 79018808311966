import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = (type) => {
  const Data = useStaticQuery(graphql`
    query GetSiteMetadata {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `);

  return Data?.site?.siteMetadata?.[type];
};
