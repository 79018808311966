import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Link from 'common/components/Link';

import BlockWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './entrepreneurguide';

const HappyGuy = 'image/happyguy.png';

const EntrepreneurGuide = ({ head, row, col, text, button }) => {
  const Data = useStaticQuery(graphql`
    query GetEntrepreneurGuide {
      driverJson {
        entrepreneurGuide {
          title
          description
          buttonText
        }
      }
    }
  `);

  const { title, description, buttonText } =
    Data?.driverJson?.entrepreneurGuide;

  return (
    <BlockWrapper className="features-block" id="driver">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading
                as="h2"
                {...head}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <Text {...text} content={description} />
              <Link
                href="https://www.autoentrepreneur.urssaf.fr/portail/accueil/creer-mon-auto-entreprise.html"
                target="_blank"
                rel="noopener noreferrer"
                className="guide_link"
              >
                <Button {...button} title={buttonText} />
              </Link>
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={HappyGuy} className="happyguy" alt="Happy Guy" />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

EntrepreneurGuide.propTypes = {
  head: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  text: PropTypes.object,
  button: PropTypes.object,
};

EntrepreneurGuide.defaultProps = {
  head: {
    width: '80%',
    fontWeight: '800',
    textShadow: '0px 1.5px, 1.5px 0px, 1.5px 1.5px',
    lineHeight: '1.5',
  },
  row: {
    justifyContent: 'center',
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    mb: '5%',
  },
  col: {
    width: ['100%', '50%', '50%'],
  },
  text: {
    width: '80% !important',
    pb: '15%',
    color: '#FF9D00',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  button: {
    type: 'button',
    color: '#000000',
    borderRadius: '25px',
    p: '15px 35px 15px 35px',
  },
};

export default EntrepreneurGuide;
