import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputField from './input.style';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment/moment';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import GooglePlacesAddressInput from '../GoogleMapSearch';

registerLocale('fr', fr); // register it with the name you want

const Input = ({
  label,
  value,
  onBlur,
  onFocus,
  onChange,
  inputType,
  isMaterial,
  icon,
  iconPosition,
  passwordShowHide,
  className,
  formatDate,
  placeholder,
  options,
  ...props
}) => {
  const [dateTime, setDateTime] = useState(null);
  const defaultValue = value ?? '';
  const [state, setState] = useState({
    toggle: false,
    focus: false,
    value: defaultValue,
  });

  const handleOnFocus = (event) => {
    setState({
      ...state,
      focus: true,
    });
    onFocus(event);
  };

  const handleOnBlur = (event) => {
    setState({
      ...state,
      focus: false,
    });
    onBlur(event);
  };

  const handleOnChange = (event) => {
    setState({
      ...state,
      value: event.target.value,
    });
    onChange(event.target.value);
  };

  const getInputFocusClass = () => {
    if (state.focus === true || state.value !== '') {
      return 'is-focus';
    } else {
      return '';
    }
  };

  let inputElement, htmlFor;
  const addAllClasses = ['class_input'];

  if (isMaterial) {
    addAllClasses.push('is-material');
  }

  if (icon && iconPosition) {
    addAllClasses.push(`icon-${iconPosition}`);
  }

  if (className) {
    addAllClasses.push(className);
  }

  if (label) {
    htmlFor = label.replace(/\s+/g, '_').toLowerCase();
  }

  const LabelPosition = isMaterial === true ? 'bottom' : 'top';
  const LabelField = label && <label htmlFor={htmlFor}>{label}</label>;

  const DateInput = ({ value: valueDate, onClick }) => {
    return (
      <input
        {...props}
        id={htmlFor}
        name={htmlFor}
        type={'text'}
        // disabled={"disabled"}
        value={state.value}
        onBlur={handleOnBlur}
        onFocus={() => {
          handleOnFocus();
          onClick();
        }}
      />
    );
  };

  switch (inputType) {
    case 'textarea':
      inputElement = (
        <textarea
          {...props}
          id={htmlFor}
          name={htmlFor}
          value={state.value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
      );
      break;

    case 'password':
      inputElement = (
        <div className="field-wrapper">
          <input
            {...props}
            id={htmlFor}
            name={htmlFor}
            type={state.toggle ? 'password' : 'text'}
            value={state.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
        </div>
      );
      break;
    case 'dropdown':
      inputElement = (
        <div className="field-wrapper">
          <Dropdown
            options={options}
            onChange={(val) => handleOnChange({ target: { value: val } })}
            value={state.value}
            placeholder={placeholder | 'Select an option'}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
        </div>
      );
      break;

    case 'date-picker':
      inputElement = (
        <div className="field-wrapper">
          <DatePicker
            locale={'fr'}
            className={'class_input'}
            customInput={<DateInput />}
            onSelect={(valueDate) => {
              handleOnChange({
                target: { value: valueDate?.toLocaleDateString('en-US') },
              });
            }}
          />
        </div>
      );
      break;
    case 'time-picker':
      inputElement = (
        <div className="field-wrapper">
          <DatePicker
            locale={'fr'}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="sélectionner l'heure"
            timeFormat={'h:mm'}
            customInput={<DateInput />}
            value={dateTime}
            onChange={(valueDate) => {
              setDateTime(valueDate);
              handleOnChange({
                target: { value: moment(valueDate).format('h:mm') },
              });
            }}
          />
        </div>
      );
      break;
    case 'address':
      inputElement = (
        <GooglePlacesAddressInput
          onSelectedValue={(value) => {
            setState({
              ...state,
              value: value,
            });
            onChange(value);
          }}
          onCleanInput={() => {}}
          isFullAddress={true}
        />
      );
      break;
    default:
      inputElement = (
        <div className="field-wrapper">
          <input
            {...props}
            id={htmlFor}
            name={htmlFor}
            type={inputType}
            value={state.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
          {icon && <span className="input-icon">{icon}</span>}
        </div>
      );
  }

  return (
    <InputField
      className={`${addAllClasses.join(' ')} ${getInputFocusClass()}`}
    >
      {LabelPosition === 'top' && LabelField}
      {inputElement}
      {isMaterial && <span className="highlight" />}
      {LabelPosition === 'bottom' && LabelField}
    </InputField>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOf(['string', 'number']),
  isMaterial: PropTypes.bool,
  passwordShowHide: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  inputType: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'number',
    'textarea',
    'date-picker',
    'time-picker',
    'dropdown',
    'address',
  ]),
  icon: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  inputType: 'text',
  isMaterial: false,
  iconPosition: 'left',
  formatDate: '',
  placeholder: '',
  options: [],
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
};

export default Input;
