import ReactDOM from 'react-dom';
const loadableReady = require('@loadable/component').loadableReady;

export function shouldUpdateScroll({ routerProps: { location } }) {
  const { pathname } = location;
  if (pathname) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return false;
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
