import styled from 'styled-components';

const BlockWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: left;
  img {
    margin: 0 0 0 10%;
    max-width: 100%;
  }
  padding: 25px 70px 0px 70px;
  @media only screen and (max-width: 1360px) {
    padding: 20px 30px 0px 30px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  z-index: 999;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-left: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 30px;
    transform: rotateY(0deg);
  }
  button {
    border-radius: 22.5px;
    &:hover {
      box-shadow: 3.3px 3.3px 2.5px 2.5px rgb(0 0 0 / 10%);
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 80%;
  float: right;
  margin-top: 35%;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }
  p {
    width: 60%;
  }
  h2 {
    letter-spacing: 1.5px;
    @media only screen and (min-width: 1441px) {
      margin: 100px 0 100px;
    }
    @media only screen and (min-width: 1440px) {
      margin: 65px 0 65px;
    }
    @media only screen and (min-width: 767px) {
      margin: 50px 0 50px;
    }
  }
`;

export default BlockWrapper;
