import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql } from 'gatsby';

import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Separator from 'common/components/Separator';

import BlockWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './featuresdriver.style';

const FeatureDriverPhone = 'image/features-driver-phone.png';
const FeatureDriverPhone2 = 'image/features-driver-phone2.png';

const FeaturesDriver = ({ head, row, col, text, text2 }) => {
  const Data = useStaticQuery(graphql`
    query GetFeaturesDriverData {
      driverJson {
        featuresData {
          driver {
            featureTitle
            featureDescription
            featureDescription2
            featureDescription3
            featureDescription4
            featureTitle2
            featureDescription22
            featureDescription32
            featureDescription42
            featureDescription52
          }
        }
      }
    }
  `);

  const {
    featureTitle,
    featureDescription,
    featureDescription2,
    featureDescription3,
    featureDescription4,
    featureTitle2,
    featureDescription22,
    featureDescription32,
    featureDescription42,
    featureDescription52,
  } = Data?.driverJson?.featuresData?.driver;

  return (
    <BlockWrapper className="features-block" id="driver">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Fade direction="left" className="first-element">
                <Heading
                  as="h2"
                  {...head}
                  dangerouslySetInnerHTML={{ __html: featureTitle }}
                />
                <Fade cascade damping={0.75}>
                  <Text {...text} content={featureDescription} />
                  <Text {...text} content={featureDescription2} />
                  <Text {...text} content={featureDescription3} />
                  <Text
                    {...text}
                    style={{ paddingTop: '10%' }}
                    content={featureDescription4}
                  />
                </Fade>
              </Fade>
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Fade direction="right">
                <Image
                  src={FeatureDriverPhone}
                  className="feature_driver_phone"
                  alt="Driver Features Phone"
                />
              </Fade>
            </ImageWrapper>
          </Box>
        </Box>
      </Container>

      <Separator
        color={'#FFFFFF'}
        angle={-6}
        height={'30%'}
        marginTop={'-7.5%'}
      />

      <Container style={{ backgroundColor: '#FF9D00' }} width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper style={{ marginLeft: '20%' }}>
              <Fade direction="left">
                <Image
                  src={FeatureDriverPhone2}
                  className="feature_driver_phone"
                  alt="Driver Features Phone"
                />
              </Fade>
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Fade direction="right">
                <Heading
                  as="h2"
                  {...head}
                  dangerouslySetInnerHTML={{ __html: featureTitle2 }}
                />
                <Fade cascade damping={0.75}>
                  <Text {...text2} content={featureDescription22} />
                  <Text {...text2} content={featureDescription32} />
                  <Text {...text2} content={featureDescription42} />
                  <Text
                    {...text2}
                    style={{ paddingTop: '10%' }}
                    content={featureDescription52}
                  />
                </Fade>
              </Fade>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

FeaturesDriver.propTypes = {
  head: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  rightText: PropTypes.object,
  text: PropTypes.object,
  text2: PropTypes.object,
  littleSeparator: PropTypes.object,
};

FeaturesDriver.defaultProps = {
  head: {
    width: '60%',
    fontWeight: '800',
    textShadow: '0px 1.5px, 1.5px 0px, 1.5px 1.5px',
  },
  row: {
    justifyContent: 'center',
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    mb: '5%',
  },
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
  },
  text: {
    width: '50%',
    padding: '0 0 10px 0',
    color: '#FF9D00',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  text2: {
    width: '50%',
    padding: '0 0 10px 0',
    color: '#FFFFFF',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
};

export default FeaturesDriver;
