import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/Container';

import SelectionWrapper from './sideSelector.style';

const DriverImage = 'image/driver-pro-girl.png';
const UserImage = 'image/driver-logo-side.png';
const DriverProLogo = 'image/driver-pro-logo-sticked-selector.svg';
const DriverLogo = 'image/driver-logo-sticked.svg';

const SkillSection = ({
  sectionWrapper,
  row,
  col,
  sideTitleWrapper,
  sideTitle,
  onHover,
  widthed,
}) => {
  return (
    <SelectionWrapper id="selection-section">
      <Box {...sectionWrapper} as="section">
        <Container noGutter mobileGutter width="1200px" className="container">
          <Box {...row}>
            <Box {...col} className="userBlock">
              <Link to="driver" className="driver-link">
                <Image
                  src={DriverLogo}
                  className="driver_logo"
                  alt="Driver Logo"
                />
                <Box
                  {...sideTitleWrapper}
                  className="sideTitleWrapper sideTitleWrapperLeft"
                >
                  <Heading
                    as="h1"
                    {...sideTitle}
                    content="Nos chauffeurs conduisent votre véhicule à votre place"
                    className="selector-title driver"
                  />
                  <Box {...onHover} className="onHover textOnHoverLeft">
                    <Image
                      src={UserImage}
                      className="driver_image"
                      alt="Driver Image"
                    />
                  </Box>
                </Box>
              </Link>
            </Box>
            <Box {...col} className="driverBlock">
              <Link to="driver-pro" className="driver-pro-link">
                <Image
                  src={DriverProLogo}
                  className="driver_pro_logo"
                  alt="Driver Pro Logo"
                />
                <Box className="sideTitleWrapper">
                  <Heading
                    as="h1"
                    {...sideTitle}
                    {...widthed}
                    content="Devenez Driver et conduisez le véhicule de vos clients"
                    className="selector-title driver-pro"
                  />
                  <Box {...onHover} className="onHover">
                    <Image
                      src={DriverImage}
                      className="driver_pro_image"
                      alt="Driver Pro Image"
                    />
                  </Box>
                </Box>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </SelectionWrapper>
  );
};

SkillSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  widthed: PropTypes.object,
  sideTitle: PropTypes.object,
  onHover: PropTypes.object,
  details: PropTypes.object,
};

SkillSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '180px'],
    pb: ['60px', '80px', '100px', '110px', '120px'],
    background: '#FFE6C1',
  },
  sideTitle: {
    fontWeight: '800',
    color: '#FFFFFF',
    lineHeight: '28px',
    textAlign: 'center',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  col: {
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '50%',
  },
  widthed: {
    width: '120%',
  },
  onHover: {
    width: '100%',
  },
  details: {
    fontSize: ['14px', '14px', '16px', '16px', '16px'],
    fontWeight: '400',
    color: '#FFFFFF',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default SkillSection;
