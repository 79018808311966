import PropTypes from 'prop-types';
import React from 'react';

import Box from 'common/components/Box';
import Heading from 'common/components/Heading';

const TermsConditionsProBackup = ({ boxBackground, title }) => {
  return (
    <Box {...boxBackground} id="static" className="static_box" as="section">
      <Heading
        as="h2"
        {...title}
        dangerouslySetInnerHTML={{
          __html: 'Conditions générales d’utilisation',
        }}
      />
      <div
        className="static-text"
        dangerouslySetInnerHTML={{
          __html: `<p>Bienvenue sur la plateforme Oovoom Driver Pro à laquelle il est possible d’accéder soit par le biais du site internet <a href="http://www.oovoomdriver.com/">www.oovoomdriver.com</a> ou <a href="https://www.oovoom.com/">https://www.oovoom.com</a>  ou directement sur l’AppStore ou PlayStore en téléchargeant l’application mobile Oovoom Driver Pro (ci-après la « <strong>Plateforme</strong> » ou l’ « <strong>Application</strong> »).</p>
              <p>En accédant à cette Plateforme, toute personne accepte sans réserve d’être liée par l’ensemble des présentes conditions générales d’utilisation (ci-après les « <strong>CGU</strong> ») qu’elle confirme avoir lu dans leur intégralité.</p>
              <p><strong>Conditions générales d’utilisation de la Plateforme Oovoom Driver Pro pour les Prestataires</strong></p>
              <p>La Plateforme est exploitée par la société SCHS OOVOOMDRIVER, Société par Actions Simplifiée au capital de 10 012 €, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 918 414 111, dont le siège social est situé au 111 avenue Victor Hugo 75016 Paris (ci-après « <strong>Oovoom Driver</strong> »).</p>
              <p>Oovoom Driver peut être contactée aux coordonnées suivantes :</p>
              <p>Adresse postale : 111 avenue Victor Hugo – 75016 – Paris</p>
              <p>Téléphone : 01 80 82 44 44</p>
              <p>Adresse courriel : contact@oovoom.com</p>
              <p><strong>Article 1 – Objet</strong></p>
              <p>Les présentes CGU ont pour objet de définir les conditions d’utilisation et les modalités de mise à disposition d’un service de mise en relation (ci-après les « <strong>Services</strong> ») entre tout conducteur inscrit sur la Plateforme (ci-après le « <strong>Prestataire</strong> ») aux fins d’effectuer une prestation de service relative au véhicule d’un tiers, personne physique ou morale, telle que définie à l’Article 5.1 (ci-après le « <strong>Client</strong> ») (ci-après conjointement les « <strong>Utilisateurs</strong> »).</p>
              <p>Oovoom Driver et les Utilisateurs sont dénommées collectivement les « <strong>Parties</strong> ».</p>
              <p>Les présentes CGU ont pour objet de définir les relations entre les Clients, les Prestataires et Oovoom Driver dans le cadre des mises en relation proposées via la Plateforme, en particulier les modalités et les conditions dans lesquelles les Prestataires exécutent les diverses prestations choisies par les Clients sur la Plateforme.</p>
              <p>Ces relations s’inscrivent en dehors de toute exclusivité et de tout lien de subordination entre Oovoom Driver et les Utilisateurs.</p>
              <p>Les présentes CGU peuvent être complétées par des conditions d’utilisation spécifiques à certains services proposés sur la Plateforme, qui prévalent sur les CGU en cas de contradiction.</p>
              <p>Les présentes CGU devront être signées à l’occasion de la création d’un compte pour le Client, et de la validation de l’inscription pour le Prestataire.</p>
              <p>Oovoom Driver se réserve le droit, à tout moment et sans préavis, d’améliorer, de modifier, d’altérer, de suspendre ou d’interrompre définitivement tout ou partie de la Plateforme et d’en restreindre ou d’en interdire l’accès.</p>
              <p>De plus, Oovoom Driver se réserve le droit de modifier ou de mettre à jour les présentes CGU sans préavis. Toutes les modifications entrent en vigueur immédiatement après leur publication. Le Prestataire doit consulter régulièrement la dernière version des CGU affichée à l’adresse suivante « <em><a href="https://oovoomdriver.com/ProCGU">https://oovoomdriver.com/ProCGU</a></em> »  et est réputé l’accepter à chaque nouvelle connexion sur la Plateforme.</p>
              <p>En cas de problème, défaillance ou dysfonctionnement empêchant l’accès à la Plateforme, les Utilisateurs s’engagent à prévenir Oovoom Driver dans les plus brefs délais, par courriel à l’adresse suivante <a href="mailto:contact@oovoom.com">contact@oovoom.com</a> ou par téléphone au +33 1 80 82 44 44.</p>
              <p><strong>Article 2 – Présentation générale de la Plateforme</strong></p>
              <p><strong>Article 2.1 – Présentation de la Plateforme</strong></p>
              <p>La société Oovoom Driver a développé une Plateforme, ainsi qu’une Application, d’intermédiation entre des Clients souhaitant bénéficier d’une prestation sur leur véhicule (ci-après une « <strong>Prestation</strong> »), et un Prestataire qui se charge de l’effectuer.</p>
              <p>Les Prestations proposées par le biais de la Plateforme et telles que définies à l’Article 5.1, sont les suivantes : les Prestataires vont conduire le véhicule d’un Client (<strong>i</strong>) soit pour effectuer un trajet (ci-après une « <strong>Course</strong> »), (<strong>ii</strong>) soit pour effectuer une prestation de service supplémentaire (ci-après les « <strong>Prestations de Service Supplémentaires</strong> »), et cela en contrepartie d’une somme monétaire.</p>
              <p><strong>Article 2.2 – Accès à la Plateforme</strong></p>
              <p>La Plateforme est accessible 24 heures sur 24, 7 jours sur 7 et toute l’année, sauf exceptions notamment en cas de force majeure, telle que définie à l’Article 18, ou de la survenance d’un évènement irrésistible, sous réserve des périodes de maintenance ou de pannes éventuelles et pour lesquelles Oovoom Driver ne pourra être tenue responsable.</p>
              <p>Le Prestataire doit préalablement créer un compte (ci-après le « <strong>Compte</strong>), et sera amené à fournir des justificatifs afin de garantir la véracité de l’intégralité des informations renseignées. L’inscription et la création du Compte sur la Plateforme sont soumises au contrôle et à la validation d’Oovoom Driver.</p>
              <p>Dans le cas d’un dysfonctionnement de la Plateforme, Oovoom Driver s’engage à faire de son mieux afin de procéder aux opérations de réparation nécessaires afin de rétablir la Plateforme dans les meilleurs délais, sans pour autant être tenue d’une quelconque obligation de résultat et sans que sa responsabilité ne puisse être mise en cause à ce sujet.</p>
              <p><strong>Article 3 – Inscription sur la Plateforme</strong></p>
              <p>Toute personne désirant proposer une Prestation de service quelle qu&#39;elle soit (effectuer une Course, ou Prestation de Service Supplémentaire), en sa qualité de Prestataire, doit préalablement s’inscrire sur la Plateforme Oovoom Driver Pro.</p>
              <p><strong>Article 3.1 – Conditions relatives au Prestataire</strong></p>
              <p>Les Services proposés sur la Plateforme sont destinés exclusivement aux personnes qui remplissent les conditions décrites ci-dessous.</p>
              <p>Le Prestataire doit être âgé de 21 ans au minimum.</p>
              <p>Le Prestataire doit être titulaire du permis de conduire français, ou d’une équivalence délivrée par une préfecture française, correspondant au modèle du véhicule à conduire, et ce, depuis plus de 3 ans et posséder à minima 6 points sur son permis de conduire.</p>
              <p>Le Prestataire ne doit pas faire l’objet, au moment de chaque Prestation, d’une suspension ou d’un retrait de son permis de conduire. À ce titre, Oovoom Driver se réserve le droit de vérifier le nombre de points restants dans le permis de conduire des Prestataires à chaque moment qu’elle le jugera utile – voire préalablement à chaque Prestation.</p>
              <p>Durant les 3 années précédant la Prestation, le Prestataire ne doit pas avoir causé un sinistre corporel lié à la conduite, ni avoir fait l’objet d’une résiliation de police d’assurance automobile, d’un retrait ou d’une suspension de permis de conduire et ni avoir été condamné pénalement pour un crime ou un délit routier.</p>
              <p>Par la même, le Prestataire ne doit pas avoir fait l’objet de condamnation pour conduite en état d’ivresse ou sous l’emprise de stupéfiants et doit posséder un casier judiciaire vierge.</p>
              <p>Le cas échéant, des conditions particulières différentes peuvent être expressément acceptées par écrit par Oovoom Driver en lieu et place de tout ou partie des conditions précitées.</p>
              <p>D’un point de vue pratique, et pour toute la durée de la Prestation, le Prestataire devra disposer d’un smartphone afin d’avoir accès à l’Application mobile Oovoom Driver Pro et d’un accès effectif à Internet.</p>
              <p><strong>Article 3.2 – Informations et documents communiqués</strong></p>
              <p>Afin de s’inscrire sur la Plateforme, les Prestataires doivent suivre la procédure décrite ci-dessous.</p>
              <p>Lors de son inscription sur la Plateforme, le Prestataire doit préalablement créer un Compte, et remplir un formulaire en renseignant à ce titre l’ensemble des informations requises pour valider son inscription, et qui sont les suivantes :</p>
              <ul>
              <li>Nom, prénom, sexe, date de naissance, adresse postale, numéro de téléphone, et adresse courriel ;</li>
              <li>Coordonnées bancaires ;</li>
              <li>Compagnie d’assurance, si nécessaire ;</li>
              <li>Le numéro de SIRET</li>
              <li>Numéro de permis de conduire ; et,</li>
              <li>Mot de passe.</li>
              </ul>
              <p>Ces informations peuvent différer en cas d’ouverture d’un compte professionnel et notamment inclure l’adresse postale et le siège postal de l’Utilisation, une adresse courriel pour la gestion du compte et l’envoi des factures, les adresses des structures, le numéro de SIRET et le numéro de TVA.</p>
              <p>Par ailleurs, le Prestataire devra fournir les documents suivants :</p>
              <ul>
              <li>Permis de conduire et l’équivalence délivrée par une préfecture française le cas échéant ;</li>
              <li>Pièce d’identité ;</li>
              <li>Une photo</li>
              <li>Justificatif de domicile ;</li>
              <li>Casier judiciaire vierge ;</li>
              <li>KBIS ou enregistrement URSSAF ;</li>
              <li>Copie de son IBAN ou de son RIB ; et,</li>
              <li>Mandat de prélèvement SEPA signé par ses soins ou tout autre moyen de paiement acceptable.</li>
              <li>Son relevé d’information, ou à défaut, une attestation de non-sinistralité responsable durant les 3 dernières années</li>
              </ul>
              <p>En communiquant le numéro du permis de conduire ainsi qu’une copie du permis de conduire dont il est titulaire, le Prestataire certifie sur l’honneur que son permis de conduire est valide et que les informations y relatives sont à jour.</p>
              <p>Le Prestataire garantit que les informations et les documents fournis sont complets, exacts et dénués de vice, et s’engage à les mettre à jour sans délai en cas de changement. En particulier, en cas de modification de son adresse, numéro de téléphone, pièce d’identité (renouvellement) et/ou de ses coordonnées bancaires, le Prestataire s’engage à mettre son compte à jour dans son Compte en y téléchargeant les derniers documents mis à jour. Le Prestataire s’engage aussi à fournir tous les justificatifs requis par Oovoom Driver, à première demande.</p>
              <p>Le prestataire garantit qu’à chaque course qu’il assure, il est toujours en possession d’un permis valide et s’engage, le cas échéant à ne pas proposer ses prestations sur la plateforme, et à informer immédiatement OovoomDriver d’un retrait de permis ou d’une annulation de son permis de conduire.</p>
              <p>Un Prestataire ne peut ouvrir qu’un seul et unique Compte sur la Plateforme.</p>
              <p><strong>Article 3.3 – Authentification par Oovoom Driver</strong></p>
              <p>Suite à la réception en bonne et due forme de tous les documents et informations préalablement énoncés, ces derniers feront l’objet d’une vérification approfondie par Oovoom Driver ou par toute société tierce spécialisée dans l’analyse de documents.</p>
              <p>Tout faux document fourni, quel qu’il soit (c’est-à-dire : un permis de conduire non valide ou ne correspondant pas à l’identité du Prestataire, un justificatif de domicile falsifié, etc.) est passible de sanctions, d’amendes et/ou de poursuites judiciaires.</p>
              <p>En cas d’information et/ou document erronés quels qu’ils soient, cela entraînera l’invalidation du Compte. Le Prestataire ne pourra ni se connecter ni se réinscrire sur la Plateforme. Dans ce cas, le Prestataire ne pourra prétendre à aucune rémunération pour les Prestations préalablement réalisées et des poursuites judiciaires pourront être engagées contre lui.</p>
              <p>En cas d’inscription incomplète, celle-ci ne sera pas validée.</p>
              <p>Par ailleurs, Oovoom Driver se réserve le droit, à sa seule discrétion, de refuser l’inscription d’un Prestataire sur la Plateforme, pour quelque raison que ce soit ou sans motif et sans que sa responsabilité ne puisse être mise en cause à ce sujet.</p>
              <p>De plus, Oovoom Driver se réserve le droit, à sa seule discrétion, de suspendre ou résilier, sans préavis ni indemnités, tout compte qui serait créé ou utilisé sous une fausse identité ou à des fins frauduleuses.</p>
              <p><strong>Article 3.4 – Validation du Compte</strong></p>
              <p>Une fois les informations vérifiées et validées conformes, le Prestataire recevra un courriel de validation de Compte, auquel il pourra accéder grâce à un identifiant qui lui est attribué et un mot de passe préalablement choisi par lui (ci-après les « <strong>Identifiants</strong> »). Il est ensuite invité à accepter les présentes CGU afin de finaliser la création de son Compte. Le Compte lui permettra notamment de mettre à jour les informations fournies lors de son inscription et de gérer l’utilisation des Services en général.</p>
              <p>Le Prestataire s’engage à conserver ses Identifiants secrets et demeure le seul responsable de leur utilisation. Tout accès à la Plateforme par le biais de ses Identifiants est réputé avoir été effectué par le Prestataire en question. Il est aussi responsable de toute conséquence qui résulte de la perte ou du vol de ses Identifiants et s’engage dans ce cas à les modifier sans délai.</p>
              <p>Le Prestataire s’engage à utiliser son Compte à titre personnel, confidentiel et incessible, et est pleinement responsable en cas d’utilisation de son Compte par un tiers en son nom ou pour son compte.</p>
              <p><strong>Article 4 – Description des Services proposés</strong></p>
              <p>La Plateforme permet aux Prestataires d’être à la disposition de Clients durant une période prédéfinie afin (<strong>i</strong>) d’effectuer une Course en conduisant leur véhicule et (<strong>ii</strong>) de réaliser des Prestations de Services Supplémentaires demandées par eux sur le véhicule, au travers de la Plateforme Oovoom Driver Pro, et cela dans les conditions prévues ci-après.</p>
              <p><strong>Article 4.1 – Disponibilité des Prestataires</strong></p>
              <p>Dans un premier temps, le Prestataire est invité à se connecter grâce à ses Identifiants à la Plateforme afin de se rendre disponible auprès des Utilisateurs.</p>
              <p>Les Prestataires sont disponibles dès qu’ils se connectent à la Plateforme Oovoom Driver Pro et qu’ils appuient sur le bouton « en service » qui y est destiné.</p>
              <p>Dans un second temps, les Prestataires renseignent certaines des caractéristiques essentielles, qui sont entre autres les suivantes :</p>
              <ul>
              <li>Leur localisation exacte, au travers d’un système de géolocalisation ; et,</li>
              <li>Leurs dates et horaires de disponibilité.</li>
              </ul>
              <p>Une fois la géolocalisation activée, les Prestataires seront visibles sur la Plateforme pour les Clients souhaitant réserver une Prestation. En particulier, cela permet aux Clients d’avoir accès à la liste des Prestataires disponibles dans le secteur géographique recherché Les Prestataires acceptent de maintenir leur géolocalisation activée pendant toute la durée de la Prestation.</p>
              <p><strong>Article 4.2 – Validation entre les Utilisateurs</strong></p>
              <p>La réservation d’une Prestation manifeste la volonté du Client. L’accord de volonté est caractérisé par la validation de la réservation par le Prestataire. De plus, par la validation de la réservation, le Prestataire s’engage à effectuer la Prestation dans le respect des modalités indiquées dans l’offre de réservation effectuée par le Client, selon les dispositions des présentes CGU et du/des Code(s) de la Route applicable(s) aux différents territoires traversés lors de la Prestation.</p>
              <p>La réservation d’une Prestation est finalisée au moment où les Utilisateurs acceptent les conditions de la Prestation à effectuer telles qu’affichées par la Plateforme.</p>
              <p>Le Prestataire reçoit une notification contenant à la fois le récapitulatif des informations relatives aux Prestations, et notamment à la Course et aux Prestations de Service Supplémentaires le cas échéant, ainsi que les informations nécessaires à la réalisation de sa mission (ci-après la « <strong>Notification</strong> »).</p>
              <p>Le Prestataire doit valider les Prestations qu’il s’engage à réaliser.</p>
              <p><strong>Article 5 – Réalisation des Prestations</strong></p>
              <p><strong>Article 5.1 - Définition des Prestations proposées</strong></p>
              <p>La Plateforme met en place un service de Course qui consiste à effectuer un trajet au moyen du véhicule du Client, d’une adresse spécifique de départ (point A) à une autre adresse d’arrivée (point B), ou un aller-retour, et cela dans une période de temps donnée, dans le respect des conditions proposées par Oovoom Driver et des règles de sécurité routière en vigueur sur le(s) territoire(s) où la Course est effectuée.</p>
              <p>Plus précisément, le Prestataire est amené à réaliser, pour le compte de son Client et en fonction des réservations de ce dernier, les Prestations suivantes :</p>
              <ul>
              <li>Une Course aller simple du lieu de départ (point A) au lieu d’arrivée (point B)</li>
              </ul>
              <p>Dans ce cas, le Prestataire prend le véhicule et le dépose à l’adresse souhaitée. Il sera amené à réaliser ce type de Prestation dans le cadre du dépôt du véhicule auprès d’un garagiste par exemple.</p>
              <p>Le Prestataire peut également être sollicité pour conduire le véhicule du Client lorsque ce dernier ne souhaite pas conduire. Il sera ainsi amené à transporter des personnes dans leur propre véhicule et les déposer à une adresse indiquée, avec le véhicule.</p>
              <ul>
              <li>Une Course aller-retour du lieu de départ (point A) au lieu d’arrivée (point B) puis un retour au point de départ (point A).</li>
              </ul>
              <p>Le Prestataire sera amené à réaliser ce type de prestation dans le cadre du dépôt du véhicule auprès d’un prestataire par exemple. Auquel cas, un temps d’attente est estimé par le Client, au terme duquel le Prestataire s’engage à reconduire le véhicule au lieu de départ (point A).</p>
              <p>Durant les Courses précitées, le Prestataire peut être amené à réaliser, à la demande du Client, des Prestations de Service Supplémentaires qui incluent le remplissage de carburant, le lavage du véhicule dans un automate ou auprès d’un prestataire spécialisé, l’entretien, la révision, la réparation, le contrôle technique et de remisage du véhicule, l’attente d’un tiers (tel que par exemple l’attente de dépanneuse) ou encore le gonflage des pneus.</p>
              <p>En tout état de cause, le Prestataire reçoit la Notification contenant à la fois le récapitulatif des informations relatives à la Prestation et notamment à la Course et aux Prestations de Services Supplémentaires le cas échéant, ainsi que les indications nécessaires à la réalisation de sa mission :</p>
              <ul>
              <li>La nature de la Course : un aller simple, un aller-retour ou une course avec des Prestations de Service Supplémentaires ;</li>
              <li>L’adresse du lieu de départ (point A) et l’adresse du lieu d’arrivée (point B) ;</li>
              <li>Les caractéristiques du véhicule à conduire telles que la marque, le modèle et le numéro d’immatriculation ;</li>
              <li>Les horaires qui doivent être scrupuleusement respectées pour (<strong>i</strong>) rejoindre le Client et/ou le véhicule à conduire à l’adresse de départ (point A) et (<strong>ii</strong>) remettre le véhicule conduit à l’adresse d’arrivée (point B) ou de départ (point A) s’il s’agit d’un aller-retour (ci-après l’ « <strong>Adresse de Remise</strong> »). À noter que 5 minutes sont accordées au départ et ensuite à la remise pour attester de l’état du véhicule par un état des lieux tel que défini à l’Article 5.2.1 ;</li>
              <li>Dans le cas où le Prestataire doit remettre le véhicule à un tiers non détenteur, distinct du Client, l’identité de la personne physique ou personne morale, désignée par le Client comme étant en charge de récupérer le véhicule (ci-après le « <strong>Tiers Non Détenteur</strong> ») ;</li>
              <li>Les éventuels Prestations de Service Supplémentaires ou tout autre service complémentaire à la Course ; et,</li>
              <li>Le prix proposé de la Prestation.</li>
              </ul>
              <p>À noter que le Prestataire se rend à l’adresse de départ et repart de l’Adresse de Remise, par tout moyen de son choix et à ses propres frais.</p>
              <p><strong>Article 5.2 - Déroulement de la Prestation</strong></p>
              <p>Au moment de la remise du véhicule à conduire, le Prestataire s’assure qu’il s’agit effectivement du véhicule objet de la réservation, notamment sa marque, son modèle, et de la régularité du numéro d’immatriculation du véhicule et comparera l’ensemble de ces éléments avec ceux indiqués dans la Notification.</p>
              <p>Le Prestataire s’engage à réaliser un état des lieux au départ et à l’arrivée de la Prestation via la Plateforme, comme décrit à l’Article 5.2.1. Les états des lieux effectués par le Prestataire au départ et ensuite à la remise du véhicule, sont obligatoirement soumis à la validation du Client.</p>
              <p>De plus, le Prestataire s’engage à respecter les obligations prévues à l’Article 8 des présentes CGU.</p>
              <p>Par ailleurs, les Prestataires reconnaissent et acceptent que les Clients auxquels ils ont fourni une Prestation sont autorisés à les évaluer. À cet effet, les Clients peuvent attribuer aux Prestataires une note qui varie entre 1 et 5 et rédiger un commentaire au sujet de la Prestation du Prestataire concerné. Les Prestataires acceptent également qu’en cas d’évaluations négatives, Oovoom Driver peut, à sa seule discrétion, décider de suspendre ou de bloquer leur accès à la Plateforme, temporairement ou de façon permanente.</p>
              <p><strong>Article 5.2.1 – État des lieux</strong></p>
              <p>Le véhicule à conduire fait l’objet d’un état des lieux version électronique directement dans l’onglet « État des lieux » sur la Plateforme Oovoom Driver Pro, à effectuer par le Prestataire via son smartphone à l’adresse de départ (point A) et à l’Adresse de Remise.</p>
              <p>L’état des lieux de départ et de remise se fait en présence du Client, ou du Tiers Non Détendeur.</p>
              <p>Cet état des lieux est obligatoire pour entamer et clôturer une Prestation. Le Prestataire devra faire valider, par le Client titulaire du compte Oovoom Driver Pro l’état des lieux, dûment complété, au travers de la Plateforme.</p>
              <p>Le Prestataire doit dans un premier temps prendre entre 4 et 16 photos du véhicule par côté par le biais de l’Application.</p>
              <p>Le Prestataire est ensuite amené à renseigner manuellement sur la Plateforme les informations sur le véhicule à la fois au départ et à la remise, avec l’aide du Client et/ou du Tiers Non Détenteur et notamment :</p>
              <ul>
              <li>Les éventuels impacts présents sur le véhicule ;</li>
              <li>Les éventuelles marques et traces sur le véhicule;</li>
              <li>La propreté extérieure et intérieure ;</li>
              <li>Le kilométrage ; et,</li>
              <li>Le niveau de carburant en pourcentage (%).</li>
              </ul>
              <p>En Particulier, le Prestataire doit toujours préciser dans l’état des lieux tout problème, défaillance ou irrégularité, constaté au départ, de remise et/ou durant le transfert du véhicule.</p>
              <p>La date et l’heure de départ et de remise seront automatiquement renseignées sur la Plateforme.</p>
              <p>À noter que les Utilisateurs reconnaissent et acceptent qu’en début et fin de Prestation, 5 minutes sont accordées à l’état des lieux de départ et de remise. Cela ne peut entraîner aucune pénalité pour le Prestataire.</p>
              <p>Une fois l’état des lieux complété par le Prestataire, il doit être validé par le Client ou son représentant sur la Plateforme.</p>
              <p>Les litiges relatifs à l’état des lieux doivent être renseignés directement dans la Plateforme.  Oovoom Driver s’engage à intervenir en qualité d’intermédiaire. Le rôle d’Oovoom Driver est d&#39;assurer la conformité des photos aux informations renseignées et de valider ou contredire le litige selon les preuves qui ont été validées par les Utilisateurs dans la Plateforme.</p>
              <p><strong>Article 5.2.2 - Remise des documents et clefs</strong></p>
              <p>Au terme de l’état des lieux d’arrivée, le Prestataire atteste s’être fait remettre les clefs, la carte grise ainsi que l’attestation d’assurance du véhicule.</p>
              <p>À ce titre, il s’engage à utiliser le matériel conformément à la destination pour laquelle il lui a été confié.</p>
              <p>Le Prestataire devient détenteur du matériel, à charge de restitution au terme de la réalisation de la Prestation.</p>
              <p><strong>Article 5.2.3 - Dépôt du véhicule et clôture de la Prestation</strong></p>
              <p>Lorsque le Prestataire a déposé le véhicule à l’Adresse de Remise, un état des lieux est réalisé conformément aux dispositions de l’Article 5.2.1.</p>
              <p>Dans le cas où le Prestataire doit transmettre le véhicule à un Tiers Non Détenteur, par exemple à un garagiste, il doit s’assurer de l’identité de ce dernier grâce aux informations communiquées par le biais de la Plateforme et mentionnées dans la Notification.</p>
              <p>En cas d’absence du Client ou du Tiers Non Détenteur à l’Adresse de Remise, le Prestataire devra attendre la personne indiquée dans la Notification comme étant chargée de réceptionner le véhicule, que ce soit le Client ou le Tiers Non Détenteur, jusqu’à clôture de la Prestation. Le temps d’attente est alors facturé au Client conformément aux dispositions de l’Article 6.</p>
              <p>Afin de clôturer une Prestation, le Prestataire doit :</p>
              <ul>
              <li>attester avoir restitué le matériel ainsi que les clefs, la carte grise, et l’attestation d’assurance du véhicule au Client ou au Tiers Non Détenteur ;</li>
              <li>effectuer les états des lieux électroniques dûment complétés à l’adresse de départ et à l’Adresse de Remise et recevoir la validation du Client ;</li>
              <li>mentionner et télécharger les justificatifs des frais pour la réalisation des Prestations de Services Supplémentaires ou tout autre service complémentaire à la Course tel que le péage ou le plein de carburant par exemple.</li>
              </ul>
              <p><strong>Article 6 – Facturation et modalités de paiement</strong></p>
              <p>Le prix de la Prestation est un montant forfaitaire indiqué avant l’acceptation de la Prestation par le Prestataire.</p>
              <p>Le prix affiché comprend la rémunération du Prestataire pour la Prestation et notamment les frais des Prestations de Service Supplémentaires, ainsi que les frais des Services.</p>
              <p>La validation d’une réservation par le Prestataire emporte acceptation du prix affiché pour la réalisation de la Prestation. À partir de ce moment, le Prestataire ne pourra réclamer une somme supérieure ou différente.</p>
              <p>Dans le cas d’attente non déclarée par le Client ou le Tiers Non Détenteur, le Prestataire recevra un montant supplémentaire forfaitaire correspondant au temps d’attente sur une base de 13€HT/heure – au prorata du temps attendu.</p>
              <p>Au moment de la réservation de la Prestation par le Client, le Prestataire perçoit immédiatement les montants correspondant à la réalisation des Prestations de Service Supplémentaires réservées par leur intégration à son portefeuille disponible via la Plateforme (ci-après le « <strong>Wallet</strong> »). Le Prestataire s’engage à intégrer la preuve des paiements et réalisations au travers de la mise à disposition, dans la Plateforme de la preuve – ticket de caisse ou facture à télécharger directement dans la Plateforme.</p>
              <p>Au prix de la Prestation peuvent s’ajouter des frais éventuels de train (si course au-delà de 50km) qui seront pris en charge par la plateforme durant « une opération spécifique » cadrée par un contrat de prestation spécifique le moment venu. Tout remboursement éventuel au Prestataire se fait uniquement sur présentation de justificatifs. À ce titre, le Prestataire veille à conserver et à communiquer au Client toutes preuves et justificatifs de paiement de plein de carburant et/ou de péages le cas échéant. Les justificatifs afférents doivent indiquer clairement le montant exact, le libellé, la date et l’heure ainsi que le lieu de la réalisation.</p>
              <p>Dans le cas où le Prestataire ne remettrait pas les preuves des dépenses engagées, Oovoom Driver n’a pas d’obligation de rembourser les dépenses et se réserve le droit de suspendre le Compte du Prestataire et de récupérer par tout moyen, les sommes dues déjà perçues par le Prestataire sans justificatifs.</p>
              <p>Les Prestations de Service Supplémentaires correspondantes, payées par le Prestataire feront l’objet d’une facturation automatique émise pour le compte du Prestataire. Le Prestataire pourra alors faire un virement de ces sommes dans un délai de 3 jours ouvrés, sauf en cas de litige tel qu’indiqué ci-dessous.</p>
              <p>La Prestation est alors due, une facture est émise et le montant dû est alors intégré au portefeuille du Prestataire disponible via la Plateforme (ci-après le « <strong>Wallet</strong> »). Le Prestataire pourra alors faire un virement de ces sommes après un délai de 2 jours ouvrés.</p>
              <p>Dans le cas d’une anomalie qui interviendrait après cette date (une amende par exemple), Oovoom Driver se réserve le droit de bloquer les sommes dues du Wallet du Prestataire le temps que le litige soit levé.</p>
              <p>Dans le cas où l’état des lieux d’arrivée n’est pas validé par le Client, un litige sera ouvert. Oovoom Driver sera responsable de gérer le litige et aura le droit de fermer provisoirement la Prestation. La facture est alors bloquée, les sommes dues également, le temps de régler à l’amiable ce litige. Dans le cas où les Utilisateurs arrivent à une solution amiable, la Prestation est alors finalisée et payée comme initialement prévue.</p>
              <p>Dans le cas où les Utilisateurs n’arrivent pas à résoudre le litige, Oovoom Driver s’efforcera de trouver des solutions qui conviennent aux Utilisateurs. Le véhicule pourra alors être déposé dans un garage pour réparation.</p>
              <p>Les franchises pourront alors être retenues sur les avoirs en cours ou futurs du Prestataire.</p>
              <p>En annexe, se trouve les conditions d’assurance pour lesquelles chaque Driver est soumis lors de la prise d’un véhicule.</p>
              <p>Dans le cas où le Prestataire devra plus que les montants détenus dans son Wallet, Oovoom Driver se réserve le droit de recouvrir ces montants dus par tous moyens légaux.</p>
              <p><strong>Article 7 – Annulation d’une Prestation</strong></p>
              <p>En cas d’annulations répétées, Oovoom Driver se réserve le droit de procéder à la fermeture du Compte de l’Utilisateur, sans préavis ni indemnité et sans que sa responsabilité ne puisse être recherchée pour quelconque raison que ce soit.</p>
              <p><strong>Article 7.1 - Annulation par le Prestataire</strong></p>
              <p>Pour procéder à l’annulation d’une Prestation réservée, le Prestataire doit se rendre sur son Compte via la Plateforme et effectuer les démarches nécessaires à cette fin.</p>
              <p>Il doit également prévenir Oovoom Driver dans les plus brefs délais, par courriel à l’adresse suivante : contact@oovoom.com ou par téléphone au 01 80 82 44 44.</p>
              <p>En cas de récidive d’annulation, le Prestataire peut se voir appliquer une pénalité de vingt-cinq (25) €HT, qui est doublée dans les situations suivantes :</p>
              <ul>
              <li>Si l’annulation est effectuée moins d’une (1) heure avant l’heure prévue de mise à disposition du véhicule au départ,</li>
              </ul>
              <p>Ces pénalités sont cumulables et leurs montants correspondent aux frais de gestion et de recouvrement rendus nécessaires par les éventuelles annulations.</p>
              <p>En cas d’annulation pour force majeure, telle que définie à l’Article 18, le Prestataire peut voir sa pénalité supprimée sous réserve de la présentation de justificatif qu’Oovoom Driver considère à sa seule discrétion, valable et valide.</p>
              <p><strong>Article 7.2 - Annulation par le Client</strong></p>
              <p>Pour procéder à l’annulation d’une Prestation que le Client a réservée, ce dernier doit se rendre sur son compte via la Plateforme.</p>
              <p>Dans le cas où l’annulation survient dans les 3 minutes suivant la réservation, et avant la prise en charge, le Prestataire ne percevra aucune indemnité.</p>
              <p>Dans le cas où l’annulation survient au moins 3 minutes après la réservation, mais avant l’état des lieux de départ, le Prestataire percevra un coût forfaitaire de 2,50€HT.</p>
              <p>Par ailleurs, le Prestataire ne percevra pas de somme si le Client annule une Prestation dans l’un des cas suivants :</p>
              <ul>
              <li>Le Prestataire figure sur la liste de surveillance du Client (déclarée auprès de la CNIL) ;</li>
              <li>Le Client se trouve exceptionnellement dans l&#39;incapacité de délivrer le véhicule ;</li>
              <li>En cas d’anomalie dans la prise en charge de la Prestation telle que par exemple la non-conformité du Prestataire à sa photo sur la Plateforme ou à ses documents, l’état du Prestataire.</li>
              </ul>
              <p>Le Client devra alors le mentionner expressément dans la Plateforme.</p>
              <p>Oovoom Driver se réserve le droit,  à sa seule discrétion,  de suspendre le compte du Prestataire sans indemnité ni dédommagement et sans que sa responsabilité soit mise en cause à ce sujet.</p>
              <p>En cas d’annulation durant la Prestation, le Prestataire percevra la somme indiquée au départ de la Prestation.</p>
              <p><strong>Article 7.3 - Annulation par Oovoom Driver</strong></p>
              <p>Oovoom Driver se réserve le droit d’annuler une réservation par un Client dans le cas où, notamment, le Client se trouverait dans l’incapacité de délivrer le véhicule au point de départ dans la période horaire indiquée.</p>
              <p>Oovoom Driver doit en informer le Prestataire par tout moyen et dans les plus brefs délais en le contactant via les coordonnées fournies lors de son inscription sur la Plateforme.</p>
              <p><strong>Article 8 – Obligations des Prestataires et comportements interdits</strong></p>
              <p><strong>Article 8.1 – Obligations des Prestataires</strong></p>
              <p>Le Prestataire s’engage pleinement à respecter les obligations contenues dans les présentes CGU, et notamment toutes les obligations énumérées dans le présent Article.</p>
              <ol>
              <li>Le Prestataire s’engage à utiliser la Plateforme et les Services dans le respect des lois et des règlements en vigueur et sans porter atteinte aux droits et à la sécurité des tiers ou à l’ordre public.</li>
              <li>Le Prestataire s’engage à effectuer les Prestations avec prudence, professionnalisme et sécurité, dans le respect des modalités indiquées dans les présentes CGU et du Code(s) de la Route applicable(s) aux différents territoires traversés par le Prestataire lors de la Prestation.</li>
              <li>Le Prestataire s’engage à employer ses meilleurs efforts pour mener à bien la Prestation. En particulier, le Prestataire s’engage à apporter le plus grand soin au véhicule à conduire, à compléter l’état des lieux, à respecter les horaires pour (<strong>i</strong>) rejoindre le Client et/ou le véhicule à conduire à l’adresse de départ et (<strong>ii</strong>) remettre le véhicule conduit à l’Adresse de Remise et à entretenir des échanges courtois et respectueux avec tous ses interlocuteurs, quels qu’ils soient.</li>
              <li>Le Prestataire reconnaît et accepte les contraintes auxquelles il peut faire face lors de l’utilisation de la Plateforme et des Services. Il prend conscience des contraintes techniques dont il supporte pleinement la responsabilité, en particulier celles liées à la connexion Internet et de laquelle va dépendre la qualité des Services.</li>
              <li>En cas de problème, défaillance ou dysfonctionnement empêchant l’accès à la Plateforme, le Prestataire s’engage à prévenir Oovoom Driver dans les plus brefs délais, par courriel à l’adresse suivante <a href="mailto:contact@oovoom.com">contact@oovoom.com</a> ou par téléphone au +331 80 82 44 44.</li>
              <li>Le Prestataire s’engage à utiliser personnellement les Services sans concéder, transférer ou céder les droits et obligations qui en découlent à un tiers.</li>
              <li>(vii)Le Prestataire garantit l’exactitude et la véracité des informations et des documents qu’il fournit lors de son inscription, ainsi que lors de la validation d’une réservation de Prestations. Il s’engage notamment à s’assurer que les informations et documents le concernant soient complets et à jour. À défaut, il s’engage à les rectifier sans délais.</li>
              <li>(viii)Le Prestataire est seul responsable des informations communiquées aux Clients ainsi que des rapports et des échanges qui vont se créer entre eux dans le cadre des Prestations demandées</li>
              <li>Le Prestataire s’engage à effectuer l’ensemble des Prestations de bonne foi, notamment les états des lieux de départ et de remise.</li>
              <li>Le Prestataire s’engage à respecter et à se présenter au lieu, au jour et à l’heure convenus pour la remise du véhicule. De plus, il s’engage à ne pas annuler sans raison valable, ou de manière répétitive, les rendez-vous fixés et confirmés avec les Clients.</li>
              <li>Dès la validation de la Prestation, le Prestataire s’engage à mettre en fonctionnement la Plateforme ayant pour objectif de guider et d’estimer la durée de Prestation. Le Prestataire est informé que cette Plateforme permet également la géolocalisation du véhicule. À ce titre, le Prestataire s’engage à garder la Plateforme ainsi que la géolocalisation activent pendant toute la durée de la Prestation.</li>
              <li>(xii)De plus, le Prestataire s’engage à disposer d’un accès effectif à Internet durant toute la durée de la Prestation.</li>
              <li>(xiii)En cas d’anomalie(s) dans la réalisation de la Course tels que, entre autres, dépassement kilométrique suspect, erreur de remise du véhicule, retard, et pour des raisons d’amélioration de la Plateforme, le Prestataire est informé et accepte qu’Oovoom Driver se réserve le droit de consulter les informations relevées afin d’examiner l’itinéraire effectué.</li>
              <li>(xiv)Le Prestataire s’engage à coopérer activement avec Oovoom Driver et avec les Clients, notamment en leur fournissant toutes les informations nécessaires à la bonne exécution des Prestations demandées. En particulier, Oovoom Driver se réserve le droit de demander à tout moment au Prestataire tout document ou information complémentaires, que le Prestataire garantit fournir dans les plus brefs délais. À défaut, Oovoom Driver peut suspendre ou interdire au Prestataire l’accès à la Plateforme sans que sa responsabilité ne puisse être retenue à cet égard.</li>
              <li>(xv)Le Prestataire s’engage à souscrire une Assurance de Responsabilité Professionnelle avec tout organisme d’assurance.</li>
              <li>(xvi)Le Prestataire s’engage à toujours disposer d’un formulaire vierge de constat amiable d’accident automobile lors de la réalisation de toutes les Prestations. En cas de sinistre, le Prestataire devra obligatoirement dresser un constat amiable. A défaut, le prestataire s’engage à télécharger l’application oovoom et à faire établir le constat via l’application oovoom</li>
              <li>(xvii)Le Prestataire s’engage à être seul à bord du véhicule pendant toute la durée de la Prestation, sauf indication contraire expresse de la part du Client.</li>
              </ol>
              <p><strong>Article 8.2 – Comportements interdits</strong></p>
              <p>Toute violation des CGU ainsi que l’adoption d’un comportement prohibé par le présent Article, sont sanctionnées par l’Article 10.</p>
              <ol>
              <li>Il est strictement interdit d’employer les Services à des fins qui diffèrent de leur objet.</li>
              <li>Il est strictement interdit de réaliser une Prestation sous l’emprise d’alcool et/ou de produits stupéfiants.</li>
              <li>Il est strictement interdit de supprimer ou suspendre la géolocalisation pendant toute la durée de la Prestation.</li>
              <li>Il est strictement interdit de ralentir, nuire, interrompre ou empêcher la bonne exécution des Services. Ainsi, toute intrusion et tentative de piratage des systèmes d’Oovoom Driver sont strictement prohibés.</li>
              <li>Il est strictement interdit d’utiliser la Plateforme, ou de publier ou diffuser un contenu (<strong>i</strong>) contraire aux lois et règlements en vigueur, à l’ordre public et aux bonnes mœurs, (<strong>ii</strong>) portant atteinte à l’intérêt ou sécurité des tiers, (<strong>iii</strong>) frauduleux, mensonger ou trompeur, ou (<strong>iv</strong>) racistes et diffamatoires.</li>
              </ol>
              <p><strong>Article 9 – Garantie des Prestataires</strong></p>
              <p>Les Prestataires supportent la charge de toutes les plaintes, réclamations, actions et/ou revendications qu’Oovoom Driver pourrait subir du fait de la violation, par un Prestataire, de l’une quelconque de ses obligations et/ou garanties aux termes des présentes CGU.</p>
              <p>Ils s’engagent à indemniser Oovoom Driver intégralement de tout préjudice, réclamation, perte et/ou dommage qu’elle pourrait subir et auquel cas, à payer tous les coûts, dépenses, frais juridiques et/ou charges qu’elle aurait à supporter.</p>
              <p><strong>Article 10 – Conséquences résultants d’infractions et amendes ou de manquements</strong></p>
              <p><strong>Article 10.1 - Conséquences résultants d’infractions et amendes</strong></p>
              <p>Les infractions au Code de la Route ainsi que toutes amendes et contraventions qui découleraient de l’exécution par le Prestataire d’une Prestation sont à sa charge exclusive.</p>
              <p>Dans ce cas, le Prestataire sera averti par Oovoom Driver, par tout moyen écrit (courriel, courrier...).</p>
              <p>Le Prestataire s’engage à payer sans délai les montants relatifs aux infractions, amendes et/ou contraventions.  En cas de défaut de paiement dans les trois (3) jours suivant l’avertissement, Oovoom Driver envoie une mise en demeure au Prestataire. Une fois le délai de deux (2) jours écoulé après réception par le Prestataire de cette mise en demeure restée sans effet, Oovoom Driver se réserve le droit d’adopter toute mesure qu’elle juge appropriée.</p>
              <p>En cas d’infraction entrainant un retrait de point(s) sur le permis de conduire pendant la réalisation d’une Prestation, le Prestataire reconnaît et accepte qu’Oovoom Driver a l’obligation d’informer la Préfecture de Police et/ou le Client ayant reçu le procès-verbal de contravention sur l’identité du Prestataire ayant commis l’infraction.</p>
              <p>Toutes les informations demandées par la Préfecture de Police au sujet du Prestataire lui seront transmises par Oovoom Driver.</p>
              <p><strong>Article 10.2 - Conséquences résultants de manquements</strong></p>
              <p>En cas de manquement dans l’exécution de l’une des dispositions présentes dans les CGU ou encore de violation des lois et règlements en vigueur, Oovoom Driver envoie une mise en demeure adressée par lettre recommandée avec accusé de réception au Prestataire, exprimant son intention de se prévaloir des dispositions du présent Article. Une fois le délai de quinze jours écoulé après réception par le Prestataire de cette mise en demeure restée sans effet, Oovoom Driver se réserve le droit d’adopter toute mesure qu’elle juge appropriée.</p>
              <p>Ainsi, Oovoom Driver aura la libre discrétion de suspendre, restreindre ou supprimer l’accès à la Plateforme, ou tout contenu publié sur la Plateforme, sans qu’aucune indemnité ne puisse être réclamée, et sans qu’aucune formalité, procédure ou intervention judiciaire ne soit requise, et ce à tout moment.</p>
              <p>Par ailleurs, Oovoom Driver se réserve le droit d’avertir l’autorité concernée et d’intenter une action en justice si cela lui semble nécessaire.</p>
              <p><strong>Article 11 - Durée des Services et désinscription</strong></p>
              <p>Les Prestataires bénéficient des Services pour une durée indéterminée.</p>
              <p>Ils peuvent se désinscrire des Services en adressant une demande à cette fin par courrier recommandé avec accusé de réception, à l’adresse figurant dans le Préambule en respectant un préavis de quinze jours à compter de la réception par Oovoom Driver dudit courrier.</p>
              <p><strong>Article 12 – Assurance</strong></p>
              <p>Oovoom Driver souscrit une assurance dans le but d’assurer les véhicules objets des Prestations réalisées par le Prestataire. Le véhicule conduit est automatiquement assurée par Oovoom Driver, le temps de la Prestation.</p>
              <p>L’assurance démarre au moment de la validation de l’état des lieux de départ par le Client et prend fin par la validation de l’état des lieux de remise par le Client.</p>
              <p>En cas de sinistre, le Prestataire doit en aviser immédiatement Oovoom Driver ainsi que le Client via la Plateforme.</p>
              <p>[voir annexe]</p>
              <p><strong>Article 13 – Indépendance et absence de lien de subordination et d’exclusivité</strong></p>
              <p>Les Parties reconnaissent être partenaires indépendantes, mises en relation via la Plateforme, sans lien de subordination entre elles.</p>
              <p>Le Prestataire reconnaît et accepte qu’il exerce son activité en qualité de travailleur indépendant, sans aucune exclusivité ni lien de subordination avec Oovoom Driver. Ainsi, conformément à cette qualité, le Prestataire s’engage à exécuter des Prestations par le biais de la Plateforme dans la limite de 500€/mois. Ce plafond permet à Oovoom Driver de garantir l’absence d’exclusivité.</p>
              <p>Le Prestataire est entièrement libre de développer à sa guise sa clientèle en dehors de la Plateforme et de conclure des accords similaires avec toute autre personne, entité ou société même concurrente d’Oovoom Driver.</p>
              <p>À ce titre, le choix du Prestataire de limiter son activité à Oovoom Driver relève de sa responsabilité exclusive et ne saurait lui permettre de revendiquer une quelconque exclusivité ou de prétendre à l’existence d’un lien de subordination avec Oovoom Driver.</p>
              <p><strong>Article 14 – Absence de Responsabilité</strong></p>
              <p>Agissant comme plateforme de mise en relation entre les Prestataires et les Clients, Oovoom Driver met à leur disposition les outils techniques leur permettant d’entrer en contact afin de fixer un rendez-vous et fournir pour les premiers, et recevoir pour les seconds, des Prestations.</p>
              <ul>
              <li>Relation avec les Clients</li>
              </ul>
              <p>Oovoom Driver agit en son nom et n’intervient aucunement au nom ou pour le compte de l’un quelconque des Utilisateurs. Ces derniers contractent directement et strictement entre eux, sans l’intervention ou la participation d’Oovoom Driver. La responsabilité d’Oovoom Driver ne saurait en aucun cas être engagée, et Oovoom Driver ne sera aucunement tenu de supporter une quelconque obligation au titre des potentiels litiges relatifs, d’une quelconque manière, à la relation entre le Prestataire et le Client.</p>
              <p>En effet, Oovoom Driver ne pourra en aucun cas être tenu responsable notamment (i) de la mauvaise exécution ou non-exécution de la Prestation par le Prestataire, (ii) des informations fournies par le Prestataire et affichées sur la Plateforme, et cela quand bien même Oovoom Driver aurait procédé à des vérifications de quelque nature que ce soit, (iii) des notes et des commentaires publiés par les Clients, sur lesquelles elle peut effectuer un contrôle, (iv) de la modification ou de l’annulation par le Client du rendez-vous fixé et des conséquences que cela peut entraîner, ou (v) du défaut de paiement par le Client.</p>
              <p>Oovoom Driver ne pourra en aucun cas être tenu responsable de tout dommage direct, indirect, accessoire, spécial, punitif ou consécutif, quel qu’il soit, qui en résulte, mais également en cas d’indisponibilité de tout ou partie de la Plateforme.</p>
              <p>Le Prestataire et le Client sont les seuls responsables dans les situations mentionnées ci-dessus, ainsi que dans toutes les situations qui les lient entre eux. Oovoom Driver ne sera en aucun cas responsable de tout acte, déclaration, omission, ou de toute violation ou négligence de l’un d’eux, ou de tout dommage en découlant.</p>
              <p>En conséquence, tout litige doit être résolu directement entre le Client et le Prestataire, Oovoom Driver pouvant, le cas échéant, servir d’intermédiaire lorsque cela est prévu de manière expresse et non équivoque dans les présentes CGU.</p>
              <ul>
              <li>Contenu de la Plateforme et fonctionnement du réseau internet</li>
              </ul>
              <p>Le Prestataire reconnaît et accepte d’utiliser la Plateforme sous sa propre responsabilité et renonce à engager la responsabilité d’Oovoom Driver à cet effet.</p>
              <p>Bien qu’Oovoom Driver prenne des mesures raisonnables pour assurer l’exactitude et la fiabilité des données et autres informations affichées sur la Plateforme, notamment celles relatives aux Prestataires, Oovoom Driver ne garantit rien à cet égard.</p>
              <p>En aucun cas Oovoom Driver, ses dirigeants, directeurs, employés ou agents, ne seront responsables de tout dommage direct, indirect, accessoire, spécial, punitif ou consécutif, quel qu’il soit, résultant (<strong>i</strong>) d’erreurs, de fautes ou d’inexactitudes dans le contenu de la Plateforme, (<strong>ii</strong>) de blessures corporelles ou de dommages matériels, de quelque nature que ce soit, résultant de l’accès et de l’utilisation de la Plateforme, (<strong>iii</strong>) de toute utilisation non autorisée des serveurs sécurisés d’Oovoom Driver et d’accès à toutes les informations personnelles et/ou financières qui y sont stockées, (<strong>iv</strong>) de toute interruption des serveurs d’Oovoom Driver, (<strong>v</strong>) de tout bogue, virus ou autre, qui pourrait être transmis à ou via le service d’Oovoom Driver par un tiers, ou (<strong>vi</strong>) de tout dommage de quelque nature que ce soit résultant d’un fait quelconque, lié à l’utilisation de la Plateforme.</p>
              <p>Les Prestataires reconnaissent et acceptent qu’Oovoom Driver ne peut être tenu responsable de leur classement et/ou ordre d’affichage sur la Plateforme, et ne peuvent lui reprocher un manque de visibilité.</p>
              <p>Oovoom Driver décline toute responsabilité en cas d’interruption temporaire ou définitive de la Plateforme, ou des difficultés ou incapacités à y accéder, notamment pour des raisons liées à des perturbations du réseau internet. De plus, la responsabilité d’Oovoom Driver ne peut être engagée en cas de perte des informations liées au compte du Prestataire ou du Client.</p>
              <p>La Plateforme peut inclure des liens hypertextes qui redirigent vers des sites internet exploités par des tiers. Oovoom Driver n’est pas responsable du contenu de ces sites web tiers liés et n’est donc pas responsable des informations qui y sont contenues qui peuvent être liés à la Plateforme au moyen d’un hyperlien.</p>
              <p><strong>Article 15 - Convention sur la preuve</strong></p>
              <p>Les Prestataires reconnaissent et acceptent expressément que :</p>
              <ul>
              <li>Les Identifiants permettent d’identifier valablement l’auteur d’un document et d’établir l’authenticité dudit document,</li>
              <li>Les informations saisies par les Prestataires les engagent dès leur validation,</li>
              <li>Tout document électronique comportant les Identifiants d’un Prestataire a la même valeur juridique qu’un écrit signé par lui,</li>
              <li>Les opérations effectuées ainsi que les mentions inscrites sur la Plateforme font foi de la réalité des opérations accomplies dans le cadre des Services proposés et constituent le principal mode de preuve admis.</li>
              </ul>
              <p><strong>Article 16 – Confidentialité et protection des données personnelles</strong></p>
              <p>Conformément aux lois et règlements en vigueur relatives à la protection des données personnelles, Oovoom Driver recueille certaines informations ou données personnelles afin d’assurer le bon fonctionnement de la Plateforme et de la mise en relation. Oovoom Driver garantie notamment le respect du Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données ainsi que la loi n˚78-17 « Informatique et Libertés » du 6 janvier 1978 telle que modifiée par la loi du 20 juin 2018 (ci-après dénommées collectivement les « <strong>Lois et Règlements</strong> »).</p>
              <ul>
              <li>Données collectées</li>
              </ul>
              <p>Lors de l’inscription, les Utilisateurs sont informés des données indispensables, signalées par un astérisque, afin qu’Oovoom Driver puisse traiter et valider leur inscription sur la Plateforme. En cas de refus ou de défaut de communication de l’une quelconque de ces données, l’inscription ne sera pas validée et la Plateforme ne pourra donc pas être utilisée.</p>
              <p>Oovoom Driver se réserve le droit de collecter les données personnelles des Utilisateurs qui sont entre autres les suivantes : le nom, le prénom, l’adresse postale, l’adresse courriel, la date de naissance, le numéro de téléphone, les coordonnées bancaires, et une photocopie de la pièce d’identité.</p>
              <p>En particulier pour le Prestataire, les données suivantes sont également collectées : une photocopie de son permis de conduire, le numéro de permis de conduire, sa géolocalisation lors de la réalisation de chaque Prestation, les incidents survenus lors de la réalisation d’une Prestation tels qu’un retard, sinistre, amende, avis des Clients, violation des règles fixées dans les présentes CGU durant une Prestation.</p>
              <ul>
              <li>Utilisation des données collectées</li>
              </ul>
              <p>L’utilisation et le traitement des données collectées par Oovoom Driver est indispensable afin de permettre aux Utilisateurs d’accéder à la Plateforme et de l’utiliser ainsi que de réserver et d’exécuter des Prestations. De plus, ces données sont nécessaires à l’exécution des CGU, au développement de la Plateforme et à l’amélioration de son  fonctionnement.</p>
              <p>L’utilisation des données relatives à la géolocalisation est basée sur le consentement du Prestataire.</p>
              <p>Les Prestataires par la présente autorisent expressément Oovoom Driver à fournir toutes les informations requises  les concernant en sa possession aux Clients.</p>
              <p>Le traitement automatisé de données à caractère personnel consiste à effectuer un profilage et ainsi à utiliser ces données pour évaluer certains aspects de la personne concernée, et analyser ou prédire ses intérêts, son comportement et d&#39;autres attributs. Cela est nécessaire afin d’assurer un niveau de qualité et de sécurité des Prestations effectués par les Prestataires au profit des Clients.</p>
              <p>Les Prestataires autorisent Oovoom Driver à fournir certaines informations à ses prestataires techniques afin de les faire bénéficier de certaines fonctions de la Plateforme (forum, avis, commentaires, …)</p>
              <p>Oovoom Driver s’engage à veiller à la sécurisation des données personnelles collectées et à en préserver la confidentialité.</p>
              <ul>
              <li>Conservation des données collectées</li>
              </ul>
              <p>Les données personnelles sont conservées afin de répondre aux besoins du Prestataire et pour que Oovoom Driver respecte ses obligations légales.</p>
              <p>Les données personnelles sont conservées par  Oovoom Driver pour la durée nécessaire à la réalisation des Prestations et/ou jusqu’à la clôture du Compte du Prestataire, et cela conformément aux obligations légales et règlementaires.</p>
              <p>Seules certaines données peuvent être conservées durant les 3 ans qui suivent la durée nécessaire à la réalisation des Prestations et/ou la clôture du Compte par le Prestataire. Les données personnelles liées à la géolocalisation sont conservées pendant 1 an afin de garder la preuve de la/des Prestation(s) réalisé(s) par le Prestataire.</p>
              <p>Par ailleurs,  Oovoom Driver peut être amené à conserver certaines données en vue de  satisfaire aux obligations légales et  règlementaires, notamment afin de faire valoir ses droits et/ou à des fins statistiques ou historiques. En effet, dans le cadre de toute demande effectuée par les autorités administratives ou judiciaires, Oovoom Driver peut être amené à communiquer des données personnelles.</p>
              <p>À l’expiration des périodes mentionnées ci-dessus, les données personnelles collectées seront supprimées ou rendues anonymes par  Oovoom Driver.</p>
              <p>Les données personnelles sont traitées par Oovoom Driver ainsi que par les prestataires techniques qui soutiennent l’activité (hébergeur du site, moteurs de recherches ...) agissant en qualité de sous-traitant d’Oovoom Driver</p>
              <p>En cas de sinistre, les Prestataires reconnaissent et acceptent qu’Oovoom Driver communique leurs données personnelles à son assureur le cas échéant.</p>
              <ul>
              <li>Collecte et utilisation des informations non personnelles</li>
              </ul>
              <p>À l’instar de nombreuses organisations commerciales, Oovoom Driver surveille l’utilisation de cette Plateforme en recueillant des informations globales. Oovoom Driver peut recueillir automatiquement des informations non personnelles concernant les Prestataires, telles que le type de navigateur internet qu’ils utilisent, les pages internet visitées ou le site web qui a dirigé vers la Plateforme. Ces informations ne permettent pas d’identifier les Utilisateurs et ne sont utilisées que pour aider Oovoom Driver à fournir un service efficace sur cette Plateforme</p>
              <ul>
              <li>Engagements et droits des Prestataires</li>
              </ul>
              <p>Les Prestataires s’engagent :</p>
              <ol>
              <li>à respecter les dispositions des lois et règlements en vigueur et notamment les Lois et Règlements relatives à la protection des données personnelles auxquels ils ont accès dans le cadre des Prestations, et leurs conséquences éventuelles et plus généralement de l’utilisation de la Plateforme ;</li>
              <li>à ne pas exploiter les données personnelles relatives à leurs Clients, notamment à des fins commerciales, et à les traiter dans le cadre de l’exécution des Prestations et dans le seul objectif de fournir les meilleures Prestations ;</li>
              <li>à conserver lesdites données personnelles uniquement pour la durée des Prestations et leurs conséquences éventuelles et procéder par la suite à leur destruction sans délai ;</li>
              <li>à recueillir le consentement des Clients pour toute collecte et traitement de leurs données personnelles, tout en les informant des conditions de cette collecte ainsi que des droits dont ils disposent relativement à ces données ;</li>
              <li>à garantir la protection, la préservation de la sécurité, et la conservation de la confidentialité des données personnelles des Clients ;</li>
              <li>à communiquer des données valides et à jour de leur situation ;</li>
              <li>(vii)à accepter de faire l’objet d’un profilage par Oovoom Driver à des fins de qualité et de sécurité des Prestations effectuées pour les Clients ;</li>
              <li>(viii)à ne pas communiquer les données personnelles de toute personne tierce avec laquelle ils seraient entrés en contact dans le cadre de la réalisation des Prestations ; et,</li>
              <li>à consentir à la géolocalisation des véhicules dans le cadre de la réalisation des Prestations.</li>
              </ol>
              <p>Les Prestataires bénéficient d’un droit d’accès, de rectification, de suppression, de portabilité de leurs données personnelles, d’opposition au traitement de leurs données collectées et traitées par Oovoom Driver en adressant une demande accompagnée d’une copie de leur carte d’identité à l’adresse courriel suivante : contact@oovoom.com ou par format papier à l’adresse d’Oovoom Driver indiquée au Préambule.</p>
              <p>Si malgré les efforts employés par Oovoom pour préserver la confidentialité des données personnelles des Utilisateurs, ces derniers estiment que leurs droits ne sont pas respectés, Oovoom Driver les rappelle qu’ils  disposent de la possibilité de saisir l’autorité nationale de contrôle en matière de traitement de données (en France : <a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>).</p>
              <ul>
              <li>Données bancaires</li>
              </ul>
              <p>Les informations et données bancaires fournies par les Prestataires dans le cadre de la réalisation des Prestations sont traitées par le prestataire responsable de la gestion des paiements sur la Plateforme.</p>
              <ul>
              <li>Avertissement</li>
              </ul>
              <p>Les Prestataires reconnaissent et acceptent expressément que, dès lors qu’ils fournissent des informations personnelles sur internet, ces informations peuvent être collectées et utilisées par des tiers.</p>
              <ul>
              <li>Utilisation des Cookies</li>
              </ul>
              <p>Oovoom Driver reçoit et enregistre des informations du navigateur ou du smartphone des Utilisateurs lorsqu’ils utilisent la Plateforme et utilise des « Cookies » pour collecter ces informations. Les « Cookies » sont de petits fichiers de données amovibles, ne contenant aucune information personnelle, qui sont automatiquement stockés par le navigateur internet ou smartphone des Utilisateurs. Ils permettent aux Utilisateurs de transporter des informations sur la Plateforme d’Oovom Driver sans avoir à les saisir à nouveau. Ils contiennent des informations sur l’utilisation de la Plateforme sans permette à Oovoom Driver d’en identifier l’utilisateur. Ils sont utilisés à des fins de publicité et de mesure d’audience (nombre de visites, de pages vues, …)</p>
              <p>En cas de refus des « Cookies », Oovoom Driver ne garantit pas le fonctionnement optimal de la Plateforme.</p>
              <p>Le Prestataire reconnaît et accepte qu’Oovoom Driver se réserve la possibilité d’implanter un « Cookie » dans son ordinateur ou son smartphone afin d’enregistrer toute information relative à la navigation sur la Plateforme.</p>
              <p>Si les Utilisateurs ne souhaitent pas qu’un « Cookie » soit implanté dans leur ordinateur ou leur smartphone, il leur est possible de paramétrer leur navigateur ou smartphone en suivant la procédure décrite dans le lien suivant « <a href="https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur">https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur</a> »</p>
              <p><strong>Article 17 – Droits de propriété intellectuelle</strong></p>
              <p>Le design, et le contenu de la Plateforme, ainsi que les textes, bases de données, mise en page, scripts, liens hypertextes, graphiques, visuels, fonctions interactives, logiciels, dessins, images, logos, vidéos, éléments sonores, codes, et les marques de commerce, marques de service, dénominations sociales, noms commerciaux, enseignes et autres droits de la propriété intellectuelle et industrielle (ci-après les « <strong>Droits de propriété intellectuelle</strong> ») qui y sont contenus sont et demeurent la propriété exclusive, pleine et entière d’Oovoom Driver et sont protégés par les Droits de propriété intellectuelle et les droits des producteurs des bases de données en vigueur.</p>
              <p>L’utilisation de la Plateforme n’implique pas l’autorisation ou la cession d’un droit d’exploitation d’un quelconque contenu figurant sur la Plateforme. Toute utilisation, reproduction, diffusion, adaptation, traduction ou représentation totale ou partielle de toute ou partie de cette Plateforme, par quelque procédé que ce soit, et sur quelconque support est strictement prohibée et constitue une contrefaçon sanctionnée par le Code de la propriété intellectuelle, sauf autorisation expresse, écrite et préalable d’Oovoom Driver en sa qualité d’auteur et de titulaire de l’intégralité des Droits de propriété intellectuelle.</p>
              <p>Oovoom Driver se réserve le droit d’entamer des poursuites judiciaires et de réclamer une indemnisation de l’entier préjudice contre toute personne qui violerait cette interdiction.</p>
              <p>Seule l’utilisation pour un usage privé et à des fins non commerciales est autorisée, sous réserve du respect des Droits de propriété intellectuelle.</p>
              <p><strong>Article 18 - Force majeure</strong></p>
              <p>De façon expresse, sont considérés comme cas de force majeure ou cas fortuits, outre ceux habituellement retenus par la jurisprudence française : les grèves totales ou partielles, internes ou externes à l’entreprise, lock-out, intempérie, épidémie, blocage de moyen de transport ou d’approvisionnement pour quelque raison que ce soit, tremblement de terre, incendie, tempête, inondation, dégâts des eaux, restrictions gouvernementales ou légales, modification légale ou réglementaire des formes de commercialisation, panne d’ordinateurs, blocage des moyens de télécommunications, y compris les réseaux, et tout autre évènement extérieur, imprévisible et irrésistible, indépendant de la volonté expresse des Parties et empêchant l’exécution normale des présentes CGU.</p>
              <p><strong>Article 19 - Autres clauses</strong></p>
              <p>Dans l’hypothèse où l’une des dispositions contenues dans les présentes CGU serait déclarée intégralement ou partiellement nulle en vertu d’une loi, règlement ou décision judiciaire, cette annulation ou invalidité n’affectera pas les autres dispositions des CGU, dont les termes resteront pleinement en vigueur et conserveront leur plein et entier effet. Oovoom Driver pourra dans ce cas procéder à la rédaction d’une nouvelle disposition tout en respectant le droit positif applicable aux CGU.</p>
              <p><strong>Article 20 - Traitement des réclamations - Médiation</strong></p>
              <p>Le Prestataire est informé de l’existence d’un service « Réclamations » qu’il peut joindre en cas de problème aux coordonnées suivantes : +33 1 80 82 44 44</p>
              <p>Le Prestataire qui revêt la qualité de consommateur dispose du droit de saisir gratuitement un médiateur de la consommation en cas de contestation, aux fins d’une résolution amiable d’un litige découlant de l’interprétation ou de l’exécution des présentes Conditions, selon les conditions énoncées aux Articles L.611-1 et suivants et R.612-1 et suivants du Code de la consommation. Ils peuvent ainsi entrer en contact avec le médiateur de la consommation.</p>
              <p>Le Prestataire pourra également résoudre le litige par le biais de la plateforme de Résolution des litiges en ligne mise en place par la Commission européenne, accessible à l’adresse suivante : ﷟ <a href="https://ec.europa.eu/consumers/odr.">https://ec.europa.eu/consumers/odr.</a></p>
              <p><strong>Article 21 - Droit applicable et juridictions compétentes</strong></p>
              <p>Les présentes CGU sont soumises à la loi française.</p>
              <p>Tout différend ou litige qui surviendrait dans le cadre des présentes CGU et notamment dans le cadre de leur interprétation ou de leur exécution et qui ne serait pas résolu à l’amiable dans un délai de trente jours suivant sa survenance, sera soumis aux tribunaux compétents du ressort des Tribunaux de Paris, nonobstant une pluralité de défendeurs ou un appel en garantie le cas échéant.</p>
              <p>ANNEXE</p>
              <table>
              <thead>
              <tr>
              <th>Inclus</th>
              <th>Garanties</th>
              <th>Franchise</th>
              <th>Plafonds</th>
              </tr>
              </thead>
              <tbody>
              <tr>
              <td>Oui</td>
              <td>Responsabilité civile</td>
              <td>Aucune franchise</td>
              <td>Dommages corporels : illimités</td>
              </tr>
              </tbody>
              </table>
              <p>Dommages matériels : 
              50 000 000€ |
              | Oui | Défense Pénale et Recours | Seuil d’intervention pour un préjudice supérieur à 300€ HTVA | Application du barème par juridiction dans la limite de 13 500€ HTVA
              se référer aux Conditions générales |
              | Oui | Incendie | 800€ | Valeur à dire d’expert |
              | Oui | Tempête et Grêle | 800 € | Valeur à dire d’expert |
              | Oui | Evènements climatiques | 800€ | Valeur à dire d’expert |
              | Oui | Vol | 800€ | Valeur à dire d’expert |
              | Oui | Bris de glaces | - Réparation : 0€</p>
              <ul>
              <li>Remplacement : 75 € | Frais réels |
              | Oui | Catastrophes naturelles | 380€ | Valeur à dire d’expert |
              | Oui | Catastrophes technologiques | Aucune franchise | Valeur à dire d’expert |
              | Oui | Attentats et actes de terrorisme | 800 € | Valeur à dire d’expert |
              | Oui | Dommages tous accidents | 800 € | Valeur à dire d’expert |
              | Oui | Option « Aménagements du véhicule (dont coffre de toit) » | 800€ | [1500€ ; 3000€] |</li>
              </ul>
              `,
        }}
      />
    </Box>
  );
};

TermsConditionsProBackup.propTypes = {
  boxBackground: PropTypes.object,
  text: PropTypes.object,
};

TermsConditionsProBackup.defaultProps = {
  boxBackground: {
    background: '#FFFFFF',
    padding: '10%',
  },
  title: {
    fontSize: ['24px', '26px', '28px', '30px', '32px'],
    fontWeight: '800',
    color: '#ff9d00',
    lineHeight: '1.34',
    mb: ['10%', '10%', '7.5%', '5%', '5%'],
    textAlign: 'center',
    letterSpacing: '-0.025em',
  },
};

export default TermsConditionsProBackup;
