import styled from 'styled-components';
import Background from 'common/assets/image/background.png';

const FeaturesAdvancedWrapper = styled.section`
  padding: 12.5% 0 0 0;
  position: relative;
  overflow: hidden;
  .backgroungImg {
    position: absolute;
    top: 80px;
    z-index: -1;
    pointer-events: none;
  }
  .col {
    justify-content: center;
  }
`;

export const SquaresArea = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 990px) {
    margin-top: 50px;
    padding-left: 0;
  }
  .card {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: box-shadow 0.3s ease-in-out;
    z-index: 1;
    background: #ffffff;
    box-shadow: 0px 0px 13px rgb(0 0 0 / 25%);
    cursor: pointer;
    @media (max-width: 480px) {
      padding: 20px;
    }
    @media (max-width: 360px) {
      width: 100%;
      margin-left: 0;
    }
    &:hover {
      box-shadow: 0px 16px 35px 0px rgba(15, 66, 100, 0.2);
    }
    img {
      height: 100px;
      @media (max-width: 1440px) {
        height: 80px;
      }
      @media (max-width: 990px) {
        height: 50px;
      }
    }
    p {
      color: #ff9d00;
      font-size: 20px;
      line-height: 40px;
      font-weight: 600;
      margin-top: 30px;
      @media (max-width: 767px) {
        display: block;
      }
      @media (max-width: 460px) {
        font-size: 16px;
        margin-top: 5px;
        text-align: center;
      }
    }
  }
`;

export const FeaturesAdvancedDriverBackground = styled.div`
  height: 125vh;
  background: url(${Background});
  background-size: cover;
`;

export const FeaturesAdvancedDriverWrapper = styled.div`
  background: #ff9d00;
  img {
    max-width: 100%;
  }
  h2 {
    letter-spacing: 1.5px;
    @media only screen and (min-width: 1441px) {
      margin: 100px 0 100px;
    }
    @media only screen and (min-width: 1440px) {
      margin: 65px 0 65px;
    }
    @media only screen and (min-width: 767px) {
      margin: 50px 0 50px;
    }
  }
`;

export const FeaturesAdvancedDriverWrapperWhite = styled.div`
  background: #ffffff;
  img {
    max-width: 100%;
  }
  h2 {
    letter-spacing: 1.5px;
    @media only screen and (min-width: 1441px) {
      margin: 100px 0 100px;
    }
    @media only screen and (min-width: 1440px) {
      margin: 65px 0 65px;
    }
    @media only screen and (min-width: 767px) {
      margin: 50px 0 50px;
    }
  }
`;

export const InnerBackground = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  background: #ffc876;
  -moz-border-radius: 350px;
  -webkit-border-radius: 350px;
  border-radius: 350px;
`;

export default FeaturesAdvancedWrapper;
