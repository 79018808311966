import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'common/components/Container';
import Image from 'common/components/Image';
import Button from 'common/components/Button';

import NotFoundWrapper from './notfound.style.js';

const FourOFourImage = 'image/404.svg';

const NotFound = ({ button }) => {
  const Data = useStaticQuery(graphql`
    query GetNotFoundButtonText {
      driverJson {
        seoData {
          notfound {
            buttonText
          }
        }
      }
    }
  `);

  const { buttonText } = Data?.driverJson?.seoData?.notfound;

  return (
    <Container className="404-container" noGutter mobileGutter width="1200px">
      <NotFoundWrapper>
        <Image src={FourOFourImage} alt="404" />
        <a href="/">
          <Button {...button} title={buttonText} />
        </a>
      </NotFoundWrapper>
    </Container>
  );
};

NotFound.propTypes = {
  wrapper: PropTypes.object,
  button: PropTypes.object,
};

NotFound.defaultProps = {
  button: {
    borderRadius: '25px',
    padding: '0 10%',
    backgroundColor: '#FF9D00',
  },
};

export default NotFound;
