import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'common/components/Container';
import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Card from 'common/components/Card';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Separator from 'common/components/Separator';

import FeaturesAdvancedWrapper, {
  SquaresArea,
  FeaturesAdvancedDriverBackground,
  FeaturesAdvancedDriverWrapper,
  FeaturesAdvancedDriverWrapperWhite,
  InnerBackground,
} from './featuresadvanced.style';

const WhyChooseUsDriver1 = 'image/whydriver1.svg';
const WhyChooseUsDriver2 = 'image/whydriver2.svg';
const WhyChooseUsDriver3 = 'image/whydriver3.svg';

const WhyChooseUsPro1 = 'image/whypro1.svg';
const WhyChooseUsPro2 = 'image/whypro2.svg';
const WhyChooseUsPro3 = 'image/whypro3.svg';

const DoublePhoneDriver = 'image/doublephonedriver.png';
const SinglePhoneDriver = 'image/singlephonedriver.png';

const FeaturesAdvanced = ({
  title,
  row,
  col,
  col2,
  col3,
  col4,
  col5,
  cardStyle,
  head,
  text,
  text2,
  text3,
  driver,
}) => {
  const Data = useStaticQuery(graphql`
    query GetFeaturesAdvancedData {
      driverJson {
        animatedFeaturesData {
          contentTitle
          driver {
            titleDriver1
            titleDriver2
            titleDriver3
            descriptionDriver1
            descriptionDriver12
            descriptionDriver13
            descriptionDriver2
            descriptionDriver22
            descriptionDriver23
            descriptionDriver3
            descriptionDriver32
            titleAdvanced1
            titleAdvanced2
            descriptionAdvanced1
            descriptionAdvanced12
            descriptionAdvanced13
            descriptionAdvanced14
            descriptionAdvanced15
            descriptionAdvanced2
            descriptionAdvanced3
          }
          driverpro {
            titlePro1
            titlePro2
            titlePro3
            descriptionPro1
            descriptionPro2
            descriptionPro3
          }
        }
      }
    }
  `);

  const { contentTitle } = Data?.driverJson?.animatedFeaturesData;
  const {
    titleDriver1,
    titleDriver2,
    titleDriver3,
    descriptionDriver12,
    descriptionDriver13,
    descriptionDriver2,
    descriptionDriver22,
    descriptionDriver23,
    descriptionDriver3,
    descriptionDriver32,
    titleAdvanced1,
    titleAdvanced2,
    descriptionAdvanced1,
    descriptionAdvanced12,
    descriptionAdvanced13,
    descriptionAdvanced14,
    descriptionAdvanced15,
    descriptionAdvanced2,
    descriptionAdvanced3,
  } = Data?.driverJson?.animatedFeaturesData?.driver;
  const {
    titlePro1,
    titlePro2,
    titlePro3,
    descriptionDriver1,
    descriptionPro1,
    descriptionPro2,
    descriptionPro3,
  } = Data?.driverJson?.animatedFeaturesData?.driverpro;

  const forceCardStyle = {
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '30px',
    marginTop: '10px',
    marginBottom: '7.5px',
    textAlign: 'center',
  };

  return (
    <FeaturesAdvancedWrapper
      id="featured"
      style={
        driver
          ? { background: '#ff9d00', paddingTop: '0' }
          : {
              background: 'linear-gradient(180deg, #383838 0%, #1C1C1C 66.15%)',
            }
      }
    >
      {driver ? (
        <Fragment>
          <FeaturesAdvancedDriverBackground />
          <Separator
            color={'#FF9D00'}
            angle={-6}
            height={'8.5%'}
            marginTop={'-6%'}
          />
          <Separator
            color={'#FFC876'}
            angle={-6}
            height={'5%'}
            marginTop={'calc(-6% - 1px)'}
          />

          <FeaturesAdvancedDriverWrapper>
            <Container width="1260px">
              <Box className="row" {...row}>
                <Box className="col" {...col2}>
                  <Fade direction="left">
                    <Heading
                      as="h2"
                      {...head}
                      dangerouslySetInnerHTML={{ __html: titleAdvanced1 }}
                    />
                    <Text {...text} content={descriptionAdvanced1} />
                    <Text {...text3} content={descriptionAdvanced12} />
                    <Text {...text3} content={descriptionAdvanced13} />
                    <Text {...text3} content={descriptionAdvanced14} />
                    <Text {...text3} content={descriptionAdvanced15} />
                  </Fade>
                </Box>
                <Box className="col" {...col3}>
                  <Fade direction="right">
                    <InnerBackground />
                    <Image
                      src={DoublePhoneDriver}
                      className="double_phone_driver"
                      alt="Feature Double Phone Driver"
                    />
                  </Fade>
                </Box>
              </Box>
            </Container>
          </FeaturesAdvancedDriverWrapper>

          <Separator
            color={'#FF9D00'}
            angle={-6}
            height={'6.5%'}
            marginTop={'-6.5%'}
          />

          <FeaturesAdvancedDriverWrapperWhite>
            <Container width="1260px">
              <Box className="row" {...row}>
                <Box className="col" {...col5}>
                  <Fade direction="left">
                    <Image
                      src={SinglePhoneDriver}
                      className="single_phone_driver"
                      alt="Feature Single Phone Driver"
                    />
                  </Fade>
                </Box>
                <Box className="col" {...col4}>
                  <Fade direction="right">
                    <Heading
                      as="h2"
                      {...head}
                      dangerouslySetInnerHTML={{ __html: titleAdvanced2 }}
                    />
                    <Text {...text2} content={descriptionAdvanced2} />
                    <Text {...text2} content={descriptionAdvanced3} />
                  </Fade>
                </Box>
              </Box>
            </Container>
          </FeaturesAdvancedDriverWrapperWhite>
        </Fragment>
      ) : null}

      <Container width="1260px">
        <Heading content={contentTitle} {...title} />
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {driver ? (
              <SquaresArea>
                <Card className="card" {...cardStyle}>
                  <Image src={WhyChooseUsDriver1} alt="Image1" />
                  <Text content={titleDriver1} />
                  <Text style={forceCardStyle} content={descriptionDriver1} />
                  <Text style={forceCardStyle} content={descriptionDriver12} />
                  <Text style={forceCardStyle} content={descriptionDriver13} />
                </Card>
                <Card className="card" {...cardStyle}>
                  <Image src={WhyChooseUsDriver2} alt="Image2" />
                  <Text content={titleDriver2} />
                  <Text style={forceCardStyle} content={descriptionDriver2} />
                  <Text style={forceCardStyle} content={descriptionDriver22} />
                  <Text style={forceCardStyle} content={descriptionDriver23} />
                </Card>
                <Card className="card" {...cardStyle}>
                  <Image src={WhyChooseUsDriver3} alt="Image3" />
                  <Text content={titleDriver3} />
                  <Text style={forceCardStyle} content={descriptionDriver3} />
                  <Text style={forceCardStyle} content={descriptionDriver32} />
                </Card>
              </SquaresArea>
            ) : (
              <SquaresArea>
                <Card className="card" {...cardStyle}>
                  <Image src={WhyChooseUsPro1} alt="Image1" />
                  <Text content={titlePro1} />
                  <Text style={forceCardStyle} content={descriptionPro1} />
                </Card>
                <Card className="card" {...cardStyle}>
                  <Image src={WhyChooseUsPro2} alt="Image2" />
                  <Text content={titlePro2} />
                  <Text style={forceCardStyle} content={descriptionPro2} />
                </Card>
                <Card className="card" {...cardStyle}>
                  <Image src={WhyChooseUsPro3} alt="Image3" />
                  <Text content={titlePro3} />
                  <Text style={forceCardStyle} content={descriptionPro3} />
                </Card>
              </SquaresArea>
            )}
          </Box>
        </Box>
      </Container>
    </FeaturesAdvancedWrapper>
  );
};

FeaturesAdvanced.propTypes = {
  title: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  col3: PropTypes.object,
  col4: PropTypes.object,
  col5: PropTypes.object,
  cardStyle: PropTypes.object,
  head: PropTypes.object,
  text: PropTypes.object,
  text2: PropTypes.object,
  text3: PropTypes.object,
};

FeaturesAdvanced.defaultProps = {
  row: {
    flexBox: true,
    justifyContent: 'center',
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    width: ['100%', '50%', '50%'],
    p: '0 15px 5% 15px',
    flexBox: true,
    alignSelf: 'center',
  },
  col2: {
    width: ['100%', '50%', '50%'],
    pr: '16.5%',
    flexBox: true,
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'right',
  },
  col3: {
    width: ['100%', '50%', '50%'],
    p: '10% 15px 10% 15px',
    flexBox: true,
    alignSelf: 'center',
    zIndex: '999',
  },
  col4: {
    width: ['100%', '45%', '45%'],
    pr: '16.5%',
    flexBox: true,
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'left',
  },
  col5: {
    width: ['100%', '50%', '50%'],
    p: '0 15px 5% 15px',
    flexBox: true,
    alignSelf: 'center',
    zIndex: '999',
  },
  title: {
    fontSize: ['21px', '24px', '26px', '30px', '36px'],
    textShadow: '0px 1px, 1px 0px, 1px 1px',
    fontWeight: '800',
    color: '#FFFFFF',
    pt: '5%',
    mb: '5%',
    maxWidth: '100%',
    textAlign: 'center',
  },
  cardStyle: {
    m: '0 2.5% 0 2.5%',
    p: '50px 25px 35px',
    borderRadius: '50px',
    boxShadow: '0px 8px 20px 0px rgba(15, 66, 100, 0.075)',
  },
  head: {
    fontSize: ['21px', '24px', '26px', '30px', '36px'],
    textShadow: '0px 1px, 1px 0px, 1px 1px',
    fontWeight: '800',
  },
  text: {
    padding: '0 0 30px 0',
    color: '#FFFFFF',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'right',
    mt: '5%',
  },
  text2: {
    padding: '0 0 30px 0',
    color: '#FF9900',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  text3: {
    padding: '0 0 5px 0',
    color: '#FFFFFF',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'right',
  },
};

export default FeaturesAdvanced;
