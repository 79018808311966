import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';

import BlockWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './partner.style';

const SecuriteRoutiereLogoBlack = 'image/securiteroutiere-black.svg';

const Partner = ({
  row,
  col1,
  col2,
  text,
  background,
  textColor,
  driverPro,
}) => {
  const Data = useStaticQuery(graphql`
    query GetPartnerData {
      driverJson {
        partnerData {
          securiteRoutiere {
            driverText
            driverProText
          }
        }
      }
    }
  `);

  const { driverText, driverProText } =
    Data?.driverJson?.partnerData?.securiteRoutiere;
  const textFinal = driverPro ? driverProText : driverText;

  return (
    <BlockWrapper className="partner-block">
      <Container width="1260px" style={{ background: `${background}` }}>
        {driverPro ? (
          <Box className="row" {...row}>
            <Box className="col-driver-pro" {...col1}>
              <ContentWrapper>
                <Text
                  {...text}
                  content={textFinal}
                  style={{ color: `${textColor}` }}
                />
              </ContentWrapper>
            </Box>
            <Box className="col" {...col1}>
              <ImageWrapper>
                <Image
                  src={SecuriteRoutiereLogoBlack}
                  alt="Securite Routiere Logo"
                />
              </ImageWrapper>
            </Box>
          </Box>
        ) : (
          <Box className="row" {...row}>
            <Box className="col-driver" {...col2}>
              <ImageWrapper>
                <Image
                  src={SecuriteRoutiereLogoBlack}
                  alt="Securite Routiere Logo"
                />
              </ImageWrapper>
            </Box>
            <Box className="col" {...col2}>
              <ContentWrapper>
                <Text
                  {...text}
                  content={textFinal}
                  style={{ color: `${textColor}` }}
                />
              </ContentWrapper>
            </Box>
          </Box>
        )}
      </Container>
    </BlockWrapper>
  );
};

Partner.propTypes = {
  row: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  button: PropTypes.object,
  text: PropTypes.object,
};

Partner.defaultProps = {
  row: {
    alignItems: 'center',
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col1: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '10%',
    m: '5% 0 5% 0',
  },
  col2: {
    width: ['100%', '50%', '50%'],
    pr: '15px',
    pl: '10%',
    m: '5% 0 5% 0',
  },
  text: {
    color: '#FFFFFF',
    fontWeight: '800',
    lineHeight: '32px',
    letterSpacing: '0.5px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'black',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    minHeight: 'auto',
  },
};

export default Partner;
