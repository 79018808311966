import React from 'react';
import PropTypes from 'prop-types';
import LoaderStyle from './loader.style';

const Loader = ({ loaderColor, className, ...props }) => {
  const allClasses = ['rc-loading'];

  if (className) {
    allClasses.push(className);
  }
  return (
    <LoaderStyle
      className={allClasses.join(' ')}
      loaderColor={loaderColor}
      {...props}
    />
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loaderColor: PropTypes.string,
};

Loader.defaultProps = {};

export default Loader;
