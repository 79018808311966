import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql, Link as LocalLink } from 'gatsby';
import { StoreLink } from '../../common/tools';

import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Link from 'common/components/Link';

import BlockWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './features.style';

const FeatureDriver = 'image/driver-features.png';
const FeatureDriverPro = 'image/driver-pro-features.png';
const DriverLogoMain = 'image/driver-logo-main.svg';
const DriverProLogo = 'image/driver-pro-features-logo.svg';

const Features = ({
  head,
  row,
  col1,
  col2,
  userText,
  driverText,
  downloadText,
  downloadText2,
  button,
  buttonWrapper,
  strangeBox,
}) => {
  const Data = useStaticQuery(graphql`
    query GetFeaturesData {
      driverJson {
        featuresData {
          driver {
            name
            title
            description
            description2
            description3
            buttonText
            download
          }
          driverPro {
            name
            title
            description
            description2
            description3
            buttonText
            download
          }
        }
      }
    }
  `);

  const driverData = Data?.driverJson?.featuresData?.driver;
  const driverProData = Data?.driverJson?.featuresData?.driverPro;

  return (
    <Fragment>
      <BlockWrapper className="features-block" id="driver">
        <Container width="1260px" id="driver">
          <Box className="row" {...row}>
            <Box className="col" {...col1}>
              <ContentWrapper id={driverData?.name}>
                <Fade direction="left">
                  <Box className="absolute-wrapper-driver">
                    <Box
                      {...strangeBox}
                      id="absolute-1-driver"
                      className="absolute-box"
                    >
                      <Fragment />
                    </Box>
                    <Box
                      {...strangeBox}
                      id="absolute-2-driver"
                      className="absolute-box"
                    >
                      <Fragment />
                    </Box>
                    <Box
                      {...strangeBox}
                      id="absolute-3-driver"
                      className="absolute-box"
                    >
                      <Fragment />
                    </Box>
                    <Heading
                      as="h2"
                      id="absolute-title-driver"
                      {...head}
                      dangerouslySetInnerHTML={{ __html: driverData?.title }}
                    />
                  </Box>
                  <Fade cascade damping={0.75}>
                    <Text
                      {...userText}
                      style={{ paddingTop: '32.5%' }}
                      content={driverData?.description}
                    />
                    <Text {...userText} content={driverData?.description2} />
                    <Text {...userText} content={driverData?.description3} />
                    <ImageWrapper {...buttonWrapper}>
                      <Text
                        className="download-text"
                        {...downloadText}
                        content={driverData?.download}
                      />
                      <Link
                        href={StoreLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="driver_main"
                        className="driver_button"
                      >
                        <Button
                          {...button}
                          className="download"
                          image={DriverLogoMain}
                        />
                      </Link>
                    </ImageWrapper>
                  </Fade>
                </Fade>
              </ContentWrapper>
            </Box>
            <Box className="col" {...col1}>
              <ImageWrapper>
                <Fade direction="right">
                  <Image
                    src={FeatureDriver}
                    className="feature_driver_image"
                    alt="Driver Feature"
                  />
                  <LocalLink
                    to="driver"
                    id="user_feature"
                    className="feature_button"
                  >
                    <Button {...button} title={driverData?.buttonText} />
                  </LocalLink>
                </Fade>
              </ImageWrapper>
            </Box>
          </Box>
        </Container>
      </BlockWrapper>

      <BlockWrapper className="features-block" id="driver-pro">
        <Container width="1260px" id="driver-pro">
          <Box className="row" {...row}>
            <Box className="col" {...col2}>
              <ImageWrapper>
                <Fade direction="left">
                  <Image
                    src={FeatureDriverPro}
                    className="feature_driver_pro_image"
                    alt="Driver Pro Feature"
                  />
                  <LocalLink
                    to="driver-pro"
                    id="driver_feature"
                    className="feature_button"
                  >
                    <Button {...button} title={driverProData?.buttonText} />
                  </LocalLink>
                </Fade>
              </ImageWrapper>
            </Box>
            <Box className="col" {...col2}>
              <ContentWrapper id={driverProData?.name}>
                <Fade direction="right">
                  <Box className="absolute-wrapper">
                    <Box
                      {...strangeBox}
                      id="absolute-1"
                      className="absolute-box"
                    >
                      <Fragment />
                    </Box>
                    <Box
                      {...strangeBox}
                      id="absolute-2"
                      className="absolute-box"
                    >
                      <Fragment />
                    </Box>
                    <Box
                      {...strangeBox}
                      id="absolute-3"
                      className="absolute-box"
                    >
                      <Fragment />
                    </Box>
                    <Heading
                      as="h2"
                      id="absolute-title"
                      {...head}
                      dangerouslySetInnerHTML={{ __html: driverProData?.title }}
                    />
                  </Box>
                  <Fade cascade damping={0.75}>
                    <Text
                      {...driverText}
                      content={driverProData?.description}
                    />
                    <Text
                      {...driverText}
                      content={driverProData?.description2}
                    />
                    <Text
                      {...driverText}
                      content={driverProData?.description3}
                    />
                    <ImageWrapper {...buttonWrapper}>
                      <Text
                        className="download-text"
                        {...downloadText2}
                        content={driverData?.download}
                      />
                      <Link
                        href={StoreLink(true)}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="driver_main"
                        className="driver_pro_button"
                      >
                        <Button
                          {...button}
                          className="download"
                          image={DriverProLogo}
                        />
                      </Link>
                    </ImageWrapper>
                  </Fade>
                </Fade>
              </ContentWrapper>
            </Box>
          </Box>
        </Container>
      </BlockWrapper>
    </Fragment>
  );
};

Features.propTypes = {
  head: PropTypes.object,
  row: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  userFeatures: PropTypes.object,
  proFeatures: PropTypes.object,
  button: PropTypes.object,
  userText: PropTypes.object,
  driverText: PropTypes.object,
  strangeBox: PropTypes.object,
  buttonWrapper: PropTypes.object,
  downloadText: PropTypes.object,
  downloadText2: PropTypes.object,
};

Features.defaultProps = {
  head: {
    fontWeight: '800',
    textShadow: '0px 1.5px, 1.5px 0px, 1.5px 1.5px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col1: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15%',
    mb: '30px',
  },
  col2: {
    width: ['100%', '50%', '50%'],
    pl: '45px',
    pr: '15px',
    mb: '30px',
  },
  userText: {
    padding: '0 0 30px 15%',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  driverText: {
    padding: '0 0 30px 15%',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'black',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    minHeight: 'auto',
  },
  strangeBox: {
    width: '999px',
    height: '5rem',
    backgroundColor: '#FFFFFF',
  },
  buttonWrapper: {
    flexBox: true,
    flexDirection: 'column',
    alignItems: 'center',
  },
  downloadText: {
    fontSize: '34px',
    color: '#000000',
  },
  downloadText2: {
    fontSize: '34px',
    color: '#FFFFFF',
  },
};

export default Features;
