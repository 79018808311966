import styled from 'styled-components';

const FooterWrapper = styled.section`
  padding: 120px 0;
  background-repeat: no-repeat;
  background-position: 100% center;
  overflow: hidden;
  z-index: 1;
  @media (max-width: 1440px) {
    padding: 80px 0 60px;
  }
  .download-app {
    margin-top: 60px;
  }
  .imageWrapper {
    display: flex;
    margin: 5% 0 5% 0;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    img {
      margin-right: 15px;
      @media (max-width: 1200px) {
        margin-bottom: 15px;
        margin-right: 0;
        width: 150px;
      }
    }
  }
  .footer-left {
    text-align: center;
    text-align: -webkit-center;
  }
  .footer-center {
    margin-top: 2.5%;
    text-align: center;
    text-align: -webkit-center;
    @media (min-width: 990px) {
      margin-right: 5%;
    }
  }
  .footer-right {
    text-align: center;
    text-align: -webkit-center;
  }
`;

const ExtraFooterWrapper = styled.section`
  padding: 15px 0;
  background: #ffffff;
  background-repeat: no-repeat;
  background-position: 100% center;
  overflow: hidden;
  z-index: 1;
  @media (max-width: 1440px) {
    padding: 20px 0 20px;
  }
`;

const List = styled.ul`
  width: max-content;
  text-align: left;
  @media (max-width: 1199px) {
    text-align: -webkit-center;
  }
`;

const ListItem = styled.li`
  a {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover {
      color: #FFFFFF;
      text-shadow: 0 1px 0px #FFFFFF;
  }
`;

export const Newsletter = styled.div`
  display: flex;
  background: #ffffff;
  border-radius: 30px;
  height: 45px;

  .email_input {
    align-self: center;
    padding: 0 10px 0 10px;
  }
  .class_input {
    flex-grow: 1;
    input {
      border: none;
      color: #000000;
      &:focus {
        outline: none;
      }
    }
  }
  .class-button {
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
  }
`;

export { ExtraFooterWrapper, List, ListItem };

export default FooterWrapper;
