import PropTypes from 'prop-types';
import React, { useContext, Fragment } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { DrawerContext } from 'common/contexts/DrawerContext';

import Box from 'common/components/Box';
import Link from 'common/components/Link';

const ScrollMenu = ({ className, menuItems, drawerClose, ...props }) => {
  const { dispatch } = useContext(DrawerContext);
  const scrollItems = [];
  menuItems?.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const allClasses = ['scroll-menu'];

  if (className) {
    allClasses.push(className);
  }

  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <Box
      items={scrollItems}
      className={allClasses.join(' ')}
      drawerClose={drawerClose}
      {...props}
    >
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`}>
          {menu.staticLink ? (
            <a href={menu.path}>{menu.label}</a>
          ) : (
            <Fragment>
              {drawerClose ? (
                <Fragment>
                  <Link href={menu.path} onClick={toggleDrawer}>
                    {menu.label}
                  </Link>
                </Fragment>
              ) : (
                <Link href={menu.path}>{menu.label}</Link>
              )}
            </Fragment>
          )}
        </li>
      ))}
      <li>
        <Link onClick={() => scrollTo('#contact')}>Contact</Link>
      </li>
    </Box>
  );
};

ScrollMenu.propTypes = {
  className: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  scrolledPastClassName: PropTypes.string,
  componentTag: PropTypes.string,
  style: PropTypes.object,
  offset: PropTypes.number,
  rootEl: PropTypes.string,
  onUpdate: PropTypes.func,
};

ScrollMenu.defaultProps = {
  componentTag: 'ul',
};

export default ScrollMenu;
