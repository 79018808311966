import { useStaticQuery, graphql } from 'gatsby';

export const useMetaLogo = () => {
  const Data = useStaticQuery(graphql`
    query GetMetaLogoSrc {
      allImageSharp {
        edges {
          node {
            original {
              src
            }
          }
        }
      }
    }
  `);

  return Data?.allImageSharp?.edges[0]?.node?.original?.src;
};
