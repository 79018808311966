import styled from 'styled-components';

const BlockWrapper = styled.div`
  margin: 0 auto 0;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  .col-driver {
    text-align: right;
    text-align: -webkit-right;
  }
  .col-driver-pro {
    text-align: right;
    text-align: -webkit-right;
  }
  padding: 55px 70px 0 55px;
  @media only screen and (max-width: 1360px) {
    padding: 20px 30px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-left: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 30px;
    transform: rotateY(0deg);
  }
`;

export const ContentWrapper = styled.div`
  p {
    text-align: center;
    max-width: 60%;
    font-size: 125%;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }
`;

export default BlockWrapper;
