import styled from 'styled-components';

const BlockWrapper = styled.div`
  #driver {
    background: #ff9d00;
    color: #ffffff;
  }
  #driver-pro {
    background: #131313;
    color: #ffffff;
  }
  #driver-features {
    width: 100%;
    float: left;
    text-align: left;
  }
  #driver-pro-features {
    width: 75%;
    float: left;
    text-align: left;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 70px;
  @media only screen and (max-width: 1360px) {
    padding: 20px 30px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  text-align: center;
  padding-left: 10%;
  .feature_driver_image {
    width: 120%;
  }
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-left: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 30px;
    transform: rotateY(0deg);
  }
  .circle {
    background: red;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  button {
    color: #000000;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    width: 15rem;
    border-radius: 22.5px;
    &:hover {
      box-shadow: 3.3px 3.3px 2.5px 2.5px rgb(0 0 0 / 10%);
    }
  }
  button.download {
    width: 25rem;
    height: 7.5rem;
    border-radius: 3rem;
  }
  button .btn-image {
    width: -webkit-fill-available;
    width: -moz-available;
    height: -webkit-fill-available;
    height: -moz-available;
    width: inherit;
    height: inherit;
  }
  button .btn-image img {
    width: inherit;
    height: inherit;
    padding: 25px;
  }
  .download-text {
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
  }
`;

export const ContentWrapper = styled.div`
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }
  #absolute-title {
    width: max-content;
    margin-left: 10rem;
    color: #131313;
    letter-spacing: 2.5px;
    font-size: 28px;
  }
  #absolute-title-driver {
    width: max-content;
    margin-left: 2.5rem;
    color: #ff9d00;
    letter-spacing: 2.5px;
    font-size: 28px;
  }
  p:last-of-type {
    margin-bottom: 5%;
  }
  h2 {
    letter-spacing: 1.5px;
    padding-left: 20%;
    @media only screen and (min-width: 1441px) {
      margin: 100px 0 100px;
    }
    @media only screen and (min-width: 1440px) {
      margin: 65px 0 65px;
    }
    @media only screen and (min-width: 767px) {
      margin: 50px 0 50px;
    }
  }
  .absolute-wrapper {
    margin-bottom: 7.5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .absolute-wrapper-driver {
    position: absolute;
    left: -25%;
    margin-bottom: 15%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .absolute-box {
    position: absolute;
    z-index: -1;
  }
  #absolute-1 {
    width: 1000px;
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
  }
  #absolute-2 {
    margin-left: 1.5%;
    background-color: #000000;
    width: 5rem;
    clip-path: polygon(80% 0, 100% 0, 40% 100%, 20% 100%);
  }
  #absolute-3 {
    margin-left: 20%;
    background-color: #000000;
    width: 5rem;
    clip-path: polygon(80% 0, 100% 0, 40% 100%, 20% 100%);
  }
  #absolute-1-driver {
    width: 780px;
    clip-path: polygon(94% 0, 0 0, 0 100%, 100% 100%);
  }
  #absolute-2-driver {
    margin-left: 145%;
    background-color: #ff9d00;
    width: 5rem;
    clip-path: polygon(20% 0, 0 0, 60% 100%, 80% 100%);
  }
  #absolute-3-driver {
    margin-left: 128.5%;
    background-color: #ff9d00;
    width: 5rem;
    clip-path: polygon(20% 0, 0 0, 60% 100%, 80% 100%);
  }
`;

export default BlockWrapper;
