import React from 'react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';

import { driverTheme } from 'common/theme/index';

import { ResetCSS } from 'common/assets/css/style';

import { DrawerProvider } from 'common/contexts/DrawerContext';

import Footer from 'containers/Footer';
import Navbar from 'containers/Navbar';
import ReservationForm from 'containers/Reservation';
import Why from 'containers/Why';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/index.style.js';

const forceCardStyle = {
  paddingTop: '10px',
};

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const ContactUs = ({ location }) => {
  return (
    <ThemeProvider theme={driverTheme}>
      <AlertProvider template={AlertTemplate} {...options}>
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <ContentWrapper>
            <ReservationForm fullwhite main />
            <Why />
            <Footer driver />
          </ContentWrapper>
        </AppWrapper>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default ContactUs;
