import React from 'react';
import PropTypes from 'prop-types';

import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/Container';

import StaticWrapper from './static1.style';

const TermsConditions = ({ boxBackground, title }) => {
  return (
    <Box {...boxBackground} id="static" className="static_box" as="section">
      <Container>
        <StaticWrapper className="wrapper">
          <Heading
            as="h2"
            {...title}
            dangerouslySetInnerHTML={{
              __html: 'Conditions générales d’utilisation',
            }}
          />
          <div
            className="static-text"
            dangerouslySetInnerHTML={{
              __html: `<p><strong>Conditions générales d’utilisation de la Plateforme Oovoom Driver pour les Clients</strong></p>
              <p>Bienvenue sur la plateforme Oovoom Driver Pro à laquelle il est possible d’accéder soit par le biais du site internet <a href="https://www.oovoomdriver.com/">https://www.oovoomdriver.com</a> ou directement sur l’AppStore ou PlayStore en téléchargeant l’application mobile Oovoom Driver (ci-après la « <strong>Plateforme</strong> » ou l’ « <strong>Application</strong> »).</p>
              <p>En accédant à cette Plateforme, toute personne accepte sans réserve d’être liée par l’ensemble des présentes conditions générales d’utilisation (ci-après les « <strong>CGU</strong> ») qu’elle confirme avoir lu dans leur intégralité.</p>
              <p>La Plateforme est exploitée par la société SCHS OOVOOMDRIVER, Société par Actions Simplifiée au capital de 10 012 €, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 918 414 111, dont le siège social est situé au 111 avenue Victor Hugo 75116 Paris (ci-après « <strong>Oovoom Driver</strong> »).</p>
              <p>Oovoom Driver peut être contactée aux coordonnées suivantes :</p>
              <p>Adresse postale : 111 avenue Victor Hugo – 75016 - Paris 
              Téléphone : +33 (0)1 80 82 44 44
              Adresse courriel : contact@oovoom.com</p>
              <p><strong>ARTICLE 1 : OBJET</strong></p>
              <p>Les présentes CGU ont pour objet de définir les conditions d’utilisation et les modalités de mise à disposition d’un service de mise en relation (ci-après les « <strong>Services</strong> ») entre toute personne physique ou morale (ci-après le « <strong>Client</strong> ») et tout conducteur inscrit sur la Plateforme (ci-après le « <strong>Prestataire</strong> ») aux fins d’effectuer une prestation de service relative au véhicule d’un Client et pour le compte de ce dernier (ci-après conjointement les « <strong>Utilisateurs</strong> »).</p>
              <p>Oovoom Driver et les Utilisateurs sont dénommées collectivement les « <strong>Parties</strong> ».</p>
              <p>Les présentes CGU ont pour objet de définir les relations entre les Clients, les Prestataires et Oovoom Driver dans le cadre des mises en relation proposées via la Plateforme, en particulier les modalités et les conditions dans lesquelles les Prestataires exécutent les diverses prestations choisies par les Clients sur la Plateforme.</p>
              <p>Ces relations s’inscrivent en dehors de toute exclusivité et de tout lien de subordination entre Oovoom Driver et les Utilisateurs.</p>
              <p>Les présentes CGU peuvent être complétées par des conditions d’utilisation spécifiques à certains services proposés sur la Plateforme, qui prévalent sur les CGU en cas de contradiction.</p>
              <p>Les présentes CGU devront être signées à l’occasion de la création d’un compte pour le Client, et de la validation de l’inscription pour le Prestataire.</p>
              <p>Oovoom Driver se réserve le droit, à tout moment et sans préavis, d’améliorer, de modifier, d’altérer, de suspendre ou d’interrompre définitivement tout ou partie de la Plateforme et d’en restreindre ou d’en interdire l’accès.</p>
              <p>De plus, Oovoom Driver se réserve le droit de modifier et/ou de mettre à jour les présentes CGU à tout moment et sans préavis. Toutes les modifications entrent en vigueur immédiatement après leur publication. Le Client doit consulter régulièrement la dernière version des CGU affichée à l’adresse « <a href="https://oovoomdriver.com/CGU">https://oovoomdriver.com/<strong>CGU</strong></a>» et est réputé l’accepter à chaque nouvelle connexion sur la Plateforme.</p>
              <p>En cas de problème, défaillance ou dysfonctionnement empêchant l’accès à la Plateforme, les Utilisateurs s’engagent à prévenir Oovoom Driver dans les plus brefs délais, par courriel à l’adresse suivante <a href="mailto:contact@oovoom.com">contact@oovoom.com</a> ou par téléphone au +33 1 80 82 44 44.</p>
              <p><strong>ARTICLE 2 : PRESENTATION GENERALE DE LA PLATEFORME</strong></p>
              <p><strong>Article 2.1 : Présentation de la Plateforme</strong></p>
              <p>La société Oovoom Driver a développé une Plateforme, ainsi qu’une Application d’intermédiation entre des Clients souhaitant bénéficier d’une prestation sur leur véhicule (ci-après une « <strong>Prestation</strong> »), et un Prestataire qui se charge de l’effectuer.</p>
              <p>Les Prestations proposées par le biais de la Plateforme et telles que définies à l’Article 5.1, sont les suivantes : les Prestataires vont conduire le véhicule d’un Client (<strong>i</strong>) soit pour effectuer un trajet (ci-après une « <strong>Course</strong> »), (<strong>ii</strong>) soit pour effectuer une prestation de service supplémentaire (ci-après les « <strong>Prestations de Service Supplémentaires</strong> »), et cela en contrepartie d’une somme monétaire.</p>
              <p>Les Prestataires disponibles sont présentés sous forme de listes de résultats obtenus à l’occasion d’une recherche sur la Plateforme et sont référencés grâce à un système de géolocalisation, du plus proche au plus éloigné par rapport au secteur géographique recherché par le Client.</p>
              <p><strong>Article 2.2 : Accès à la Plateforme</strong></p>
              <p>La Plateforme est accessible 24 heures sur 24, 7 jours sur 7 et toute l’année, sauf exceptions notamment en cas de force majeure, telle que définie à l’Article 17, ou de la survenance d’un événement irrésistible, sous réserve des périodes de maintenance ou de pannes éventuelles et pour lesquelles Oovoom Driver ne pourra être tenue responsable.</p>
              <p>Le Client doit préalablement créer un compte (ci-après le « <strong>Compte</strong>), et sera amené à fournir des justificatifs afin de garantir la véracité de l’intégralité des informations renseignées. L’inscription et la création du Compte sur la Plateforme sont soumises au contrôle et à la validation d’Oovoom Driver.</p>
              <p>Dans le cas d’un dysfonctionnement de la Plateforme, Oovoom Driver s’engage à faire de son mieux afin de procéder aux opérations de réparation nécessaires dans les meilleurs délais, sans pour autant être tenue d’une quelconque obligation de résultat et sans que sa responsabilité ne puisse être mise en cause à ce sujet.</p>
              <p><strong>ARTICLE 3 : INSCRIPTION SUR LA PLATEFORME</strong></p>
              <p>Toute personne désirant recevoir une Prestation de service quelle qu&#39;elle soit (effectuer une Course, ou Prestation de Service Supplémentaire), en sa qualité de Client, doit préalablement s’inscrire sur la Plateforme Oovoom Driver.</p>
              <p><strong>Article 3.1 : Conditions relatives au Client</strong></p>
              <p>Seuls les Clients qui remplissent les conditions suivantes sont susceptibles d’être détenteurs d’un Compte sur la Plateforme et de réserver des Prestations sur leur véhicule :</p>
              <ul>
              <li>Être une personne physique âgée d’au moins 18 ans, ou personne morale, professionnel ou particulier ;</li>
              <li>Être propriétaire d’un véhicule ou être expressément autorisé par le propriétaire d’en disposer ;</li>
              <li>Proposer un véhicule immatriculé en France, disposant de 4 roues, d’un tonnage inférieur ou égal à 3,5 tonnes et ne dépassant pas les 7 places ;</li>
              <li>Proposer un véhicule assuré à l’année, conformément aux obligations légales en vigueur ;</li>
              <li>Proposer un véhicule conforme à l’ensemble des dispositions légales et règlementaires applicables, en parfait état de fonctionnement et de sécurité, et avoir notamment effectué l’entretien et les révisions préconisées par le constructeur automobile. À noter que les véhicules en attente d’une contre-visite, même s’ils sont autorisés à circuler, ne peuvent pas faire l’objet d’une Prestation ; et,</li>
              <li>Disposer de tous les documents et équipements relatifs au véhicule qui sont obligatoires de par la loi et les règlements.</li>
              </ul>
              <p>À noter que, le cas échéant, des conditions particulières différentes peuvent être expressément acceptées par écrit par Oovoom Driver en lieu et place de tout ou partie des conditions précitées.</p>
              <p><strong>Article 3.2 – Informations et documents communiqués</strong></p>
              <p>Afin de s’inscrire sur la Plateforme, les Clients doivent suivre la procédure décrite ci-dessous.</p>
              <p>Lors de son inscription sur la Plateforme, le Client doit préalablement créer un Compte, et remplir un formulaire en renseignant à ce titre, l’ensemble des  informations requises pour valider son inscription, à savoir : civilité, prénom, nom, date de naissance, numéro de téléphone, adresse postale, adresse courriel, coordonnées bancaires (carte de paiement) et mot de passe.</p>
              <p>Ces informations peuvent différer en cas d’ouverture d’un compte professionnel et inclure notamment l’adresse postale et le siège postal de l’Utilisateur, une adresse email pour la gestion du compte et l’envoi des factures, les adresses des structures de l’Utilisateur (agences, garages, concessions...).</p>
              <p>Par ailleurs, le Client devra fournir les documents suivants :</p>
              <ul>
              <li>Pièce d’identité ;</li>
              <li>Carte Grise</li>
              <li>Attestation d’Assurance standard du véhicule ;</li>
              <li>Clé</li>
              <li>Carte Bancaire.</li>
              </ul>
              <p>Le Client garantit que les informations fournies par ses soins sont complètes, exactes et dénuées de vice, et s’engage à les mettre à jour sans délai en cas de changement. Le Client s’engage aussi à fournir tous les justificatifs requis par Oovoom Driver, à première demande.</p>
              <p>À savoir qu’un Client ne peut ouvrir qu’un seul et unique compte sur la Plateforme.</p>
              <p>Les Clients acceptent les présentes CGU en cochant une case qui y est destinée dans le formulaire d’inscription.</p>
              <p><strong>Article 3.3 – Authentification par Oovoom Driver</strong></p>
              <p>Suite à la réception en bonne et due forme de tous les documents et informations préalablement énoncés, ces derniers feront l’objet d’une vérification approfondie par Oovoom Driver ou par toute société tierce spécialisée dans l’analyse de documents.</p>
              <p>En cas d’information et/ou document erronés quels qu’ils soient, cela entraînera l’invalidation du Compte. Le Client ne pourra ni se connecter ni se réinscrire sur la Plateforme.</p>
              <p>En cas d’inscription incomplète, celle-ci ne sera pas validée.</p>
              <p>Par ailleurs, Oovoom Driver se réserve le droit, à sa seule discrétion, de refuser l’inscription d’un Client sur la Plateforme, pour quelque raison que ce soit ou sans aucun motif, sans préavis ni notification et sans que sa responsabilité ne soit mise en cause à ce sujet.</p>
              <p>De plus, Oovoom Driver se réserve le droit de suspendre ou résilier, sans préavis ni indemnités, tout compte qui serait créé sous une fausse identité ou à des fins frauduleuses.</p>
              <p><strong>Article 3.4 – Validation du Compte</strong></p>
              <p>Une fois les informations vérifiées et validées conformes, le Client recevra un courriel de validation du Compte, auquel il pourra accéder grâce à un identifiant qui lui est attribué et un mot de passe préalablement choisi par lui (ci-après les « <strong>Identifiants</strong> »). Le Compte lui permettra notamment de mettre à jour les informations fournies lors de son inscription et de gérer l’utilisation des Services en général.</p>
              <p>Le Client s’engage à conserver ses Identifiants secrets et demeure le seul responsable de leur utilisation. Tout accès à la Plateforme par l’usage des Identifiants est réputé avoir été effectué par le Client en question. Il est responsable de toute conséquence qui résulte de la perte ou du vol de ses Identifiants et s’engage dans ce cas à les modifier sans délais.</p>
              <p>Le Client s’engage à utiliser son Compte à titre personnel, confidentiel et incessible, et est pleinement responsable en cas d’utilisation de son Compte par un tiers en son nom ou pour son compte.</p>
              <p><strong>Article 3.5 : Conditions tenant à la qualité de Prestataire</strong></p>
              <p>Seuls les Prestataires remplissent les conditions suivantes sont susceptible d’être validés par la Plateforme et proposer des Prestations pour le compte d’un Client :</p>
              <ul>
              <li>Être âgé d’au moins 21 ans ;</li>
              <li>Être titulaire du permis de conduire français, ou d’une équivalence délivrée par une préfecture française, correspondant au modèle du véhicule à conduire, depuis au moins 3 ans ;</li>
              <li>Ne pas faire au moment de chaque Prestation, d’une suspension ou d’un retrait de son permis de conduire ;</li>
              <li>Ne pas avoir fait l’objet de condamnation pour conduite en état d’ivresse ou sous l’emprise de stupéfiants ;</li>
              <li>Durant les 3 années précédant la Prestation, ne pas avoir causé un sinistre (sinistre responsable) lié à la conduite, ne pas avoir fait l’objet d’une résiliation de police d’assurance automobile, d’un retrait et ne pas avoir été condamné pénalement pour un crime ou un délit routier ; et,</li>
              <li>Posséder un casier judiciaire vierge.</li>
              </ul>
              <p>À noter que, le cas échéant, des conditions particulières différentes peuvent être expressément acceptées par écrit par Oovoom Driver en lieu et place de tout ou partie des conditions précitées.</p>
              <p><strong>ARTICLE 4 : Description des Services proposés</strong></p>
              <p>La Plateforme permet aux Clients de choisir et réserver les prestations d’un Prestataire sur leur véhicule telles qu’(<strong>i</strong>) effectuer une Course en conduisant leur véhicule et (<strong>ii</strong>) réaliser des Prestations de Services Supplémentaires demandées par eux sur le véhicule, au travers de la Plateforme Oovoom Driver Pro, et cela dans les conditions prévues ci-après.</p>
              <p><strong>Article 4.1 : Réservation par le Client</strong></p>
              <p>Le Client réserve le Prestataire de son choix dans la liste des Prestataires disponibles dans le secteur géographique recherché, tout en indiquant les informations suivantes :</p>
              <ul>
              <li>Les adresses exactes des lieux de remise du véhicule au départ et à l’arrivée ;</li>
              <li>Les dates et horaires de disponibilité du véhicule pour sa remise au départ et à l’arrivée ;</li>
              <li>Les informations et caractéristiques du véhicule à conduire telles que sa catégorie, sa marque, son modèle, le numéro de sa plaque d’immatriculation, son nombre de place, etc. ; et,</li>
              <li>Ses coordonnées.</li>
              </ul>
              <p>Notons que nous n’acceptons que des véhicules dont la <strong>valeur à dire d’expert</strong> au moment de la commande est <strong>inférieure à 80.000€.</strong> Ainsi, si le véhicule du Client a une valeur supérieure à cettevaleur, le Client prend alors la responsabilité de la course et engage ainsi l’assurance de son propre véhicule et non l’assurance d’OovoomDriver. En effet, l’assurance actuelle d’OovoomDriver ne permet pas une prise en charge de véhicule ayant une valeur supérieur à 80.000€ (valeur d’expertise).</p>
              <p>Par ailleurs, lors de la réservation, le Client est informé des informations suivantes :</p>
              <ul>
              <li>La distance qui sera parcourue lors de la Prestation, (modulo une tolérance de 20% en cas de route barré, de circulation intense…)</li>
              <li>Le fait que le Prestataire est soumis à une franchise de 800€ afin de garantir le véhicule contre les incendies et les dommages, vol</li>
              <li>Les conditions d’assurance,</li>
              <li>Le prix de la prestation global (prix prestataire – assurance et frais d’intermédiation inclus) ainsi que le prix des options choisies par le Client.</li>
              </ul>
              <p><strong>Article 4.2 : Validation entre les Utilisateurs</strong></p>
              <p>La réservation d’une Prestation manifeste la volonté du Client. L’accord de volonté est caractérisé par la validation de la réservation par le Prestataire. De plus, par la validation de la réservation, le Prestataire s’engage à effectuer la Prestation dans le respect des modalités indiquées dans l’offre de réservation effectuée par le Client, selon les dispositions des présentes CGU et du/des Code(s) de la Route applicable(s) aux différents territoires traversés lors de la Prestation.</p>
              <p>La réservation d’une Prestation est finalisée au moment où les Utilisateurs acceptent les conditions de la Prestation à effectuer telles qu’affichées par la Plateforme.</p>
              <p>Le Prestataire reçoit une notification contenant à la fois le récapitulatif des informations relatives aux Prestations, et notamment à la Course et aux Prestations de Service Supplémentaires le cas échéant, ainsi que les informations nécessaires à la réalisation de sa mission (ci-après la « <strong>Notification</strong> »).</p>
              <p>Le Client reconnaît et accepte que le Prestataire valide les Prestations qu’il s’engage à réaliser.</p>
              <p><strong>Article 5 : Réalisation des Prestations</strong></p>
              <p><strong>Article 5.1 - Définition des Prestations proposées</strong></p>
              <p>La Plateforme met en place un service de Course qui consiste à effectuer un trajet au moyen du véhicule du Client, d’une adresse spécifique de départ (point A) à une autre adresse d’arrivée (point B), ou un aller-retour, et cela dans une période de temps donnée, dans le respect des conditions proposées par Oovoom Driver et des règles de sécurité routière en vigueur sur le(s) territoire(s) où la Course est effectuée.</p>
              <p>Plus précisément, le Client est amené à réserver les Prestations suivantes, qui seront réalisées par le Prestataire au nom et pour le compte de son Client :</p>
              <ul>
              <li>Une Course aller simple du lieu de départ (point A) au lieu d’arrivée (point B).</li>
              </ul>
              <p>Dans ce cas, le Client demande le déplacement du véhicule à une adresse souhaitée. Le Client peut opter pour ce type de Prestations dans le cadre du dépôt du véhicule auprès d’un garagiste par exemple.</p>
              <p>Le Client peut également solliciter un Prestataire pour conduire le véhicule du Client lorsque ce dernier ne souhaite pas conduire. Le Prestataire sera ainsi amené à transporter des personnes dans le véhicule et les déposer à une adresse indiquée, avec le véhicule.</p>
              <ul>
              <li>Une Course aller-retour du lieu de départ (point A) au lieu d’arrivée (point B) puis un retour au point de départ (point A).</li>
              </ul>
              <p>Le Client peut opter pour ce type de prestation dans le cadre du dépôt du véhicule auprès d’un prestataire par exemple. Auquel cas, un temps d’attente est estimé par le Client, au terme duquel le Prestataire s’engage à reconduire le véhicule au lieu de départ (point A).</p>
              <p>Durant les Courses précitées, le Client peut demander au Prestataire de réaliser des Prestations de Service Supplémentaires qui incluent le remplissage de carburant, le lavage du véhicule dans un automate ou auprès d’un prestataire spécialisé, l’entretien, la révision, la réparation, le contrôle technique et de remisage du véhicule, l’attente d’un tiers (tel que par exemple l’attente de dépanneuse) ou encore le gonflage des pneus.</p>
              <p>En tout état de cause, le Client reçoit la Notification contenant à la fois le récapitulatif des informations afférentes à la Prestation et notamment à la Course et aux Prestations de Services Supplémentaires le cas échéant, ainsi que les indications nécessaires à la réalisation par le Prestataire de sa mission :</p>
              <ul>
              <li>La nature de la Course : un aller simple, un aller-retour ou une course avec des Prestations de Service Supplémentaires ;</li>
              <li>L’adresse du lieu de départ (point A) et l’adresse du lieu d’arrivée (point B) ;</li>
              <li>Les caractéristiques du véhicule à conduire telles que la marque, le modèle et le numéro d’immatriculation, le type de boite de vitesse ;</li>
              <li>Les horaires qui doivent être scrupuleusement respectées pour (<strong>i</strong>) prendre le véhicule à conduire à l’adresse de départ (point A) et (<strong>ii</strong>) remettre le véhicule conduit à l’adresse d’arrivée (point B) ou de départ (point A) s’il s’agit d’un aller-retour (ci-après l’ « <strong>Adresse de Remise</strong> »). À noter que 5 minutes sont accordées au départ et ensuite à la remise pour attester de l’état du véhicule par un état des lieux tel que défini à l’Article 5.2.1 ;</li>
              <li>Dans le cas où le Prestataire doit remettre le véhicule à un tiers non détenteur, distinct du Client, l’identité de la personne physique ou personne morale, désignée par le Client comme étant en charge de récupérer le véhicule (ci-après le « <strong>Tiers Non Détenteur</strong> ») ;</li>
              <li>Les éventuels Prestations de Service Supplémentaires ou tout autre service complémentaire à la Course ; et,</li>
              <li>Le prix proposé de la Prestation.</li>
              </ul>
              <p><strong>Article 5.2 - Déroulement de la Prestation</strong></p>
              <p>Au moment de la remise du véhicule à conduire, le Client s’assure de l’identité du Prestataire et la validité de son permis de conduire et comparera l’ensemble de ces éléments avec ceux indiqués dans la Notification.</p>
              <p>Le Client reconnaît et accepte que le Prestataire réalise un état des lieux au départ et à l’arrivée de la Prestation via la Plateforme, comme décrit à l’Article 5.2.1. Les états des lieux effectués par le Prestataire au départ et ensuite à la remise du véhicule, sont obligatoirement soumis à la validation du Client ou de son représentant.</p>
              <p>De plus, le Client s’engage à respecter les obligations prévues à l’Article 8 des présentes CGU.</p>
              <p>Seuls les Clients ayant confirmé la réservation en ligne et finaliser la prestation sont autorisés à évaluer les Prestataires leur ayant fourni une Prestation. À cet effet, les Clients peuvent attribuer aux Prestataire une note positive ou négative et rédiger un commentaire au sujet de la Prestation sur la Plateforme.</p>
              <p><strong>Article 5.2.1 – État des lieux</strong></p>
              <p>Le véhicule à conduire fait l’objet d’un état des lieux version électronique directement dans l’onglet « État des lieux » sur la Plateforme Oovoom Driver Pro, à effectuer par le Prestataire via son smartphone à l’adresse de départ (point A) et à l’Adresse de Remise.</p>
              <p>L’état des lieux de départ et de remise se fait en présence du Client, ou du Tiers Non Détendeur.</p>
              <p>Cet état des lieux est obligatoire pour entamer et clôturer une Prestation. Le Client devra valider l’état des lieux, dûment complété par le Prestataire, au travers de la Plateforme.</p>
              <p>L’état des lieux consiste premièrement à prendre entre 4 et 16 photos du véhicule par côté par le biais de l’Application.</p>
              <p>De plus, l’état des lieux consiste à renseigner pour le Prestataire manuellement sur la Plateforme les informations sur le véhicule à la fois au départ et à la remise, avec l’aide du Client et/ou du Tiers Non Détenteur et notamment :</p>
              <ul>
              <li>Les éventuels impacts présents sur le véhicule ;</li>
              <li>Les éventuelles marques et traces sur le véhicule;</li>
              <li>La propreté extérieure et intérieure ;</li>
              <li>Le kilométrage ; et,</li>
              <li>Le niveau de carburant en pourcentage (%).</li>
              </ul>
              <p>En particulier, le Client reconnaît et accepte que le Prestataire doit toujours préciser dans l’état des lieux tout problème, défaillance ou irrégularité, constaté au départ, de remise et/ou durant le transfert du véhicule.</p>
              <p>La date et l’heure de départ et de remise seront automatiquement renseignées sur la Plateforme.</p>
              <p>À noter que les Utilisateurs reconnaissent et acceptent qu’en début et fin de Prestation, 5 minutes sont accordées à l’état des lieux de départ et de remise. Cela ne peut entraîner aucune pénalité pour le Prestataire.</p>
              <p>Une fois l’état des lieux complété par le Prestataire, il doit être validé par le Client sur la Plateforme.</p>
              <p>Les litiges relatifs à l’état des lieux doivent être renseignés directement dans la Plateforme. Oovoom Driver s’engage à intervenir en qualité d’intermédiaire. Le rôle d’Oovoom Driver est d&#39;assurer la conformité des photos aux informations renseignées et de valider ou contredire le litige remonté selon les preuves qui ont été validées par les Utilisateurs dans la Plateforme.</p>
              <p><strong>Article 5.2.2 - Remise des documents et clefs</strong></p>
              <p>Au terme de l’état des lieux d’arrivée, le Client remet au Prestataire les clefs, la carte grise ainsi que l’attestation d’assurance du véhicule.</p>
              <p>Au terme de la réalisation de la Prestation, le Client ou le Tiers Non Détenteur récupère le véhicule ainsi que le matériel y afférent remis au Prestataire.</p>
              <p><strong>Article 5.2.3 - Dépôt du véhicule et clôture de la Prestation</strong></p>
              <p>Lorsque le véhicule est déposé par le Prestataire à l’Adresse de Remise, un état des lieux est réalisé conformément aux dispositions de l’Article 5.2.1.</p>
              <p>Dans le cas où le Prestataire doit transmettre le véhicule à un Tiers Non Détenteur, par exemple à un garagiste, il doit s’assurer de l’identité de ce dernier grâce aux informations communiquées par le biais de la Plateforme et mentionnées dans la Notification.</p>
              <p>En cas d’absence du Client ou du Tiers Non Détenteur à l’Adresse de Remise, le Client reconnaît et accepte que le Prestataire devra attendre la personne indiquée dans la Notification comme étant chargée de réceptionner le véhicule, que ce soit le Client ou le Tiers Non Détenteur, jusqu’à clôture de la Prestation. Le temps d’attente est alors facturé au Client conformément aux dispositions de l’Article 6.</p>
              <p>Afin de clôturer une Prestation, le Client (ou Tiers Non Détenteur le cas échéant) doit :</p>
              <ul>
              <li>avoir récupéré le véhicule, le matériel y afférent ainsi que les clefs, la carte grise, et l’attestation d’assurance du véhicule ;</li>
              <li>valider les états des lieux électroniques dûment complétés à l’adresse de départ et à l’Adresse de Remise par le Prestataire sur la Plateforme.</li>
              </ul>
              <p><strong>Article 5.2.4 - Infraction</strong></p>
              <p>En cas d’infraction entrainant un retrait de point(s) sur le permis de conduire pendant la réalisation d’une Prestation, le Client ayant reçu le procès-verbal de contravention informe Oovoom Driver au travers de la Plateforme. Après vérification, Oovoom Driver informe alors le Client de l’identité du Prestataire ayant commis l’infraction.</p>
              <p><strong>Article 6 : Facturation et modalités de paiement</strong></p>
              <p><strong>Article 6.1 : Facturation du Service d’intermédiation</strong></p>
              <p>Lors de la validation de la Prestation, le Client doit procéder à une pré-autorisation de paiement, au moyen de sa carte bancaire, d’un montant égal au prix du Service d’intermédiation communiqué préalablement par Oovoom Driver. Ce prix, qui est exprimé en € TTC, est calculé en fonction des caractéristiques essentielles de la Prestation, notamment la distance séparant les adresses de départ et de remise du véhicule.</p>
              <p>Oovoom Driver se réserve le droit de facturer et encaisser tout ou partie de cette somme dans les situations suivantes :</p>
              <ul>
              <li>En totalité lorsque l’offre de Prestation aura été préalablement acceptée par un Prestataire de la Plateforme et que le véhicule aura été remis à ce Prestataire à l’adresse de départ pour être déplacé,</li>
              <li>À hauteur de cinquante pour cent (50%) lorsque le Client procèdera à l’annulation de la Prestations dans les conditions de l’Article 7.1 ci-dessous.</li>
              </ul>
              <p>Tout retard de paiement pour quelque raison que ce soit  entraînera de plein droit et sans mise en demeure l’application d’intérêts de retard calculés sur la base du taux d’intérêt en vigueur appliqué par la Banque centrale européenne à son opération de refinancement la plus récente majoré de 10 points de pourcentage et ce depuis la date d’échéance déterminée par le présent Article, jusqu’au jour de la réception du parfait paiement du prix dans son intégralité, ainsi que l’application d’une indemnité forfaitaire de 40€ par facture impayée pour frais de recouvrement et l’indemnisation de tous frais de recouvrement qui s’avèreraient supérieurs à cette somme.</p>
              <p><strong>Article 6.2 - Facturation de la Prestation</strong></p>
              <p>Lors de la réservation d’une Prestation dans les conditions de l’Article 4.1, le Client doit procéder au paiement de la prestation et à une pré-autorisation de paiement en cas de dépassement, au moyen de sa carte bancaire, correspondant au prix de la Prestation. Ce prix est un montant forfaitaire qui comprend la rémunération du Prestataire pour la Prestation et notamment les frais des Prestations de Service Supplémentaires, ainsi que les frais des Services d’intermédiation.</p>
              <p>La réservation par le Client et l’acceptation des conditions globales affichées par la Plateforme emporte acceptation du prix affiché pour la réalisation de la Prestation par les Utilisateurs. À partir de ce moment, les Utilisateurs ne pourront contester la somme.</p>
              <p>Dans le cas d’attente non déclarée par le Client ou le Tiers Non Détenteur, le Client devra payer un montant supplémentaire forfaitaire correspondant au temps d’attente sur une base de 20€HT/heure – au prorata du temps attendu.</p>
              <p>Le Client pourra consulter les preuves des paiements et réalisations des Prestations de Service Supplémentaires par le Prestataire au travers de la mise à disposition, dans la Plateforme de la preuve – ticket de caisse ou facture à télécharger directement dans la Plateforme.</p>
              <p>Au prix de la Prestation s’ajoutent les frais éventuels d’essence et de péage qui seront remboursés par le Client au Prestataire uniquement sur présentation de justificatifs. À ce titre, le Prestataire veille à conserver et à communiquer au Client toutes preuves et justificatifs de paiement de plein de carburant et/ou de péages le cas échéant. Les justificatifs afférents doivent indiquer clairement le montant exact, le libellé, la date et l’heure ainsi que le lieu de la réalisation. Une facture relative à ces dépenses sera alors émise et communiquée au Client. Ce dernier devra alors payer la facture immédiatement, lors de la restitution du véhicule.</p>
              <p>Dans le cas d’une anomalie qui interviendrait après cette date (une amende par exemple), le Client s’engage à informer sans délai Oovoom Driver et le Prestataire.</p>
              <p>Dans le cas où l’état des lieux d’arrivée n’est pas validé par le Client, un litige est ouvert. Oovoom Driver sera responsable de gérer le litige et aura le droit de fermer provisoirement la Prestation. La facture est alors bloquée, les sommes dues également, le temps de régler à l’amiable ce litige. Dans le cas où les Utilisateurs arrivent à une solution amiable, la Prestation est alors finalisée et payée comme initialement prévue.</p>
              <p>Dans le cas où les Utilisateurs n’arrivent pas à résoudre le litige, Oovoom Driver s’efforcera de trouver des solutions qui conviennent aux Utilisateurs. Dans le cas où le véhicule serait endommagé, il pourra être déposé dans un garage partenaire d’Oovoom en vue d’être réparé.</p>
              <p>Les franchises pourront alors être retenues sur les avoirs en cours ou futurs du Prestataire.</p>
              <p>En acceptant les présentes CGU, le Client autorise expressément Oovoom Driver à utiliser, à sa seule discrétion, l’ensemble des sommes dont le prélèvement a été préalablement autorisé dans le cadre de la réservation de la Prestation afin de recouvrer l’ensemble des sommes qui seraient dues à Oovoom Driver et au Prestataire en application des présentes CGU dans la limite de deux (2) mois après la remise du véhicule à l’Adresse de Remise.</p>
              <p><strong>Article 7 – Annulation d’une Prestation</strong></p>
              <p>En cas d’annulations répétées, Oovoom Driver se réserve le droit de procéder à la fermeture du Compte de l’Utilisateur, sans préavis ni indemnité et sans que sa responsabilité ne puisse être recherchée pour quelconque raison que ce soit.</p>
              <p><strong>Article 7.1 - Annulation par le Client</strong></p>
              <p>Pour procéder à l’annulation d’une Prestation que le Client a réservée, ce dernier doit se rendre sur son Compte via la Plateforme.</p>
              <p>Dans le cas où l’annulation survient dans les 3 minutes suivant la réservation, et avant la prise en charge, aucune indemnité ne sera due par le Client.</p>
              <p>Dans le cas où l’annulation survient au moins 3 minutes après la réservation, mais avant l’état des lieux de départ, le Client devra payer un coût forfaitaire de 2,50€HT plus TVA soit 3,00€TTC.</p>
              <p>Par ailleurs, le Client se réserve le droit d&#39;annuler une Prestation, et ne devra ainsi aucune indemnité au Prestataire, dans les cas suivants :</p>
              <ul>
              <li>Le Prestataire figure sur la liste de surveillance du Client (déclarée auprès de la CNIL) ;</li>
              <li>Le Client se trouve exceptionnellement dans l&#39;incapacité de délivrer le véhicule ;</li>
              <li>En cas d’anomalie dans la prise en charge de la Prestation telle que par exemple la non-conformité du Prestataire à sa photo sur la Plateforme ou à ses documents, l’état du Prestataire.</li>
              </ul>
              <p>Le Client devra alors le mentionner expressément dans la Plateforme.</p>
              <p>En cas d’annulation durant la Prestation, le Client devra payer la somme indiquée au départ de la Prestation.</p>
              <p>Avant de valider l’annulation, le Client est préalablement informé de l’application d’une pénalité de cinquante pourcent (50%) du prix d’intermédiation qui aurait dû lui être facturé par Oovoom Driver en application de l’Article 6.</p>
              <p>Le montant de cette pénalité correspond aux frais de gestion et de recouvrement rendus nécessaires par l’annulation.</p>
              <p>Dans tous les cas, le Client qui annule une Prestation devra payer une pénalité de cinquante pourcent (50%) du prix d’intermédiation qui aurait dû lui être facturé par Oovoom Driver en application de l’Article 6.</p>
              <p><strong>Article 7.2 - Annulation par le Prestataire</strong></p>
              <p>Pour procéder à l’annulation d’une Prestation réservée, le Prestataire doit se rendre sur son Compte via la Plateforme et effectuer les démarches nécessaires à cette fin.</p>
              <p>Il doit également prévenir Oovoom Driver dans les plus brefs délais, par courriel à l’adresse suivante : contact@oovoom.com ou par téléphone au 01 80 82 44 44.</p>
              <p>En cas de récidive d’annulation, le Prestataire peut se voir appliquer une pénalité de vingt-cinq (25) €HT, qui est doublée dans les situations suivantes :</p>
              <ul>
              <li>Si l’annulation est effectuée moins d’une (1) heure avant l’heure prévue de mise à disposition du véhicule au départ,</li>
              </ul>
              <p>Ces pénalités sont cumulables et leurs montants correspondent aux frais de gestion et de recouvrement rendus nécessaires par les éventuelles annulations.</p>
              <p>En cas d’annulation pour force majeure, telle que définie à l’Article 17, le Prestataire peut voir sa pénalité supprimée sous réserve de la présentation de justificatif qu’Oovoom Driver considère à sa seule discrétion, valable et valide.</p>
              <p><strong>Article 7.3 - Annulation par Oovoom Driver</strong></p>
              <p>Oovoom Driver se réserve le droit d’annuler une réservation par un Client dans le cas où, notamment, le Client se trouverait dans l’incapacité de délivrer le véhicule au point de départ dans la période horaire indiquée.</p>
              <p>Oovoom Driver doit en informer le Prestataire par tout moyen et dans les plus brefs délais en le contactant via les coordonnées fournies lors de son inscription sur la Plateforme.</p>
              <p><strong>Article 8 – Obligations des Clients et comportements interdits</strong></p>
              <p><strong>Article 8.1 – Obligations des Clients</strong></p>
              <p>Le Client s’engage pleinement à respecter les obligations contenues dans les présentes CGU, et notamment toutes les obligations énumérées dans le présent Article.</p>
              <ol>
              <li>Le Client s’engage à utiliser la Plateforme et les Services dans le respect des lois et des règlements en vigueur et sans porter atteinte aux droits et à la sécurité des tiers ou à l’ordre public.</li>
              <li>Le Client reconnaît et accepte les contraintes auxquelles il peut faire face lors de l’utilisation de la Plateforme et des Services. Il prend conscience des contraintes techniques dont il supporte pleinement la responsabilité, en particulier celles liées à la connexion Internet et de laquelle va dépendre la qualité des Services.</li>
              <li>En cas de problème, défaillance ou dysfonctionnement empêchant l’accès à la Plateforme, le Client s’engage à prévenir Oovoom Driver dans les plus brefs délais, par courriel à l’adresse suivante <a href="mailto:contact@oovoom.com">contact@oovoom.com</a> ou par téléphone au +331 80 82 44 44.</li>
              <li>Le Client s’engage à utiliser personnellement les Services sans concéder, transférer ou céder les droits et obligations qui en découlent à un tiers.</li>
              <li>Le Client garantit l’exactitude et la véracité des informations et des documents qu’il fournit lors de son inscription, ainsi que lors de la réservation de Prestations. Il s’engage notamment à s’assurer que les informations et documents le concernant et celles relatives à leur véhicule sont complets et à jour. À défaut, il s’engage à les rectifier sans délais.</li>
              <li>Le Client est seul responsable des informations communiquées aux Prestataires ainsi que des rapports et des échanges qui vont se créer entre eux dans le cadre des Prestations demandées. Il s’engage à entretenir des échanges respectueux et courtois.</li>
              <li>(vii)Le Client s’engage à respecter et à se présenter au lieu, au jour et à l’heure convenus pour la remise du véhicule. De plus, il s’engage à ne pas annuler sans raison valable, ou de manière répétitive, les rendez-vous fixés et confirmés avec les Prestataires.</li>
              <li>(viii)Le Client s’engage à coopérer activement avec Oovoom Driver et avec les Prestataires, notamment en leur fournissant toutes les informations nécessaires à la bonne exécution des Prestations demandées. En particulier, Oovoom Driver se réserve le droit de demander à tout moment au Client tout document ou information complémentaires, que le Client garantit fournir dans les plus brefs délais. À défaut, Oovoom Driver peut suspendre ou interdire au Client l’accès à la Plateforme sans que sa responsabilité ne puisse être retenue à cet égard.</li>
              <li>Le Client garantit que son véhicule à conduire est assuré à l’année, conformément aux obligations légales en vigueur ;</li>
              <li>Le Client garantit que son véhicule à conduire est (<strong>i</strong>) conforme à l’ensemble des dispositions légales et règlementaires applicables, et (<strong>ii</strong>) est en parfait état de fonctionnement et de sécurité ;</li>
              <li>Le Client garantir avoir notamment effectué l’entretien et les révisions préconisées par le constructeur automobile sur son véhicule à conduire. Il reconnaît et accepte que les véhicules en attente d’une contre-visite, même s’ils sont autorisés à circuler, ne peuvent pas faire l’objet d’une Prestation ;</li>
              <li>(xii)Le Client garantir qu’il dispose de tous les documents et équipements relatifs au véhicule, et notamment à la sécurité, qui sont obligatoires de par la loi et les règlements ;</li>
              <li>(xiii)Le Client s’engage à payer le prix des Prestations fournies par les Prestataires, tout paiement supplémentaire relatif au remplissant de carburant ou au péage dûment justifié par le Prestataire, ainsi que le prix du Service d’intermédiation.</li>
              <li>(xiv)Le Client garantit que son véhicule n’a pas une valeur supérieure à 80.000€ (valeur à dire d’expert) au moment de l’inscription de son véhicule dans la plateforme. Dans le cas contraire, la prestation rendue ne serait plus soumise à l’assurance de la société OovoomDriver mais à l’assurance du véhicule en question, via le prêt de volant. OovoomDriver ne saurait être responsable d’un quelconque litige et l’assurance d’OovoomDriver ne pourra être sollicitée dans ce cas précis.</li>
              <li>(xv)Il est de la responsabilité du client de s’assurer que son véhicule remplit les conditions d’assurance d’OovoomDriver, que ce soit sur la valeur à date du véhicule à prendre en charge, sur le fait que le Driver ne peut en aucun cas gérer un transport de marchandise.</li>
              </ol>
              <p><strong>Article 8.2 – Comportements interdits</strong></p>
              <p>Toute violation des CGU ainsi que l’adoption d’un comportement prohibée par le présent Article, sont sanctionnées par l’Article 10.</p>
              <ol>
              <li>Il est strictement interdit d’employer les Services à des fins qui diffèrent de leur objet.</li>
              <li>Il est strictement interdit de ralentir, nuire, interrompre ou empêcher la bonne exécution des Services. Ainsi, toute intrusion et tentative de piratage des systèmes d’Oovoom Driver sont strictement prohibés.</li>
              <li>Il est strictement interdit d’utiliser la Plateforme, ou de publier ou diffuser un contenu (<strong>i</strong>) contraire aux lois et règlements en vigueur, à l’ordre public et aux bonnes mœurs, (<strong>ii</strong>) portant atteinte à l’intérêt ou sécurité des tiers, (<strong>iii</strong>) frauduleux, mensonger ou trompeur, ou (<strong>iv</strong>) racistes et diffamatoires.</li>
              </ol>
              <p><strong>Article 9 – Garantie des Clients</strong></p>
              <p>Les Clients supportent la charge de toutes les plaintes, réclamations, actions et/ou revendications qu’Oovoom Driver pourrait subir du fait de la violation, par un Client, de l’une quelconque de ses obligations ou garanties aux termes des présentes CGU.</p>
              <p>Ils s’engagent à indemniser Oovoom Driver intégralement de tout préjudice, réclamation, perte et/ou dommage qu’elle pourrait subir et auquel cas, à payer tous les coûts, dépenses, frais juridiques et/ou charges qu’elle aurait à supporter.</p>
              <p><strong>Article 10 – Conséquences résultants de manquements</strong></p>
              <p>En cas de manquement dans l’exécution de l’une des dispositions présentes dans les CGU ou encore de violation des lois et règlements en vigueur, Oovoom Driver envoie une mise en demeure adressée par lettre recommandée avec accusé de réception au Client, exprimant son intention de se prévaloir des dispositions du présent Article. Une fois le délai de quinze jours, écoulé après réception par le Client de cette mise en demeure restée sans effet, Oovoom Driver se réserve le droit d’adopter toute mesure qu’elle juge appropriée.</p>
              <p>Ainsi, Oovoom Driver aura la libre discrétion de suspendre, restreindre ou supprimer l’accès à la Plateforme, ou tout contenu publié sur la Plateforme, sans qu’aucune indemnité ne puisse être réclamée, et sans qu’aucune formalité, procédure ou intervention judiciaire ne soit requise, et ce à tout moment.</p>
              <p>Par ailleurs, Oovoom Driver se réserve le droit d’avertir l’autorité concernée et d’intenter une action en justice si cela lui semble nécessaire.</p>
              <p><strong>Article 11 - Durée des Services et désinscription</strong></p>
              <p>Les Clients bénéficient des Services pour une durée indéterminée.</p>
              <p>Ils peuvent se désinscrire des Services en adressant une demande à cette fin par courrier recommandé avec accusé de réception, à l’adresse figurant dans le Préambule en respectant un préavis de quinze jours à compter de la réception par Oovoom Driver dudit courrier.</p>
              <p><strong>Article 12 : Assurance</strong></p>
              <p>Oovoom Driver souscrit une assurance dans le but d’assurer les véhicules objets des Prestations réalisées par le Prestataire. Le véhicule conduit est automatiquement assuré par la société Oovoom Driver, le temps de la Prestation.</p>
              <p>L’assurance démarre au moment de la validation de l’état des lieux de départ par le Client et prend fin par la validation de l’état des lieux de remise par le Client ou son représentant.</p>
              <p>En cas de sinistre, le Client sera avisé immédiatement par le Prestataire via la Plateforme.</p>
              <p>Sont assurés les véhicules enregistrés par leur propriétaire sur l’application OOVOOMDriver</p>
              <p>Sont considérés comme des véhicules assurés, tout véhicule enregistré au préalable sur l’application OOVOOMDriver, approuvé par le souscripteur, et uniquement pendant la durée de l’utilisation du véhicule assuré par la personne ayant la garde ou la conduite de ce véhicule pour le compte d’un propriétaire.</p>
              <p>En l’exception du bénéfice de la garantie Responsabilité civile, la valeur économique du Véhicule assuré ne doit pas excéder 80 000€.</p>
              <p>Le Véhicule assuré ne sert en aucun cas - même occasionnellement – au transport, à titre onéreux, de marchandises appartenant à des Tiers ou de voyageurs.</p>
              <p>Il peut être utilisé pour :</p>
              <ul>
              <li>les déplacements privés, y compris ceux liés à l’exercice bénévole d’un mandat électif ou d’une activité associative ou syndicale,</li>
              <li>tous les déplacements professionnels.</li>
              </ul>
              <p>Usage professionnel</p>
              <p>Le Véhicule assuré ne sert en aucun cas - même occasionnellement – au transport, à titre onéreux, de marchandises appartenant à des Tiers ou de voyageurs.</p>
              <p>Il peut être utilisé uniquement pour :</p>
              <ul>
              <li>les déplacements professionnels à l’exclusion des visites régulières de clientèle, d’agences, de dépôts de succursales ou de chantiers.</li>
              <li>les déplacements privés, y compris ceux liés à l’exercice bénévole d’un mandat électif ou d’une activité associative ou syndicale,</li>
              </ul>
              <p>Usage privé et trajets travail</p>
              <p>Le Véhicule ne sert en aucun cas - même occasionnellement - au transport, à titre onéreux, de marchandises appartenant à des tiers ou de voyageurs.</p>
              <p>Il peut être utilisé pour :</p>
              <ul>
              <li>les déplacements privés, y compris ceux liés à l’exercice bénévole d’un mandat électif ou d’une activité associative ou syndicale ainsi que pour la recherche d’un emploi,</li>
              <li>le seul trajet aller et retour, du domicile au lieu de travail (ou lieu de départ d’un transport en commun).</li>
              </ul>
              <p>Usage privé</p>
              <p>Le Véhicule est utilisé pour des déplacements privés et ne sert en aucun cas - même occasionnellement – à des besoins professionnels (exemples : déplacements pour effectuer même partiellement, le trajet jusqu’au lieu de travail et en revenir, pour visiter la clientèle, pour aller à un rendez-vous d’affaires et, en général, d’un lieu de travail à un autre), ni au transport, à titre onéreux, de marchandises appartenant à des tiers, ou de voyageurs.</p>
              <p><strong>Article 13 – Absence de Responsabilité</strong></p>
              <p>Agissant comme plateforme de mise en relation entre les Clients et les Prestataires, Oovoom Driver met à leur disposition les outils techniques leur permettant d’entrer en contact afin recevoir des Prestations pour les premiers et de fixer un rendez-vous et fournir pour les seconds lesdites Prestations.</p>
              <ul>
              <li>Relation avec les Prestataires</li>
              </ul>
              <p>Oovoom Driver agit en son nom et n’intervient aucunement au nom ou pour le compte de l’un quelconque des Utilisateurs. Ces derniers contractent directement et strictement entre eux, sans l’intervention ou la participation d’Oovoom Driver. La responsabilité d’Oovoom Driver ne saurait en aucun cas être engagée, et Oovoom Driver ne sera aucunement tenu de supporter une quelconque obligation au titre des potentiels litiges relatifs, d’une quelconque manière, à la relation entre le Client et le Prestataire.</p>
              <p>En effet, Oovoom Driver ne pourra en aucun cas être tenu responsable notamment (<strong>i</strong>) de la mauvaise exécution ou non-exécution de la Prestation par le Prestataire, (<strong>ii</strong>) des informations fournies par le Prestataire et affichées sur la Plateforme, et cela quand bien même Oovoom Driver aurait procédé à des vérifications de quelque nature que ce soit, (<strong>iii</strong>) des notes et des commentaires publiés par les Clients, sur lesquelles elle n’effectue aucun contrôle, (<strong>iv</strong>) de la modification ou de l’annulation par le Prestataire du rendez-vous fixé et des conséquences que cela peut entraîner, ou (<strong>v</strong>) du défaut de paiement par le Client.</p>
              <p>Oovoom Driver ne pourra en aucun cas être tenu responsable de tout dommage direct, indirect, accessoire, spécial, punitif ou consécutif, quel qu’il soit, qui en résulte, mais également en cas d’indisponibilité de tout ou partie de la Plateforme.</p>
              <p>Le Client et le Prestataire sont les seuls responsables dans les situations mentionnées ci-dessus, ainsi que dans toutes les situations qui les lient entre eux. Oovoom Driver ne sera en aucun cas responsable de tout acte, déclaration, omission, ou de toute violation ou négligence de l’un d’eux, ou de tout dommage en découlant.</p>
              <p>En conséquence, tout litige doit être résolu directement entre le Prestataire et le Client, Oovoom Driver pouvant, le cas échéant, servir d’intermédiaire lorsque cela est prévu de manière expresse et non équivoque dans les présentes CGU.</p>
              <ul>
              <li>Contenu de la Plateforme et fonctionnement du réseau internet</li>
              </ul>
              <p>Le Client reconnaît et accepte d’utiliser la Plateforme sous sa propre responsabilité et renonce à engager la responsabilité d’Oovoom Driver à cet effet.</p>
              <p>Bien qu’Oovoom Driver prenne des mesures raisonnables pour assurer l’exactitude et la fiabilité des données et autres informations affichées sur la Plateforme, notamment celles relatives aux Prestataires, ou aux Clients, Oovoom Driver ne garantit rien à cet égard.</p>
              <p>En aucun cas Oovoom Driver, ses dirigeants, directeurs, employés ou agents, ne seront responsables de tout dommage direct, indirect, accessoire, spécial, punitif ou consécutif, quel qu’il soit, résultant (<strong>i</strong>) d’erreurs, de fautes ou d’inexactitudes dans le contenu de la Plateforme, (<strong>ii</strong>) de blessures corporelles ou de dommages matériels, de quelque nature que ce soit, résultant de l’accès et de l’utilisation de la Plateforme, (<strong>iii</strong>) de toute utilisation non autorisée des serveurs sécurisés d’Oovoom Driver et d’accès à toutes les informations personnelles et/ou financières qui y sont stockées, (<strong>iv</strong>) de toute interruption des serveurs d’Oovoom Driver, (<strong>v</strong>) de tout bug, virus ou autre, qui pourrait être transmis à ou via le service d’Oovoom Driver par un tiers, ou (<strong>vi</strong>) de tout dommage de quelque nature que ce soit résultant d’un fait quelconque, lié à l’utilisation de la Plateforme.</p>
              <p>Oovoom Driver décline toute responsabilité en cas d’interruption temporaire ou définitive de la Plateforme, ou des difficultés ou incapacités à y accéder, notamment pour des raisons liées à des perturbations du réseau internet. De plus, la responsabilité d’Oovoom Driver ne peut être engagée en cas de perte des informations liées au compte du Client ou du Prestataire.</p>
              <p>La Plateforme peut inclure des liens hypertextes qui redirigent vers des sites internet exploités par des tiers. Oovoom Driver n’est pas responsable du contenu de ces sites web tiers liés et n’est donc pas responsable des informations qui y sont contenues qui peuvent être liés à la Plateforme au moyen d’un hyperlien.</p>
              <p><strong>Article 14 - Convention sur la preuve</strong></p>
              <p>Les Clients reconnaissent et acceptent expressément que :</p>
              <ul>
              <li>Les Identifiants permettent d’identifier valablement l’auteur d’un document et d’établir l’authenticité dudit document,</li>
              <li>Les informations saisies par les Clients les engagent dès leur validation,</li>
              <li>Tout document électronique comportant les Identifiants d’un Clients a la même valeur juridique qu’un écrit signé par lui,</li>
              <li>Les opérations effectuées ainsi que les mentions inscrites sur la Plateforme font foi de la réalité des opérations accomplies dans le cadre des Services proposés et constituent le principal mode de preuve admis.</li>
              </ul>
              <p><strong>Article 15 – Confidentialité et protection des données personnelles</strong></p>
              <p>Conformément aux lois et règlements en vigueur relatives à la protection des données personnelles, Oovoom Driver recueille certaines informations ou données personnelles afin d’assurer le bon fonctionnement de la Plateforme et de la mise en relation. Oovoom Driver garantie notamment le respect du Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données ainsi que la loi n˚78-17 « Informatique et Libertés » du 6 janvier 1978 telle que modifiée par la loi du 20 juin 2018 (ci-après dénommées collectivement les « <strong>Lois et Règlements</strong> »).</p>
              <ul>
              <li>Données collectées</li>
              </ul>
              <p>Lors de l’inscription, les Utilisateurs sont informés des données indispensables, signalées par un astérisque, afin qu’Oovoom Driver puisse traiter et valider leur inscription sur la Plateforme. En cas de refus ou de défaut de communication de l’une quelconque de ces données, l’inscription ne sera pas validée et la Plateforme ne pourra donc pas être utilisée.</p>
              <p>Oovoom Driver se réserve le droit de collecter les données personnelles des Utilisateurs qui sont entre autres les suivantes : le nom, le prénom, l’adresse postale, l’adresse courriel, la date de naissance, le numéro de téléphone, les coordonnées bancaires, et une photocopie de la pièce d’identité.</p>
              <p>En particulier pour le Client, les données suivantes sont également collectées : le numéro de la plaque d’immatriculation du véhicule, les informations et caractéristiques du véhicule tels que la catégorie, la marque, le modèle, le type de boite de vitesse.</p>
              <ul>
              <li>Utilisation des données collectées</li>
              </ul>
              <p>L’utilisation et le traitement des données collectées par Oovoom Driver est indispensable afin de permettre aux Utilisateurs d’accéder à la Plateforme et de l’utiliser ainsi que de réserver et d’exécuter des Prestations. De plus, ces données sont nécessaires à l’exécution des CGU, au développement de la Plateforme et à l’amélioration de son fonctionnement.</p>
              <p>Les Clients par la présente autorisent expressément Oovoom Driver à fournir toutes les informations requises les concernant en sa possession aux Clients.</p>
              <p>Le traitement automatisé de données à caractère personnel consiste à effectuer un profilage et ainsi à utiliser ces données pour évaluer certains aspects de la personne concernée, et analyser ou prédire ses intérêts, son comportement et d&#39;autres attributs. Cela est nécessaire afin d’assurer un niveau de qualité et de sécurité des Prestations effectués par les Prestataires au profit des Clients.</p>
              <p>Oovoom Driver s’engage à veiller à la sécurisation des données personnelles collectées et à en préserver la confidentialité.</p>
              <ul>
              <li>Conservation des données collectées</li>
              </ul>
              <p>Les données personnelles sont conservées afin de répondre aux besoins du Client et pour que Oovoom Driver respecte ses obligations légales.</p>
              <p>Les données personnelles sont conservées par Oovoom Driver pour la durée nécessaire à la réalisation des Prestations et/ou jusqu’à la clôture du Compte du Client, et cela conformément aux obligations légales et règlementaires.</p>
              <p>Seules certaines données peuvent être conservées durant les 3 ans qui suivent la durée nécessaire à la réalisation des Prestations et/ou la clôture du Compte par le Client. Les données personnelles liées à la géolocalisation sont conservées pendant 1 an afin de garder la preuve de la/des Prestation(s) réalisé(s) par le Prestataire.</p>
              <p>Par ailleurs, Oovoom Driver peut être amené à conserver certaines données en vue de satisfaire aux obligations légales et règlementaires, notamment afin de faire valoir ses droits et/ou à des fins statistiques ou historiques. En effet, dans le cadre de toute demande effectuée par les autorités administratives ou judiciaires, Oovoom Driver peut être amené à communiquer des données personnelles.</p>
              <p>À l’expiration des périodes mentionnées ci-dessus, les données personnelles collectées seront supprimées ou rendues anonymes par Oovoom Driver.</p>
              <p>Les données personnelles sont traitées par Oovoom Driver ainsi que par les prestataires techniques qui soutiennent l’activité (hébergeur du site, moteurs de recherches ...) agissant en qualité de sous-traitant d’Oovoom Driver</p>
              <ul>
              <li>Collecte et utilisation des informations non personnelles</li>
              </ul>
              <p>À l’instar de nombreuses organisations commerciales, Oovoom Driver surveille l’utilisation de cette Plateforme en recueillant des informations globales. Oovoom Driver peut recueillir automatiquement des informations non personnelles concernant les Client, telles que le type de navigateur internet qu’ils utilisent, les pages internet visitées ou le site web qui a dirigé vers la Plateforme. Ces informations ne permettent pas d’identifier les Utilisateurs et ne sont utilisées que pour aider Oovoom Driver à fournir un service efficace sur cette Plateforme</p>
              <ul>
              <li>Engagements et droits des Clients</li>
              </ul>
              <p>Les Clients s’engagent :</p>
              <ol>
              <li>à respecter les dispositions des lois et règlements en vigueur et notamment les Lois et Règlements relatives à la protection des données personnelles auxquels ils ont accès dans le cadre des Prestations, et leurs conséquences éventuelles et plus généralement de l’utilisation de la Plateforme ;</li>
              <li>à ne pas exploiter les données personnelles relatives à leurs Prestataires ;</li>
              <li>à conserver lesdites données personnelles uniquement pour la durée des Prestations et leurs conséquences éventuelles et procéder par la suite à leur destruction sans délai ;</li>
              <li>à recueillir le consentement des Prestataires pour toute collecte et traitement de leurs données personnelles, tout en les informant des conditions de cette collecte ainsi que des droits dont ils disposent relativement à ces données ;</li>
              <li>à garantir la protection, la préservation de la sécurité, et la conservation de la confidentialité des données personnelles des Prestataires ;</li>
              <li>à communiquer des données valides et à jour de leur situation ;</li>
              <li>(vii)à ne pas communiquer les données personnelles de toute personne tierce avec laquelle ils seraient entrés en contact dans le cadre de la réalisation des Prestations ; et,</li>
              <li>(viii)à consentir à la géolocalisation des véhicules dans le cadre de la réalisation des Prestations.</li>
              </ol>
              <p>Les Clients bénéficient d’un droit d’accès, de rectification, de suppression, de portabilité de leurs données personnelles, d’opposition au traitement de leurs données collectées et traitées par Oovoom Driver en adressant une demande accompagnée d’une copie de sa carte d’identité à l’adresse courriel suivante : contact@oovoom.com ou par format papier à l’adresse d’Oovoom Driver indiquée au Préambule.</p>
              <p>Si malgré les efforts employés par Oovoom pour préserver la confidentialité des données personnelles des Utilisateurs, ces derniers estiment que leurs droits ne sont pas respectés, Oovoom Driver les rappelle qu’ils  disposent de la possibilité de saisir l’autorité nationale de contrôle en matière de traitement de données (en France : <a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>).</p>
              <ul>
              <li>Données bancaires</li>
              </ul>
              <p>Les informations et données bancaires fournies par les Clients dans le cadre de la réalisation des Prestations sont traitées par le prestataire responsable de la gestion des paiements sur la Plateforme.</p>
              <ul>
              <li>Avertissement</li>
              </ul>
              <p>Les Clients reconnaissent et acceptent expressément que, dès lors qu’ils fournissent des informations personnelles sur internet, ces informations peuvent être collectées et utilisées par des tiers.</p>
              <ul>
              <li>Utilisation des Cookies</li>
              </ul>
              <p>Oovoom Driver reçoit et enregistre des informations du navigateur ou du smartphone des Utilisateurs lorsqu’ils utilisent la Plateforme et utilise des « Cookies » pour collecter ces informations. Les « Cookies » sont de petits fichiers de données amovibles, ne contenant aucune information personnelle, qui sont automatiquement stockés par le navigateur internet ou smartphone des Utilisateurs. Ils permettent aux Utilisateurs de transporter des informations sur la Plateforme d’Oovom Driver sans avoir à les saisir à nouveau. Ils contiennent des informations sur l’utilisation de la Plateforme sans permette à Oovoom Driver d’en identifier l’utilisateur. Ils sont utilisés à des fins de publicité et de mesure d’audience (nombre de visites, de pages vues, …)</p>
              <p>En cas de refus des « Cookies », qu’Oovoom Driver ne garantit pas le fonctionnement optimal de la Plateforme.</p>
              <p>Le Client reconnaît et accepte qu’Oovoom Driver se réserve la possibilité d’implanter un « Cookie » dans son ordinateur ou son smartphone afin d’enregistrer toute information relative à la navigation sur la Plateforme.</p>
              <p>Si les Utilisateurs ne souhaitent pas qu’un « Cookie » soit implanté dans leur ordinateur ou leur smartphone, il leur est possible de paramétrer leur navigateur ou smartphone en suivant la procédure décrite dans le lien suivant « <a href="https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur">https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur</a> »</p>
              <p><strong>Article 16 – Droits de propriété intellectuelle</strong></p>
              <p>Le design, et le contenu de la Plateforme, ainsi que les textes, bases de données, mise en page, scripts, liens hypertextes, graphiques, visuels, fonctions interactives, logiciels, dessins, images, logos, vidéos, éléments sonores, codes, et les marques de commerce, marques de service, dénominations sociales, noms commerciaux, enseignes et autres droits de la propriété intellectuelle et industrielle (ci-après les « <strong>Droits de propriété intellectuelle</strong> ») qui y sont contenus sont et demeurent la propriété exclusive, pleine et entière d’Oovoom Driver et sont protégés par les Droits de propriété intellectuelle et les droits des producteurs des bases de données en vigueur.</p>
              <p>L’utilisation de la Plateforme n’implique pas l’autorisation ou la cession d’un droit d’exploitation d’un quelconque contenu figurant sur la Plateforme. Toute utilisation, reproduction, diffusion, adaptation, traduction ou représentation totale ou partielle de toute ou partie de cette Plateforme, par quelque procédé que ce soit, et sur quelconque support est strictement prohibée et constitue une contrefaçon sanctionnée par le Code de la propriété intellectuelle, sauf autorisation expresse, écrite et préalable d’Oovoom Driver en sa qualité d’auteur et de titulaire de l’intégralité des Droits de propriété intellectuelle.</p>
              <p>Oovoom Driver se réserve le droit d’entamer des poursuites judiciaires et de réclamer une indemnisation de l’entier préjudice contre toute personne qui violerait cette interdiction.</p>
              <p>Seule l’utilisation pour un usage privé et à des fins non commerciales est autorisée, sous réserve du respect des Droits de propriété intellectuelle.</p>
              <p><strong>Article 17 - Force majeure</strong></p>
              <p>De façon expresse, sont considérés comme cas de force majeure ou cas fortuits, outre ceux habituellement retenus par la jurisprudence française : les grèves totales ou partielles, internes ou externes à l’entreprise, lock-out, intempérie, épidémie, blocage de moyen de transport ou d’approvisionnement pour quelque raison que ce soit, tremblement de terre, incendie, tempête, inondation, dégâts des eaux, restrictions gouvernementales ou légales, modification légale ou réglementaire des formes de commercialisation, panne d’ordinateurs, blocage des moyens de télécommunications, y compris les réseaux, et tout autre évènement extérieur, imprévisible et irrésistible, indépendant de la volonté expresse des Parties et empêchant l’exécution normale des présentes CGU.</p>
              <p><strong>Article 18 - Autres clauses</strong></p>
              <p>Dans l’hypothèse où l’une des dispositions contenues dans les présentes CGU serait déclarée intégralement ou partiellement nulle en vertu d’une loi, règlement ou décision judiciaire, cette annulation ou invalidité n’affectera pas les autres dispositions des CGU, dont les termes resteront pleinement en vigueur et conserveront leur plein et entier effet. Oovoom Driver pourra dans ce cas procéder à la rédaction d’une nouvelle disposition tout en respectant le droit positif applicable aux CGU.</p>
              <p><strong>Article 19 - Traitement des réclamations - Médiation</strong></p>
              <p>Le Client est informé de l’existence d’un service « Réclamations » qu’il peut joindre en cas de problème aux coordonnées suivantes : +33 1 80 82 44 44</p>
              <p>Le Client qui revêt la qualité de consommateur dispose du droit de saisir gratuitement un médiateur de la consommation en cas de contestation, aux fins d’une résolution amiable d’un litige découlant de l’interprétation ou de l’exécution des présentes Conditions, selon les conditions énoncées aux Articles L.611-1 et suivants et R.612-1 et suivants du Code de la consommation. Ils peuvent ainsi entrer en contact avec le médiateur de la consommation suivant : <a href="https://www.economie.gouv.fr/mediation-conso/liste-des-mediateurs-references">https://www.economie.gouv.fr/mediation-conso/liste-des-mediateurs-references</a><em>.</em></p>
              <p>Le Client pourra également résoudre le litige par le biais de la plateforme de Résolution des litiges en ligne mise en place par la Commission européenne, accessible à l’adresse suivante : ﷟ <a href="https://ec.europa.eu/consumers/odr.">https://ec.europa.eu/consumers/odr.</a></p>
              <p><strong>Article 20 - Droit applicable et juridictions compétentes</strong></p>
              <p>Les présentes CGU sont soumises à la loi française.</p>
              <p>Tout différend ou litige qui surviendrait dans le cadre des présentes CGU et notamment dans le cadre de leur interprétation ou de leur exécution et qui ne serait pas résolu à l’amiable dans un délai de trente jours suivant sa survenance, sera soumis aux tribunaux compétents du ressort des Tribunaux de Paris, nonobstant une pluralité de défendeurs ou un appel en garantie le cas échéant.</p>              
              `,
            }}
          />
        </StaticWrapper>
      </Container>
    </Box>
  );
};

TermsConditions.propTypes = {
  boxBackground: PropTypes.object,
  text: PropTypes.object,
};

TermsConditions.defaultProps = {
  boxBackground: {
    background: '#FFFFFF',
    padding: '10%',
  },
  title: {
    fontSize: ['24px', '26px', '28px', '30px', '32px'],
    fontWeight: '800',
    color: '#ff9d00',
    lineHeight: '1.34',
    mb: ['10%', '10%', '7.5%', '5%', '5%'],
    textAlign: 'center',
    letterSpacing: '-0.025em',
  },
};

export default TermsConditions;
