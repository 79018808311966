import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Box from 'common/components/Box';

import SectionWrapper from './separator.style';

const Separator = ({
  color,
  angle,
  height,
  width,
  diagonal,
  marginTop,
  ...props
}) => {
  const allClasses = ['diagonal'];

  return (
    <SectionWrapper {...props}>
      <Box
        {...diagonal}
        className={allClasses.join(' ')}
        style={{
          background: `${color}`,
          transform: `rotate(${angle}deg)`,
          minHeight: `${height}`,
          maxWidth: `${width}`,
          marginTop: `${marginTop}`,
        }}
      >
        <Fragment />
      </Box>
    </SectionWrapper>
  );
};

Separator.propTypes = {
  diagonal: PropTypes.object,
};

Separator.defaultProps = {
  diagonal: {
    width: '125%',
  },
};

export default Separator;
