import React, { useEffect, useState } from 'react';
// import LoadScript from 'load-script';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PlacesAutocomplete from 'react-places-autocomplete';

import './index.css';

const GooglePlacesAddressInput = ({
  id,
  name,
  placeholder,
  onSelectedValue,
  onCleanInput,
  isFullAddress,
  onUpdateCityCountry,
  inputClassName,
  initialData,
  onSetValueMapClick,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    _isEmpty(initialData) === true ? {} : initialData
  );

  useEffect(() => {
    if (initialData !== selectedValue) {
      setSelectedValue(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (onSetValueMapClick && _isEmpty(onSetValueMapClick) === false) {
      setSelectedValue(onSetValueMapClick);
    }
  }, [onSetValueMapClick]);

  const handleSelect = (value) => {
    setSelectedValue({ address: value });
    return onSelectedValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue({ address: value });
    return onSelectedValue(value);
  };
  return (
    <PlacesAutocomplete
      value={_get(selectedValue, 'address', '')}
      onChange={handleChange}
      onSelect={handleSelect}
      googleCallbackName="onFullLoadMap"
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <input
            id={id ?? ''}
            name={name ?? ''}
            autoComplete="off"
            {...getInputProps({
              placeholder,
            })}
            className={`${inputClassName || ''}`}
          />
          {suggestions.length > 0 && (
            <div className="input-suggestions">
              {suggestions.map((suggestion, index) => {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    className="suggestions__item"
                    key={index}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAddressInput;
