import styled from 'styled-components';
import { variant, alignItems, boxShadow } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { buttonStyle, colorStyle, sizeStyle } from '../customVariant';
import { base } from '../base';

const ButtonStyle = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet('colors.white', '#FFFFFF')};
  background-color: ${themeGet('colors.primary', '#FF9D00')};
  min-height: ${themeGet('heights.3', '48')}px;
  min-width: ${themeGet('widths.3', '48')}px;
  border-radius: ${themeGet('radius.0', '3')}px;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.4', '16')}px;
  font-weight: ${themeGet('fontWeights.4', '500')};
  text-decoration: none;
  padding-top: ${themeGet('space.2', '8')}px;
  padding-bottom: ${themeGet('space.2', '8')}px;
  padding-left: ${themeGet('space.4', '15')}px;
  padding-right: ${themeGet('space.4', '15')}px;
  border: 0;
  transition: all 0.3s ease;
  span.btn-text {
    padding-left: ${themeGet('space.2', '5')}px;
    padding-right: ${themeGet('space.2', '5')}px;
  }
  span.btn-icon {
    display: flex;
    > div {
      display: flex !important;
    }
  }
  &:focus {
    outline: none;
  }
  &.is-loading {
    .btn-text {
      padding-left: ${themeGet('space.2', '8')}px;
      padding-right: ${themeGet('space.2', '8')}px;
    }
  }

  ${alignItems}
  ${boxShadow}
  ${buttonStyle}
  ${colorStyle}
  ${sizeStyle}
  ${base}
`;

ButtonStyle.propTypes = {
  ...alignItems.propTypes,
  ...boxShadow.propTypes,
  ...variant.propTypes,
};

ButtonStyle.displayName = 'ButtonStyle';

export default ButtonStyle;
