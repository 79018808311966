import styled from 'styled-components';

const StaticWrapper = styled.div`
  background: #ffffff;
  position: relative;
  align-items: center;

  @media (max-width: 1220px) {
    padding: 70px 40px 35px 40px;
  }

  @media (max-width: 575px) {
    padding: 70px 20px 35px 20px;
  }

  @media (max-width: 990px) {
    width: 100%
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    text-transform: uppercase;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
    letter-spacing: 0.25px;
  }

  .wrapper {
    text-align: center;
    justify-content: center;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .static-text {
    overflow-wrap: break-word;
  }
`;

export default StaticWrapper;
