import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ImageWrapper = styled('img')(base, themed('Image'));

const Image = ({ src, alt, ...props }) => {
  const Data = useStaticQuery(graphql`
    query GetAllAssets {
      images: allFile(
        filter: { extension: { regex: "/jpeg|jpg|png|gif|svg/" } }
      ) {
        edges {
          node {
            extension
            relativePath
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  const imageData = Data?.images?.edges?.find(
    (image) => image?.node?.relativePath === src
  );
  const image = imageData?.node;
  const imageExtension = image?.extension;
  const imagePublicURL = image?.publicURL;

  // Using regular Image component with SVGs
  if (imageExtension === 'svg') {
    return <ImageWrapper src={imagePublicURL} alt={alt} {...props} />;
  }

  return (
    <GatsbyImage
      // getImage helper: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#getimage
      image={getImage(image)}
      alt={alt}
      {...props}
    />
  );
};

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
  m: 0,
};
