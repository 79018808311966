import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { useSiteMetadata } from '/src/common/hooks/use-site-metadata';
import { useMetaLogo } from '/src/common/hooks/use-meta-logo';

function Seo({ customTitle, customDescription, lang, pageUrl }) {
  const siteTitle = useSiteMetadata('title');
  const siteDescription = useSiteMetadata('description');
  const siteAuthor = useSiteMetadata('author');
  const sitePlainUrl = useSiteMetadata('siteUrl');
  const logoSrc = useMetaLogo();
  const metaTitle = customTitle || siteTitle;
  const metaDescription = customDescription || siteDescription;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={
        metaTitle === siteTitle ? siteTitle : `${metaTitle} - ${siteTitle}`
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: sitePlainUrl + (pageUrl || '/'),
        },
        {
          property: 'og:image',
          content: sitePlainUrl + logoSrc,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image',
          content: sitePlainUrl + logoSrc,
        },
        {
          name: 'twitter:creator',
          content: siteAuthor,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]}
    />
  );
}

Seo.defaultProps = {
  lang: 'fr',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
};

export default Seo;
