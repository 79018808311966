import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { DrawerContext } from 'common/contexts/DrawerContext';
import { StoreLink } from 'common/tools';

import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import Logo from 'common/components/Logo';
import Box from 'common/components/Box';
import HamburgerMenu from 'common/components/HamburgerMenu';
import Container from 'common/components/Container';
import ScrollMenu from 'common/components/ScrollMenu';
import Link from 'common/components/Link';
import AppStoreIcon from 'common/assets/image/app-store-icon.svg';
import GoogleIcon from 'common/assets/image/google-play-icon.svg';

const DriverLogoMain = 'image/driver-logo-main.svg';
const DriverLogo = 'image/driver-logo.svg';
const DriverProLogo = 'image/driver-pro-logo.svg';

const Navbar = ({
  navbarStyle,
  logoStyle,
  button,
  row,
  menuWrapper,
  main,
  driverPro,
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  const DriverLogoFinal = main
    ? DriverLogoMain
    : driverPro
    ? DriverProLogo
    : DriverLogo;

  const MainNavbarClass = ['main_navbar'];
  MainNavbarClass.push(main ? 'main' : driverPro ? 'driver-pro' : 'driver');

  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const Data = useStaticQuery(graphql`
    query GetNavbarData {
      driverJson {
        HEADER_MENU_ITEMS {
          label
          path
        }
      }
    }
  `);

  const HeaderItem = Data?.driverJson?.HEADER_MENU_ITEMS;

  return (
    <NavbarWrapper {...navbarStyle} className={MainNavbarClass.join(' ')}>
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={DriverLogoFinal}
            title="Driver Logo"
            logoStyle={logoStyle}
            className="driver-logo"
          />
          <Box {...menuWrapper} className="main-menu-wrapper">
            <ScrollMenu className="main_menu" menuItems={HeaderItem} />
            <Link
              href={driverPro ? StoreLink(driverPro) : StoreLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="navbar_button mobile"
            >
              <Button {...button} title="Télécharger l'application" />
            </Link>
            {/* <div className="top-btn desktop">
              <a
                href="https://apps.apple.com/fr/app/oovoom-driver/id1622651894"
                target="_blank"
                rel="noopener noreferrer"
                class="navbar_button"
                display="inline-block"
              >
                <button type="button" class="ceuYGn class-button">
                  <img src={AppStoreIcon}></img>
                  <span class="btn-text ios">App Store</span>
                </button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.driverapp.user&hl=fr&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                class="navbar_button"
                display="inline-block"
              >
                <button type="button" class="ceuYGn class-button">
                  <img src={GoogleIcon}></img>
                  <span class="btn-text">Google Play</span>
                </button>
              </a>
            </div> */}
            <nav id="mainnav" class="mainnav">
              <ul class="menu">
                <li>
                  <a
                    href="https://www.facebook.com/OovoomDriver/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="navbar_button"
                    display="inline-block"
                  >
                    <button type="button" class="ceuYGn class-button">
                      <span class="btn-text">Télécharger l'application</span>
                    </button>
                  </a>
                  <ul class="submenu">
                    <li>
                      <a
                        href="https://apps.apple.com/fr/app/oovoom-driver/id1622651894"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="navbar_button"
                        display="inline-block"
                      >
                        <button type="button" class="ceuYGn class-button">
                          <img
                            style="max-width: 15px;"
                            src={AppStoreIcon}
                          ></img>
                          <span class="btn-text ios">App Store</span>
                        </button>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.driverapp.user&hl=fr&gl=US"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="navbar_button"
                        display="inline-block"
                      >
                        <button type="button" class="ceuYGn class-button">
                          <img style="max-width: 15px;" src={GoogleIcon}></img>
                          <span class="btn-text">Google Play</span>
                        </button>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <Drawer
              placement="right driver-home"
              drawerHandler={<HamburgerMenu />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollMenu
                className="mobile_menu"
                menuItems={HeaderItem}
                drawerClose={true}
              />
              <Link
                href={driverPro ? StoreLink(driverPro) : StoreLink()}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar_button btn-mobile"
              >
                <Button {...button} title="Télécharger l'application" />
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['165px', '165px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
