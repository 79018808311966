import React from 'react';
import PropTypes from 'prop-types';
import NavbarStyle from './navbar.style';

const Navbar = ({ className, children, navbarStyle, ...props }) => {
  const allClasses = ['rc-navbar'];

  if (className) {
    allClasses.push(className);
  }

  return (
    <NavbarStyle className={allClasses.join(' ')} {...props}>
      {children}
    </NavbarStyle>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,

  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),

  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),

  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),

  borderRadius: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),

  boxShadow: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
};

Navbar.defaultProps = {};

export default Navbar;
