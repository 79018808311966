import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useAlert } from 'react-alert';

import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Container from 'common/components/Container';
import Text from 'common/components/Text';
import Input from 'common/components/Input';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';

import Social from '../Social';

import FooterWrapper, {
  ExtraFooterWrapper,
  List,
  ListItem,
  Newsletter,
} from './footer.style';
import { getURL } from 'api';

const DriverImage = 'image/driver-logo-white.svg';
const AddSymbol = 'image/add-symbol.svg';

const Footer = ({
  row,
  col1,
  col2,
  copyright,
  newsletterContainer,
  newsletterTitle,
  newsletterButton,
  driver,
}) => {
  const Data = useStaticQuery(graphql`
    query GetFooterData {
      driverJson {
        menuWidget {
          id
          url
          text
        }
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);
  const alert = useAlert();
  const [emailSubscribe, setEmailSubscribe] = React.useState('');
  const SocialItems = Data?.driverJson.SOCIAL_PROFILES;
  const MenuItems = Data?.driverJson.menuWidget;

  const backGroundStyle = driver
    ? { background: '#FF9D00' }
    : { background: '#000000' };
  const handleSubmit = async () => {
    // Validate email
    const testRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (testRegex.test(emailSubscribe)) {
      try {
        const apiUrl = getURL();
        fetch(`${apiUrl}/newsletter-subscription`, {
          method: 'POST',
          body: JSON.stringify({ email: emailSubscribe }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then(async (res) => {
          const data = await res.json();
          if (data?.data && data?.code === 200) {
            alert.show("S'abonner");
            alert.show('Vous vous êtes abonné avec succès.');
          } else if (data?.code === 400) {
            alert.show("S'abonner");
            alert.show(data?.message || 'Veuillez réessayer plus tard.');
          }
        });
      } catch (e) {
        alert.show("S'abonner");
        alert.error(e?.message || 'Veuillez réessayer plus tard.');
      }
    } else {
      alert.show("S'abonner");
      alert.error("S'il vous plaît, mettez une adresse email valide.");
    }
  };
  return (
    <Fragment>
      <FooterWrapper style={backGroundStyle} id="footerSection">
        <Container noGutter mobileGutter width="1200px">
          <Box className="row" {...row}>
            <Box className="footer-left" {...col1}>
              <Image src={DriverImage} alt="Oovoom Driver Icon" />
              {/* <Box className="imageWrapper">
                <a href="#1">
                  <Image src={iOSImage} alt="iOS Icon" />
                </a>

                <a href="#2">
                  <Image src={AndroidImage} alt="Android Icon" />
                </a>
              </Box> */}
              <Box>
                <Social
                  className="footer-social"
                  items={SocialItems}
                  iconSize={18}
                />
              </Box>
            </Box>
            <Box className="footer-center" {...col2}>
              <Box className="col" {...col2}>
                <Heading
                  as="h3"
                  content="Inscrivez-vous à notre newsletter"
                  {...newsletterTitle}
                />
                <Newsletter {...newsletterContainer}>
                  <Input
                    inputType="email"
                    placeholder="Adresse email"
                    iconPosition="right"
                    isMaterial={false}
                    className="email_input"
                    aria-label="email"
                    onChange={(email) => setEmailSubscribe(email)}
                  />
                  <Button
                    {...newsletterButton}
                    icon={<Image src={AddSymbol} alt="add-email-icon" />}
                    onClick={handleSubmit}
                  />
                </Newsletter>
              </Box>
            </Box>
            <Box className="footer-right" {...col2}>
              <Box className="col" {...col2}>
                <List>
                  {MenuItems.map((item) => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a href={item.url} className="ListItem">
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Container>
      </FooterWrapper>
      <ExtraFooterWrapper id="footerSection">
        <Text
          {...copyright}
          className="footer-copyright"
          content="© 2022 OOVOOM Driver"
        />
      </ExtraFooterWrapper>
    </Fragment>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  copyright: PropTypes.object,
  newsletterTitle: PropTypes.object,
  newsletterButton: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    justifyContent: 'space-evenly',
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  col1: {
    width: 'fit-content',
    mt: ['3%'],
    mb: ['50px', 0],
  },
  col2: {
    width: 'fit-content',
    mb: ['30px', 0],
    pr: ['0px', 0],
  },
  copyright: {
    fontSize: ['14px', '14px', '16px', '16px', '16px'],
    fontWeight: '400',
    color: '#FF9D00',
    lineHeight: '1.5',
    mb: '0',
    maxWidth: '100%',
    textAlign: 'center',
  },
  newsletterContainer: {
    height: 'fit-content',
  },
  newsletterTitle: {
    color: '#FFFFFF',
    fontSize: ['16px', '18px'],
    fontWeight: '600',
    mb: ['20px', '25px'],
  },
  newsletterButton: {
    backgroundColor: 'transparent',
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    maxHeight: 'fit-content',
    borderRadius: '20px',
  },
};

export default Footer;
