const devAPI = 'https://dev-driver-api.oovoom.com/api/v1';
const localAPI = 'http://localhost:8181/api/v1';
const prodAPI = 'https://driver-api.oovoom.com/api/v1';

export const getURL = () => {
  let urlApi = localAPI;
  if (window.location.hostname === 'localhost') {
    urlApi = localAPI;
  } else if (window.location.hostname === 'dev-fe.oovoomdriver.com') {
    urlApi = devAPI;
  } else if (window.location.hostname === 'oovoomdriver.com') {
    urlApi = prodAPI;
  }

  return urlApi;
};
