import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 2.5%;
  @media only screen and (max-width: 1360px) {
    padding-top: 10%;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 20%;
  }

  a {
    width: 100%;
    text-align-last: center;
  }

  a.button {
    width: 50%;
  }
`;

export default NotFoundWrapper;
