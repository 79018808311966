import React, { useEffect } from 'react';

export const GoogleTagManager: React.FC<{ gtmId: string }> = ({ gtmId }) => {
  useEffect(() => {
    // head
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // body
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe 
        src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
        height="0" 
        width="0" 
        style="display:none;visibility:hidden;">
      </iframe>
    `;
    document.body.appendChild(noscript);
    
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [gtmId]);

  return null; 
};

export const MetaPixel: React.FC<{ pixelId: string }> = ({ pixelId }) => {
  useEffect(() => {
    // Add Meta Pixel Script to head
    const fbPixelScript = document.createElement('script');
    fbPixelScript.type = 'text/javascript';
    fbPixelScript.async = true;
    fbPixelScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${pixelId});
      fbq('track', 'PageView');
    `;
    document.head.appendChild(fbPixelScript);

    // Add Meta Pixel noscript to body
    const fbPixelNoscript = document.createElement('noscript');
    fbPixelNoscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />
    `;
    document.head.appendChild(fbPixelNoscript);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(fbPixelScript);
      document.head.removeChild(fbPixelNoscript);
    };
  }, [pixelId]);

  return null;
};