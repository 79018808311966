import styled from 'styled-components';

export const SocialProfileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10% 0 0 15%;
  .linkedin-icon img {
    background-color: white;
    width: 20px;
    height: 20px;
  }
`;

export const SocialProfileItem = styled.div`
  margin-right: 18px;
  a {
    color: #ffffff;
    transition: 0.15s ease-in-out;
    &:hover {
      color: #ffffff;
      text-shadow: 0 1px 0px #ffffff;
    }
  }
`;
