import styled from 'styled-components';
import DriverMainImage from 'common/assets/image/main-driver-image.png';

const SectionWrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  background-image: url(${DriverMainImage});
  background-size: cover;
  align-items: center;
  margin-top: 2.5%;
  padding: 55px 70px 55px 0;
  @media only screen and (max-width: 1360px) {
    padding: 20px 30px 20px 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  .row {
    z-index: 999;
  }
  #absolute-box {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 40% 0, 55% 90%, 0% 100%);
    background: #ff9d00;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-left: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 30px;
    transform: rotateY(0deg);
  }
  .main_driver_phone {
    width: 60%;
    padding-right: 25px;
  }
  button {
    border-radius: 22.5px;
    margin-right: 2.5rem;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    &:hover {
      box-shadow: 3.3px 3.3px 2.5px 2.5px rgb(0 0 0 / 10%);
    }
  }
`;

export const ContentWrapper = styled.div`
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }
  #absolute-title {
    padding: 0 25% 0 15%;
    margin-top: 50%;
    text-transform: uppercase;
    font-size: 300%;
    font-stretch: expanded;
    font-weight: 800;
    text-align: center;
    color: #ffffff;
    line-height: 64px;
    letter-spacing: 8px;
  }
  h2 {
    letter-spacing: 1.5px;
    padding-left: 20%;
    @media only screen and (min-width: 1441px) {
      margin: 100px 0 100px;
    }
    @media only screen and (min-width: 1440px) {
      margin: 65px 0 65px;
    }
    @media only screen and (min-width: 767px) {
      margin: 50px 0 50px;
    }
  }
`;

export default SectionWrapper;
