const colors = {
  transparent: 'transparent',
  labelColor: '#747474',
  inactiveField: '#A9A9A9',
  inactiveButton: '#FFC47D',
  inactiveIcon: '#A9A9A9',
  primaryHover: '#FFAA00',
  secondary: '#000000',
  secondaryHover: '#333333',
  yellow: '#FFD700',
  yellowHover: '#F29E02',
  borderColor: '#A9A9A9',
  black: '#000000',
  white: '#FFFFFF',
  primary: '#FF9D00',
  headingColor: '#0F2137',
  quoteText: '#343D48',
  textColor: 'rgba(50, 60, 70, 0.8)',
  linkColor: '#2B9EDD',
};

export default colors;
