import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { StoreLink } from '../../common/tools';

import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Link from 'common/components/Link';

import SectionWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './maindriverpro.style';

const DriverProMainPhone = 'image/main-driver-pro-phone.png';

const MainDriverPro = ({
  sectionWrapper,
  head,
  row,
  col,
  button,
  driverPro,
}) => {
  const Data = useStaticQuery(graphql`
    query GetMainDriverProData {
      driverJson {
        featuresData {
          driverPro {
            buttonText2
            mainSlogan
          }
        }
      }
    }
  `);

  const { buttonText2, mainSlogan } = Data?.driverJson?.featuresData?.driverPro;

  return (
    <SectionWrapper
      {...sectionWrapper}
      className="features-block"
      id="driver-pro"
    >
      <Container width="1260px">
        <Box id="absolute-box-pro">
          <Fragment />
        </Box>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading
                as="h1"
                id="absolute-title-pro"
                {...head}
                dangerouslySetInnerHTML={{ __html: mainSlogan }}
              />
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image
                src={DriverProMainPhone}
                className="main_driver_pro_phone"
                alt="Main Driver Pro Phone"
              />
              <Link
                href={StoreLink(true)}
                target="_blank"
                rel="noopener noreferrer"
                id="driver_pro_main"
                className="main_button"
              >
                <Button {...button} title={buttonText2} />
              </Link>
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

MainDriverPro.propTypes = {
  sectionWrapper: PropTypes.object,
  head: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
};

MainDriverPro.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '180px'],
    pb: ['60px', '80px', '100px', '110px', '120px'],
  },
  head: {
    fontWeight: '800',
    textShadow: '0px 1.5px, 1.5px 0px, 1.5px 1.5px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    width: ['100%', '50%', '50%'],
    pl: '45px',
    pr: '15px',
    mb: '30px',
  },
  button: {
    type: 'button',
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
    borderRadius: '4px',
    pl: '35px',
    pr: '35px',
    minHeight: 'auto',
  },
};

export default MainDriverPro;
