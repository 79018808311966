import React from 'react';
import { SocialProfileWrapper, SocialProfileItem } from './social.style';
import Image from 'common/components/Image';

const LinkedinIcon = 'image/linkedin.png';

const Social = ({ items, className }) => {
  const addAllClasses = ['social_profiles'];

  if (className) {
    addAllClasses.push(className);
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(' ')}>
      {items.map((item, index) => (
        <SocialProfileItem
          key={`social-item-${index}`}
          className="social_profile_item"
        >
          <a href={item.url || '#'} aria-label="social icon">
            {item?.icon === 'flaticon-linkedin' ? (
              <Image
                src={LinkedinIcon}
                alt="linkedin-icon"
                className="linkedin-icon"
              />
            ) : (
              <i className={item.icon}></i>
            )}
          </a>
        </SocialProfileItem>
      ))}
    </SocialProfileWrapper>
  );
};

export default Social;
