import PropTypes from 'prop-types';
import React from 'react';

import Box from 'common/components/Box';
import Button from 'common/components/Button';
import Container from 'common/components/Container';
import Heading from 'common/components/Heading';
import Input from 'common/components/Input';
import './index.css';
import { useAlert } from 'react-alert';

import { useState } from 'react';
import ReservationWrapper, {
  ReservationFormWrapper,
} from './reservation.style';
const ReservationForm = ({
  boxBackground,
  textArea,
  col1,
  button,
  title,
  fullwhite,
  main,
  driverPro,
}) => {
  const alert = useAlert();

  const MAX_STOPS = 5;
  const [formData, setFormData] = useState({
    souhaitezVous: 'Non',
    lavage: 'Non',
    carburant: 'Non',
    intermediatePointAddress: [''],
    vehicle: 1,
  });

  const submitForm = async () => {
    const payload = {
      souhaitez_vous: formData.souhaitezVous === 'Oui',
      lavage: formData.lavage === 'Oui',
      carburant: formData.carburant === 'Oui',
      company_name: formData.companyName,
      name: formData.name,
      first_name: formData.firstName,
      phone_number: formData.phoneNumber,
      email: formData.email,
      vehicle: formData.vehicle,
      from_address: formData.fromAddress,
      from_date: formData.fromDate,
      from_time: formData.fromTime,
      to_address: formData.toAddress,
      to_date: formData.toDate,
      comment: formData.comment,
    };
    if (payload.souhaitez_vous) {
      payload.intermediate_point_address =
        formData.intermediatePointAddress?.join(';') || '';
    }
    try {
      let urlApi = `http://localhost:8181/api/v1`;
      if (window.location.hostname === 'localhost') {
        urlApi = `http://localhost:8181/api/v1`;
      } else if (window.location.hostname === 'dev-fe.oovoomdriver.com') {
        urlApi = `https://dev-driver-api.oovoom.com/api/v1`;
      } else if (window.location.hostname === 'oovoomdriver.com') {
        urlApi = `https://driver-api.oovoom.com/api/v1`;
      }
      fetch(`${urlApi}/reservation-contact`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async (res) => {
          const data = await res.json();
          if (res.status === 200) {
            alert.success('Soumise');
            alert.success('Soumettre le contact avec succès!');
          } else {
            alert.error('Erreur de soumission');
            alert.error(`Il y a une erreur ${data?.message}`);
          }
        })
        .catch(() => {});
    } catch (error) {
      console.log('error', error);
      alert.error(`Il y a une erreur ${error?.message}`);
    }
  };

  return (
    <Box
      {...boxBackground}
      style={
        fullwhite
          ? {
              background: '#FFFFFF',
              paddingTop: main ? '10%' : '0',
              marginTop: driverPro ? '-7.5%' : '0',
              zIndex: driverPro ? '999' : '1',
            }
          : {
              paddingTop: main ? '10%' : '0',
              marginTop: driverPro ? '-7.5%' : '0',
              zIndex: driverPro ? '999' : '1',
            }
      }
      id="contact"
      className="contact_box"
      as="section"
    >
      <Container>
        <ReservationWrapper>
          <Box {...textArea} {...col1}>
            <Heading
              as="h2"
              content="Réservez votre chauffeur en ligne"
              {...title}
            />
            <Heading
              as="h3"
              content="Vous souhaitez faire une réservation directement avec nos agents, sans passer par l'application, contactez-nous par téléphone, ou remplissez ce formulaire afin de prendre votre réservation"
              className="contact_title"
            />
            <ReservationFormWrapper>
              <Input
                label="Entreprise*"
                iconPosition="right"
                isMaterial={true}
                className="contact_input"
                arial-label="company name"
                onChange={(value) => {
                  setFormData({ ...formData, companyName: value });
                }}
              />
              <div className="time-wrapper">
                <Input
                  label="Nom *"
                  iconPosition="right"
                  isMaterial={true}
                  className="contact_input nom"
                  arial-label="name"
                  onChange={(value) => {
                    setFormData({ ...formData, name: value });
                  }}
                />
                <Input
                  label="Prénom *"
                  iconPosition="right"
                  isMaterial={true}
                  className="contact_input prenom"
                  arial-label="firstName"
                  onChange={(value) => {
                    setFormData({ ...formData, firstName: value });
                  }}
                />
              </div>
              <div className="time-wrapper">
                <Input
                  label="Téléphone *"
                  iconPosition="right"
                  isMaterial={true}
                  className="contact_input telephone"
                  arial-label="phoneNumber"
                  onChange={(value) => {
                    setFormData({ ...formData, phoneNumber: value });
                  }}
                />
                <Input
                  label="E-mail *"
                  iconPosition="right"
                  isMaterial={true}
                  className="contact_input email"
                  arial-label="email"
                  onChange={(value) => {
                    setFormData({ ...formData, email: value });
                  }}
                />
              </div>
            </ReservationFormWrapper>
            <Heading as="h4" content="Détails de la réservation" />
            <ReservationFormWrapper>
              <Input
                label="Combien de véhicule souhaitez-vous déplacer*"
                iconPosition="right"
                isMaterial={true}
                className="contact_input"
                arial-label="vehicles"
                inputType="number"
                value="1"
                min="1"
                onChange={(value) => {
                  setFormData({ ...formData, vehicle: value });
                }}
              />
              <Input
                label="Adresse de départ"
                iconPosition="right"
                isMaterial={true}
                className="contact_input address-input"
                inputType="address"
                onChange={(value) => {
                  setFormData({ ...formData, fromAddress: value });
                }}
              />
              <div className="time-wrapper">
                <Input
                  label="Date *"
                  iconPosition="right"
                  isMaterial={true}
                  className="contact_input"
                  arial-label="From date"
                  inputType="date-picker"
                  onChange={(value) => {
                    setFormData({ ...formData, fromDate: value });
                  }}
                />
                <Input
                  label="Heure *"
                  iconPosition="right"
                  isMaterial={true}
                  className="contact_input"
                  arial-label="Heure"
                  inputType="time-picker"
                  onChange={(value) => {
                    setFormData({ ...formData, fromTime: value });
                  }}
                />
              </div>

              <Input
                label="Adresse d'arrivée *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input address-input"
                inputType="address"
                onChange={(value) => {
                  setFormData({ ...formData, toAddress: value });
                }}
              />
              <Input
                inputType="date-picker"
                label="Date *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input comment_input"
                arial-label="To date"
                onChange={(value) => {
                  setFormData({ ...formData, toDate: value });
                }}
              />
              <Input
                inputType="dropdown"
                label="Souhaitez-vous ajouter des étapes *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input comment_input"
                arial-label="Souhaitez vous"
                placeholder="Select"
                value={formData.souhaitezVous}
                options={['Oui', 'Non']}
                onChange={(value) => {
                  setFormData({ ...formData, souhaitezVous: value.value });
                }}
              />

              {formData.souhaitezVous === 'Oui' && (
                <>
                  {formData.intermediatePointAddress.map((address, index) => {
                    return (
                      <div className="stop">
                        <Input
                          label={`Adresse de l'étape intermédiaire ${
                            formData.intermediatePointAddress.length > 1
                              ? `(arrêter ${index + 1})`
                              : ``
                          } *`}
                          iconPosition="right"
                          isMaterial={true}
                          className="contact_input"
                          arial-label="Adresse de letape intermediaire"
                          inputType="address"
                          onChange={(value) => {
                            const newIntermediatePointAddress =
                              formData.intermediatePointAddress;
                            newIntermediatePointAddress[index] = value;
                            setFormData({
                              ...formData,
                              intermediatePointAddress:
                                newIntermediatePointAddress,
                            });
                          }}
                        />
                        {index === 0 ? (
                          <Button
                            className="stop-button"
                            title="+"
                            onClick={() => {
                              if (
                                formData.intermediatePointAddress.length <
                                MAX_STOPS
                              ) {
                                setFormData({
                                  ...formData,
                                  intermediatePointAddress: [
                                    ...formData.intermediatePointAddress,
                                    '',
                                  ],
                                });
                              }
                            }}
                          />
                        ) : (
                          <Button
                            className="stop-button"
                            title="-"
                            onClick={() => {
                              if (
                                formData.intermediatePointAddress.length > 1
                              ) {
                                const newIntermediatePointAddress =
                                  formData.intermediatePointAddress;
                                newIntermediatePointAddress.pop();
                                setFormData({
                                  ...formData,
                                  intermediatePointAddress:
                                    newIntermediatePointAddress,
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              )}

              <Input
                inputType="dropdown"
                label="Lavage *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input comment_input"
                arial-label="Lavage"
                placeholder="Select"
                value={formData.lavage}
                options={['Oui', 'Non']}
                onChange={(value) => {
                  setFormData({ ...formData, lavage: value.value });
                }}
              />
              <Input
                inputType="dropdown"
                label="Carburant *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input comment_input"
                arial-label="Carburant"
                placeholder="Select"
                value={formData.lavage}
                options={['Oui', 'Non']}
                onChange={(value) => {
                  setFormData({ ...formData, carburant: value.value });
                }}
              />
              <Input
                inputType="textarea"
                label="Commentaires"
                iconPosition="right"
                isMaterial={true}
                className="contact_input comment_input"
                arial-label="Commentaires"
                onChange={(value) => {
                  setFormData({ ...formData, comment: value });
                }}
              />
              <Button {...button} title="Soumettre" onClick={submitForm} />
            </ReservationFormWrapper>
          </Box>
        </ReservationWrapper>
      </Container>
    </Box>
  );
};

ReservationForm.propTypes = {
  boxBackground: PropTypes.object,
  textArea: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  inlineCol: PropTypes.object,
  separator: PropTypes.object,
  iframe: PropTypes.object,
};

ReservationForm.defaultProps = {
  boxBackground: {
    background: 'linear-gradient(350deg, #FFFFFF 50%, #ff9d00 50%)',
    padding: '0 10% 10% 10%',
  },
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
  },
  col1: {
    width: ['90%', '90%', '90%', '100%', '100%'],
  },
  col2: {
    width: ['90%', '90%', '90%', '40%', '40%'],
  },
  title: {
    fontSize: ['24px', '26px', '28px', '30px', '32px'],
    fontWeight: '800',
    color: '#000000',
    lineHeight: '1.34',
    mb: ['10%', '10%', '7.5%', '5%', '5%'],
    textAlign: 'center',
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    fontWeight: '400',
    color: '#F98800',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  button: {
    borderRadius: '25px',
    padding: '0 10%',
    backgroundColor: '#FF9D00',
  },
  inlineCol: {
    display: 'inline-flex',
    width: '100%',
  },
  separator: {
    border: [
      'none',
      '2px solid #FF9D00',
      '2px solid #FF9D00',
      '2px solid #FF9D00',
      '2px solid #FF9D00',
    ],
    transform: 'rotate(90deg)',
    margin: [
      '1rem 0 1rem 0',
      '0 2.5rem 0 2.5rem',
      '0 2.5rem 0 2.5rem',
      '0 2.5rem 0 2.5rem',
      '0 2.5rem 0 2.5rem',
    ],
  },
  iframe: {
    pt: ['25%', '25%', '10%', '7.5%', '7.5%'],
  },
};

export default ReservationForm;
