import React from 'react';
import PropTypes from 'prop-types';

import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/Container';
import Text from 'common/components/Text';

import StaticWrapper from './static1.style';

const Static1 = ({ boxBackground, title }) => {
  return (
    <Box {...boxBackground} id="static" className="static_box" as="section">
      <Container>
        <StaticWrapper className="wrapper">
          <Heading
            as="h2"
            {...title}
            dangerouslySetInnerHTML={{
              __html: 'Conditions générales d’utilisation',
            }}
          />
          <Text className="static-text" content={null} />
        </StaticWrapper>
      </Container>
    </Box>
  );
};

Static1.propTypes = {
  boxBackground: PropTypes.object,
  text: PropTypes.object,
};

Static1.defaultProps = {
  boxBackground: {
    background: '#FFFFFF',
    padding: '10%',
  },
  title: {
    fontSize: ['24px', '26px', '28px', '30px', '32px'],
    fontWeight: '800',
    color: '#ff9d00',
    lineHeight: '1.34',
    mb: ['10%', '10%', '7.5%', '5%', '5%'],
    textAlign: 'center',
    letterSpacing: '-0.025em',
  },
};

export default Static1;
