import React from 'react';
import PropTypes from 'prop-types';
import HamburgerMenuWrapper from './hamburgerMenuWrapper.style';

const HamburgerMenu = ({ className, wrapperStyle, barColor, ...props }) => {
  const allClasses = ['hamburger-menu-bar'];

  if (className) {
    allClasses.push(className);
  }

  return (
    <HamburgerMenuWrapper
      className={allClasses.join(' ')}
      {...wrapperStyle}
      barColor={barColor}
      aria-label="hamburgerMenu"
      {...props}
    >
      <span />
      <span />
      <span />
    </HamburgerMenuWrapper>
  );
};

HamburgerMenu.propTypes = {
  className: PropTypes.string,
  barColor: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

export default HamburgerMenu;
