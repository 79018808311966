import styled from 'styled-components';

const SelectionWrapper = styled.section`
  .driver_logo,
  .driver_pro_logo {
    margin-top: 5%;
    margin-left: 30%;
    max-width: 55%;
  }
  .driver_logo {
    margin-left: 7.5%;
  }
  .driver_pro_image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .userBlock,
  .driverBlock {
    max-height: 100%;
    width: 50%;
  }
  .userBlock {
    background: linear-gradient(to right, #ff9d00, #ff9d00);
    @media (min-width: 556px) {
      width: calc(57% + 10px);
      margin-right: -20%;
      clip-path: polygon(0 0, 66% 0, 100% 100%, 0% 100%);
      border-radius: 25px 5px 5px 25px;
    }
  }
  .driverBlock {
    background-color: #000000;
    @media (min-width: 556px) {
      width: 57%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 35% 100%);
      border-radius: 5px 25px 25px 5px;
    }
  }
  .sideTitleWrapper {
    width: 100%;
    .selector-title {
      margin-top: 5%;
      font-style: italic;
      text-transform: uppercase;
      text-shadow: 0px 0.75px, 0.75px 0px, 0.75px 0.75px;
      font-size: 1.5rem;
      transition: 0.35s ease-in-out;
      z-index: 1;
    }
    .selector-title.driver {
      width: 50%;
      margin-left: 10%;
      color: black;
    }
    .selector-title.driver-pro {
      width: 50%;
      margin-left: 35%;
    }
  }
  .driver_image {
    width: 120%;
    margin-left: -20%;
  }
  .driver_pro_image {
    width: 66%;
    margin-left: 27.5%;
  }
`;

export default SelectionWrapper;
