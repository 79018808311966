import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { StoreLink } from '../../common/tools';

import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Link from 'common/components/Link';

import SectionWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './maindriver.style';

const DriverMainPhone = 'image/main-driver-phone.png';

const MainDriver = ({ sectionWrapper, head, row, col, button }) => {
  const Data = useStaticQuery(graphql`
    query GetMainDriverData {
      driverJson {
        featuresData {
          driver {
            buttonText2
            mainSlogan
          }
        }
      }
    }
  `);

  const { buttonText2, mainSlogan } = Data?.driverJson?.featuresData?.driver;

  return (
    <SectionWrapper {...sectionWrapper} className="features-block" id="driver">
      <Container width="1260px">
        <Box id="absolute-box">
          <Fragment />
        </Box>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image
                src={DriverMainPhone}
                className="main_driver_phone"
                alt="Main Driver Phone"
              />
              <Link
                href={StoreLink()}
                target="_blank"
                rel="noopener noreferrer"
                id="driver_main"
                className="main_button"
              >
                <Button {...button} title={buttonText2} />
              </Link>
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading
                as="h1"
                id="absolute-title"
                {...head}
                dangerouslySetInnerHTML={{ __html: mainSlogan }}
              />
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

MainDriver.propTypes = {
  sectionWrapper: PropTypes.object,
  head: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
};

MainDriver.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '180px'],
    pb: ['60px', '80px', '100px', '110px', '120px'],
  },
  head: {
    fontWeight: '800',
    textShadow: '0px 1.5px, 1.5px 0px, 1.5px 1.5px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    width: ['100%', '50%', '50%'],
    pl: '45px',
    pr: '15px',
    mb: '60px',
  },
  button: {
    type: 'button',
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
    borderRadius: '4px',
    pl: '35px',
    pr: '35px',
    minHeight: 'auto',
  },
};

export default MainDriver;
