import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import { useStaticQuery, graphql } from 'gatsby';

import Heading from 'common/components/Heading';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Separator from 'common/components/Separator';

import BlockWrapper, {
  Container,
  ContentWrapper,
  ImageWrapper,
} from './featuresdriverpro.style';

const FeatureDriverProPhone = 'image/features-driver-pro-phone.png';
const FeatureDriverProPhone2 = 'image/features-driver-pro-phone.svg';

const FeaturesDriverPro = ({
  head,
  head2,
  row,
  col2,
  col3,
  col4,
  text,
  text2,
}) => {
  const Data = useStaticQuery(graphql`
    query GetFeaturesDriverProData {
      driverJson {
        featuresData {
          driverPro {
            featureTitle
            featureTitle2
            featureTitle3
            featureDescription
            featureDescription2
            featureDescription3
            featureDescription4
            featureDescription5
          }
        }
      }
    }
  `);

  const {
    featureTitle,
    featureTitle2,
    featureTitle3,
    featureDescription,
    featureDescription2,
    featureDescription3,
    featureDescription4,
    featureDescription5,
  } = Data?.driverJson?.featuresData?.driverPro;

  return (
    <BlockWrapper className="features-block" id="driver">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col2}>
            <ImageWrapper>
              <Fade direction="left">
                <Image
                  src={FeatureDriverProPhone}
                  className="feature_driver_prophone"
                  alt="Driver Pro Features Phone"
                />
              </Fade>
            </ImageWrapper>
          </Box>
          <Box className="col" {...col2}>
            <ContentWrapper style={{ marginTop: '37.5%' }}>
              <Fade direction="right">
                <Heading
                  as="h2"
                  {...head}
                  dangerouslySetInnerHTML={{ __html: featureTitle }}
                />
                <Text {...text} content={featureDescription} />
                <Text {...text} content={featureDescription2} />
              </Fade>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>

      <Separator
        color={'#000000'}
        angle={6}
        height={'22%'}
        style={{ marginTop: '1px' }}
      />
      <Separator color={'#383838'} angle={6} height={'5%'} />
      <Separator color={'#6D6D6D'} angle={6} height={'2.5%'} />
      <Container
        width="1260px"
        style={{
          background: 'linear-gradient(180deg, #000000 0%, #1C1C1C 66.15%)',
          marginTop: '7.5%',
        }}
      >
        <Box className="row" {...row}>
          <Box className="col" {...col3}>
            <ContentWrapper
              style={{ textAlign: 'right', float: 'right', maxWidth: '60%' }}
            >
              <Fade direction="left">
                <Heading
                  as="h2"
                  {...head2}
                  dangerouslySetInnerHTML={{ __html: featureTitle2 }}
                />
                <Text {...text2} content={featureDescription3} />
              </Fade>
            </ContentWrapper>
          </Box>
          <Box className="col" {...col3}>
            <ImageWrapper>
              <Image
                src={FeatureDriverProPhone2}
                className="feature_driver_prophone"
                alt="Driver Pro Features Phone"
              />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col4}>
            <ContentWrapper
              style={{ textAlign: 'left', float: 'left', maxWidth: '65%' }}
            >
              <Fade direction="right">
                <Heading
                  as="h2"
                  {...head2}
                  dangerouslySetInnerHTML={{ __html: featureTitle3 }}
                />
                <Text {...text2} content={featureDescription4} />
                <Text {...text2} content={featureDescription5} />
              </Fade>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

FeaturesDriverPro.propTypes = {
  head: PropTypes.object,
  head2: PropTypes.object,
  row: PropTypes.object,
  col2: PropTypes.object,
  col3: PropTypes.object,
  col4: PropTypes.object,
  button: PropTypes.object,
  text: PropTypes.object,
  text2: PropTypes.object,
};

FeaturesDriverPro.defaultProps = {
  head: {
    width: '60%',
    fontWeight: '800',
    textShadow: '0px 1.5px, 1.5px 0px, 1.5px 1.5px',
    textAlign: 'left',
  },
  head2: {
    color: '#FF9D00',
    fontWeight: '800',
    textShadow: '0px 1px, 1px 0px, 1px 1px',
    letterSpacing: '1px',
  },
  row: {
    textAlign: 'center',
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    zIndex: '999',
  },
  col2: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
  },
  col3: {
    width: ['100%', '33%', '33%'],
  },
  col4: {
    width: ['100%', '33%', '33%'],
    pt: '12.5%',
  },
  text: {
    width: '50%',
    padding: '0 0 30px 0',
    color: '#FF9D00',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  text2: {
    color: '#FFFFFF',
    fontWeight: '800',
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'black',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    minHeight: 'auto',
  },
};

export default FeaturesDriverPro;
