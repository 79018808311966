import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Raleway', sans-serif;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  section {
    position: relative;
  }
  section:first-child {
    padding-top: 5%;
  }
  @media (max-width: 990px) {
    section:first-child {
      padding-top: 10%;
    }
  }
  .class-button {
    font-weight: 700;
  }
  .mobile{
    display: none;
  }
  .desktop{
    @media only screen and (max-width: 767px){
      display: none;
    }
  }
  .top-btn{
    display: flex;
    gap: 20px;
  }
  .drawer-right .drawer-content-wrapper{
    right: unset;
  }
  .btn-mobile button{
    position: absolute !important;
    top: 240px;
  }
  .container {
    max-width: 1360px;
    @media only screen and (max-width: 1600px) {
      max-width: 1170px;
      padding-left: 0;
      padding-right: 0;
    }
    @media(max-width: 1199px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media only screen and (max-width: 667px) {
      width: 100%;
    }
    @media only screen and (max-width: 667px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media only screen and (width: 375px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .driver-link,
  .driver-pro-link{
    h1{
      @media (max-width: 767px){
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }
  .fwHMKf button.download{
    @media (max-width: 767px){
      width: 20em !important;
    }
  }
  .jKNqLM .absolute-wrapper-driver{
    @media (max-width: 767px){
      align-items: center !important;
      left: 0 !important;
    }
  }
  .jKNqLM #absolute-title-driver,
  .jKNqLM #absolute-title{
    @media (max-width: 767px){
      font-size: 18px !important; 
    }
  }
  .jKNqLM #absolute-title-driver{
    @media (max-width: 767px){
      margin-left: 0 !important;
    }
  }
  .jKNqLM #absolute-title{
    @media (max-width: 767px){
      margin-left: 5em !important;
    }
  }
  .jKNqLM .absolute-wrapper{
    @media (max-width: 767px){
      margin-left: -5em !important;
      align-items: stretch !important;
    }
  }
  .jKNqLM #absolute-1,
  .jKNqLM #absolute-1-driver{
    @media (max-width: 767px){
      width: 410px !important;
    }
  }
  .jKNqLM #absolute-1-driver{
    @media (max-width: 767px){
      -webkit-clip-path: unset !important;
      clip-path: unset !important;
    }
  }

  .jKNqLM #absolute-2-driver{
    @media (max-width: 767px){
      margin-left: 115% !important;
      width: 3rem !important;
    }
  }
  .jKNqLM #absolute-3-driver{
    @media (max-width: 767px){
      margin-left: 130% !important;
      width: 3rem !important;
      }
  }
  .jKNqLM h2{
    @media (max-width: 767px){
      padding-left: 0 !important;
      }
  }
  .drawer-content-wrapper {
    @media (max-width: 767px) {
      width: 400px !important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li {
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a {
            font-size: 20px;
            font-weight: 400;
            color: #343D48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #FF9D00;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #FF9D00;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
        }
      }
    }

    .rc-drawer-close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #FF9D00;
        transform: rotate(45deg);
        display: block;
      }
    }
  }

  .sticky-nav-active {
    .main_navbar {
      .main_menu li a {
        color: #000000;
      }
      .rc-drawer-handler {
        .hamburger-menu-bar {
          > span {
            background-color: #FFFFFF;
          }
        }
      }
    }
  }

  .main_navbar.main {
    background: #FFFFFF;
    .navbar_button button {
      background-color: #FF9D00;
    }
    .rc-drawer-handler {
      .hamburger-menu-bar {
        > span {
          background-color: #FF9D00;
        }
      }
    }
  }
  .main_navbar.driver-pro {
    background: #1F1E1E;
    .navbar_button button {
      background-color: #FF9D00;
    }
    .main_menu li a {
      color: #FFFFFF;
      &:hover {
        color: #FF9D00;
        text-shadow: 0 1px 0px #FF9D00;
      }
    }
    .navbar_button button {
      background-color: #FFFFFF;
      &:hover {
        background-color: #FF9D00;
      }
    }
  }

  .driver-home{
    // right: 60%;
  }

  .main_navbar {
    background: #FF9D00;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 35px 0;

    .driver-logo {
      display: block;
    }
    .driver-sticky-logo {
      display: none;
    }
    .main-menu-wrapper {
      flex: 1 1 100%;

      @media (max-width: 991px) {
        flex: 0 0 auto;
        margin-left: auto;
      }
    }
    .main_menu {
      width: 80%;
      text-align: center;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        a {
          padding: 5px;
          transition: 0.15s ease-in-out;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          &:hover {
            color: #000000;
            text-shadow: 0 1px 0px #000000;
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button button {
      background-color: #FFFFFF;
      border: 2px solid #FFFFFF;
      color: #000000;
      font-size: 15px;
      min-width: max-content;
      font-weight: bold;
      border-radius: 22.5px;
      transiton: all 500ms ease;
      &:hover {
        background-color: #000000;
        border: 2px solid #000000;
        color: #FFFFFF;
      }

      @media (max-width: 990px) {
        display: none;
      }
    }
    .rc-drawer-handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburger-menu-bar {
        > span {
          background-color: #FFFFFF;
        }
      }
    }
  }
  // Submenu
  nav{display: block;}
  #mainnav ul {
      list-style: none;
      margin: 0;
      padding: 0;
  }
  #mainnav > ul > li {
      display: inline-block;
      padding: 0 ;
  }
  #mainnav ul li {
      position: relative;
  }
  #mainnav > ul > li > a {
      position: relative;
      display: block;
  }
  #mainnav ul.submenu {
      position: absolute;
      top: 150%;
      width: 100%;
      text-align: left;
      z-index: 9999;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
  #mainnav ul.submenu li:first-child {
      border-top: none;
  }
  #mainnav ul li ul li {
      margin-left: 0;
  }
  #mainnav ul.submenu > li > a {
      display: block;
  }
  #mainnav ul li:hover > ul.submenu {
      top: 105%;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      opacity: 1;
      visibility: visible;
  }
  .submenu li:first-child button{
    border-radius: 20px 20px 0 0;
  }
  .submenu li:last-child button{
    border-radius: 0 0 20px 20px;
  }
  .submenu button{
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    border: none !important;
    align-items: center;
  }
  .hiBWJK .contact_input.nom{
    width: 40%;
    @media (max-width: 767px){
      width: 100% !important;
    }
  }
  .hiBWJK .contact_input.prenom{
    width: 60%;
    @media (max-width: 767px){
      width: 100% !important;
    }
  }
  .hiBWJK .contact_input.telephone{
    width: 30%;
    @media (max-width: 767px){
      width: 100% !important;
    }
  }
  .hiBWJK .contact_input.email{
    width: 70%;
    @media (max-width: 767px){
      width: 100% !important;
    }
  }
  .time-wrapper{
    @media (max-width: 767px){
      flex-direction: column;
    }
  }
  .hiBWJK button.stop-button{
    @media (max-width: 767px){
      width: 20% !important;
    }
  }
`;

export const AppWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .sticky-active {
    .navbar {
      padding: 20px 0 21px;
      background-color: ${themeGet('colors.white', '#FFFFFF')};
      @media only screen and (max-width: 1366px) {
        padding: 15px 0 16px;
      }
      .mobile-menu {
        top: 72px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
`;

export default GlobalStyle;
