import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import Container from 'common/components/Container';
import Text from 'common/components/Text';

import ContactWrapper, { ContactFormWrapper } from './contact.style';

const ContactForm = ({
  boxBackground,
  textArea,
  col1,
  col2,
  button,
  title,
  inlineCol,
  separator,
  iframe,
  fullwhite,
  main,
  driverPro,
}) => {
  return (
    <Box
      {...boxBackground}
      style={
        fullwhite
          ? {
              background: '#FFFFFF',
              paddingTop: main ? '10%' : '0',
              marginTop: driverPro ? '-7.5%' : '0',
              zIndex: driverPro ? '999' : '1',
            }
          : {
              paddingTop: main ? '10%' : '0',
              marginTop: driverPro ? '-7.5%' : '0',
              zIndex: driverPro ? '999' : '1',
            }
      }
      id="contact"
      className="contact_box"
      as="section"
    >
      <Container>
        <ContactWrapper>
          <Box {...textArea} {...col1}>
            <Heading as="h2" content="Contactez-nous" {...title} />
            <ContactFormWrapper>
              <Input
                label="Nom *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input"
                arial-label="name"
              />
              <Input
                label="Prénom *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input"
                arial-label="surname"
              />
              <Input
                label="Téléphone *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input"
                arial-label="phone"
              />
              <Input
                label="E-mail *"
                iconPosition="right"
                isMaterial={true}
                className="contact_input"
                arial-label="email"
              />
              <Input
                inputType="textarea"
                label="Commentaire"
                iconPosition="right"
                isMaterial={true}
                className="contact_input comment_input"
                arial-label="comment"
              />
              <Button {...button} title="Envoyer" />
            </ContactFormWrapper>
          </Box>
          <Box {...col2}>
            <Box className="contact" {...inlineCol}>
              <Box>
                <Text className="title" content="Téléphone" />
                <a href="tel://+33180824444">
                  <Text content="01.82.28.67.99" />
                </a>
              </Box>
              <Box {...separator}>
                <Fragment />
              </Box>
              <Box>
                <Text className="title" content="E-mail" />
                <a href="mailto:contact@oovoomdriver.fr">
                  <Text content="contact@oovoomdriver.fr" />
                </a>
              </Box>
            </Box>
            <Box {...iframe}>
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10503.591424939636!2d2.261064!3d48.841087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67abc44da4cf9%3A0x775add463215c95f!2s33%20Rue%20Claude%20Lorrain%2C%2075016%20Paris%2C%20France!5e0!3m2!1sfr!2s!4v1692873686107!5m2!1sfr!2s"
                title="Oovoom Google Map"
                style={{ border: '0', borderRadius: '12px' }}
                width="100%"
                height="250px"
                loading="lazy"
              />
            </Box>
          </Box>
        </ContactWrapper>
      </Container>
    </Box>
  );
};

ContactForm.propTypes = {
  boxBackground: PropTypes.object,
  textArea: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  inlineCol: PropTypes.object,
  separator: PropTypes.object,
  iframe: PropTypes.object,
};

ContactForm.defaultProps = {
  boxBackground: {
    background: 'linear-gradient(350deg, #FFFFFF 50%, #ff9d00 50%)',
    padding: '0 10% 10% 10%',
  },
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
  },
  col1: {
    width: ['90%', '90%', '90%', '55%', '55%'],
  },
  col2: {
    width: ['90%', '90%', '90%', '40%', '40%'],
  },
  title: {
    fontSize: ['24px', '26px', '28px', '30px', '32px'],
    fontWeight: '800',
    color: '#000000',
    lineHeight: '1.34',
    mb: ['10%', '10%', '7.5%', '5%', '5%'],
    textAlign: 'center',
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    fontWeight: '400',
    color: '#F98800',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  button: {
    borderRadius: '25px',
    padding: '0 10%',
    backgroundColor: '#FF9D00',
  },
  inlineCol: {
    display: 'inline-flex',
    width: '100%',
  },
  separator: {
    border: [
      'none',
      '2px solid #FF9D00',
      '2px solid #FF9D00',
      '2px solid #FF9D00',
      '2px solid #FF9D00',
    ],
    transform: 'rotate(90deg)',
    margin: [
      '1rem 0 1rem 0',
      '0 2.5rem 0 2.5rem',
      '0 2.5rem 0 2.5rem',
      '0 2.5rem 0 2.5rem',
      '0 2.5rem 0 2.5rem',
    ],
  },
  iframe: {
    pt: ['25%', '25%', '10%', '7.5%', '7.5%'],
  },
};

export default ContactForm;
