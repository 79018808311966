import styled from 'styled-components';

const SectionWrapper = styled.div`
  margin-top: -2.5%;
  .diagonal {
    position: absolute;
    left: -5%;
    z-index: 500;
  }
  .mt-1-5percent {
    margin-top: -1.5%;
  }
  .mt-2-5percent {
    margin-top: -2.5%;
  }
  .mt-4-5percent {
    margin-top: -4.5%;
  }
`;

export default SectionWrapper;
