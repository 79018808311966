import React from 'react';
import PropTypes from 'prop-types';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Box from 'common/components/Box';
import Container from 'common/components/Container';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Link from 'common/components/Link';

import Phone1 from 'common/assets/image/phone1.png';
import Phone2 from 'common/assets/image/phone2.png';
import Phone3 from 'common/assets/image/phone3.png';

import CarrouselWrapper from './carrousel.style';

const images = [
  {
    original: `${Phone1}`,
    originalAlt: 'slide1',
  },
  {
    original: `${Phone2}`,
    originalAlt: 'slide2',
  },
  {
    original: `${Phone3}`,
    originalAlt: 'slide3',
  },
];

const AppStoreIcon = 'image/app-store-icon.svg';
const GooglePlayIcon = 'image/google-play-icon.svg';

const FeatureSlider = ({ flexContainer, alignCenter, title, button }) => {
  return (
    <CarrouselWrapper id="keyfeature">
      <div className="inner-carrousel">
        <span />
        <span />
        <span />
      </div>
      <Container
        {...flexContainer}
        noGutter
        mobileGutter
        width="100%"
        className="container"
      >
        <Box className="carrousel">
          <ImageGallery
            // lib prop list: https://www.npmjs.com/package/react-image-gallery
            items={images}
            className="slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay
          />
          <Box className="below-carrousel" {...alignCenter}>
            <Heading
              {...title}
              as="h2"
              dangerouslySetInnerHTML={{ __html: "Télécharger l'application" }}
            />
            <Link
              href="https://apps.apple.com/fr/app/oovoom-driver-pro/id1618426125"
              target="_blank"
              rel="noopener noreferrer"
              id="driver_main"
              className="driver_pro_button"
            >
              <Button
                {...button}
                title="App Store"
                iconPosition="left"
                icon={<Image src={AppStoreIcon} alt="app-store-icon" />}
              />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.oovoom.driverproapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                {...button}
                title="Google Play"
                iconPosition="left"
                icon={<Image src={GooglePlayIcon} alt="google-play-icon" />}
              />
            </Link>
          </Box>
        </Box>
      </Container>
    </CarrouselWrapper>
  );
};

FeatureSlider.propTypes = {
  flexContainer: PropTypes.object,
  alignCenter: PropTypes.object,
  button: PropTypes.object,
  title: PropTypes.object,
};

FeatureSlider.defaultProps = {
  flexContainer: {
    flexBox: true,
  },
  alignCenter: {
    alignSelf: 'center',
    pb: '2.5%',
    width: '50%',
  },
  button: {
    type: 'button',
    backgroundColor: '#FFFFFF',
    borderRadius: '25px',
    fontSize: '18px',
    fontWeight: '600',
    color: 'black',
    pt: '10px',
    pb: '10px',
    pl: '15px',
    pr: '15px',
    m: '0 10px 0 10px',
    minHeight: 'auto',
  },
  title: {
    color: '#FF9D00',
  },
};

export default FeatureSlider;
