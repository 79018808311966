import styled from 'styled-components';

const ContactWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2.5px 2.5px rgb(0 0 0 / 10%);
  border-radius: 54px;
  position: relative;
  padding: 44px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1220px) {
    padding: 35px 40px;
  }

  @media (max-width: 575px) {
    padding: 35px 20px;
  }

  @media (max-width: 990px) {
    width: 100%
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

  .contact {
    text-align: center;
    justify-content: center;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    .title {
      color: #ff9d00;
      font-weight: 700;
    }

    p {
      color: #000000;
      padding-top: 5%;
    }
  }
`;

export const ContactFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .comment_input {
    width: 100% !important;
    .field_wrapper {
      padding: 25px;
    }
  }

  .contact_input {
    flex-grow: 1;
    width: 60%;
    padding: 20px 10px 20px 10px;

    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          font-size: 14px;
          color: #ff9d00;
        }
        .highlight {
          background: #ff9d00;
          height: 1px;
        }
      }
    }

    .highlight {
      background: rgba(255, 255, 255, 0.4);
    }

    input {
      background: transparent;
      font-size: 16px;
      font-weight: 400;
      color: #908f8f;
      padding: 10px 10px 10px 5px;
      border-bottom-color: rgb(255, 157, 0);
      border-bottom-style: solid;
      border-bottom-width: 1.25px;
      @media (max-width: 575px) {
        height: 48px;
      }
    }

    label {
      font-size: 17px;
      color: #908f8f;
      font-weight: 400;
      padding-left: 10px;
      top: 5px;
      pointer-events: none;
    }
  }
`;

export default ContactWrapper;
