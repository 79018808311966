import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ButtonStyle from './button.style';
import Image from '../Image';
import Loader from '../Loader';

const Button = ({
  type,
  title,
  icon,
  image,
  disabled,
  iconPosition,
  onClick,
  loader,
  loaderColor,
  isMaterial,
  isLoading,
  className,
  ...props
}) => {
  const allClasses = ['class-button'];

  if (isLoading) {
    allClasses.push('is-loading');
  }

  if (className) {
    allClasses.push(className);
  }

  const buttonIcon =
    isLoading !== false ? (
      <Fragment>
        {loader ? loader : <Loader loaderColor={loaderColor} />}
      </Fragment>
    ) : (
      icon && <span className="btn-icon">{icon}</span>
    );

  const position = iconPosition || 'right';

  return (
    <ButtonStyle
      type={type}
      className={allClasses.join(' ')}
      icon={icon}
      disabled={disabled}
      icon-position={position}
      onClick={onClick}
      {...props}
    >
      {position === 'left' && buttonIcon}
      {title && <span className="btn-text">{title}</span>}
      {image && (
        <span className="btn-image">
          <Image src={image} className="button-image" alt="Button Image" />
        </span>
      )}
      {position === 'right' && buttonIcon}
    </ButtonStyle>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: PropTypes.object,
  loader: PropTypes.object,
  isMaterial: PropTypes.bool,
  isLoading: PropTypes.bool,
  loaderColor: PropTypes.string,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  variant: PropTypes.oneOf(['textButton', 'outlined']),

  colors: PropTypes.oneOf([
    'primary',
    'secondary',
    'warning',
    'error',
    'primaryWithBg',
    'secondaryWithBg',
    'warningWithBg',
    'errorWithBg',
  ]),

  onClick: PropTypes.func,
};

Button.defaultProps = {
  disabled: false,
  isMaterial: false,
  isLoading: false,
  type: 'button',
};

export default Button;
