import { useStaticQuery, graphql } from 'gatsby';

export const randomText = (length) => {
  const result = [];
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }

  return result.join('');
};

const getOsDetect = (userAgent) => {
  const isOperaMini = () => /Opera Mini/i.test(userAgent);
  const isIos = () => /iPhone|iPad|iPod/i.test(userAgent);
  const isAndroid = () => /Android/i.test(userAgent) || isOperaMini();
  const isMobile = () => Boolean(isAndroid() || isIos() || isOperaMini());
  const isDesktop = () => Boolean(!isMobile());

  if (isMobile()) {
    if (isAndroid()) {
      return 'android';
    }
    if (isIos()) {
      return 'ios';
    }

    return 'unknown mobile';
  }
  if (isDesktop()) {
    return 'desktop';
  }

  return 'unknown desktop';
};

export const useOsDetect = () => {
  const userAgent =
    typeof navigator === 'undefined' ? null : navigator.userAgent;
  return getOsDetect(userAgent);
};

export const StoreLink = (driverPro) => {
  const Data = useStaticQuery(graphql`
    query GetStoreLink {
      driverJson {
        STORE_LINKS {
          driver {
            ios
            android
          }
          driverpro {
            ios
            android
          }
          desktop
        }
      }
    }
  `);
  const AppLink = Data?.driverJson?.STORE_LINKS;
  const detectedOS = useOsDetect();
  if (detectedOS === 'ios') {
    return driverPro ? AppLink?.driverpro.ios : AppLink?.driver.ios;
  }
  if (detectedOS === 'android') {
    return driverPro ? AppLink?.driverpro.android : AppLink?.driver.android;
  }

  return AppLink?.desktop;
};
