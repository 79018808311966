import styled from 'styled-components';

const InputField = styled.div`
  position: relative;

  .field-wrapper {
    position: relative;
  }

  label {
    display: block;
    color: #a9a9a9;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: 0.2s ease all;
  }

  textarea,
  input {
    font-size: 16px;
    padding: 11px;
    display: block;
    width: 100%;
    color: #a9a9a9;
    box-shadow: none;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.2s ease;
    &:focus {
      outline: none;
      border-color: #a9a9a9;
    }
  }

  textarea {
    min-height: 150px;
  }

  &.is-material {
    label {
      position: absolute;
      left: 0;
      top: 10px;
    }

    input,
    textarea {
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    textarea {
      font-family: Raleway, 'sans-serif';
      border-color: #ff9d00;
      min-height: 100px;
      padding-bottom: 0;
    }

    .highlight {
      position: absolute;
      height: 1px;
      top: auto;
      left: 50%;
      bottom: 0;
      width: 0;
      pointer-events: none;
      transition: all 0.2s ease;
    }

    &.is-focus {
      input {
        border-color: #a9a9a9;
      }

      label {
        font-size: 12px;
        color: #a9a9a9;
      }
    }
  }
`;

export default InputField;
