import React from 'react';
import styled from 'styled-components';

import { ThemeProvider } from 'styled-components';

import { driverTheme } from 'common/theme/index';

import { ResetCSS } from 'common/assets/css/style';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import Seo from 'components/seo';

import { base, themed } from 'common/components/base';

import GlobalStyle, { AppWrapper } from 'containers/index.style.js';

import { useStaticQuery, graphql } from 'gatsby';
const ImageWrapper = styled('img')(base, themed('Image'));

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const Ambassadeur = () => {
  const Data = useStaticQuery(graphql`
    query GetAllAssetsAmbassadeur {
      images: allFile(
        filter: { extension: { regex: "/jpeg|jpg|png|gif|svg/" } }
      ) {
        edges {
          node {
            extension
            relativePath
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  const imageData = Data?.images?.edges?.find(
    (image) => image?.node?.relativePath === 'image/ambassadeur.png'
  );
  const image = imageData?.node;
  const imagePublicURL = image?.publicURL;

  return (
    <ThemeProvider theme={driverTheme}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Seo title="Ambassadeur" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <ImageWrapper
            src={imagePublicURL}
            alt={'Ambassadeur'}
            style={{
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
          ;
        </AppWrapper>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default Ambassadeur;
