// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ambassadeur-js": () => import("./../../../src/pages/ambassadeur.js" /* webpackChunkName: "component---src-pages-ambassadeur-js" */),
  "component---src-pages-cgu-js": () => import("./../../../src/pages/CGU.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-driver-js": () => import("./../../../src/pages/driver.js" /* webpackChunkName: "component---src-pages-driver-js" */),
  "component---src-pages-driver-pro-js": () => import("./../../../src/pages/driver-pro.js" /* webpackChunkName: "component---src-pages-driver-pro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pro-cgu-js": () => import("./../../../src/pages/ProCGU.js" /* webpackChunkName: "component---src-pages-pro-cgu-js" */),
  "component---src-pages-static-1-js": () => import("./../../../src/pages/static1.js" /* webpackChunkName: "component---src-pages-static-1-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-terms-conditions-pro-js": () => import("./../../../src/pages/terms-conditions-pro.js" /* webpackChunkName: "component---src-pages-terms-conditions-pro-js" */)
}

