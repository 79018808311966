import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { driverTheme } from 'common/theme/index';

import { ResetCSS } from 'common/assets/css/style';

import Seo from 'components/seo';

import TermsConditionsBackup from 'containers/TermsConditionsBackup';

import GlobalStyle from 'containers/index.style.js';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
const TermsConditionsBackupPage = ({ location }) => {
  const Data = useStaticQuery(graphql`
    query Static5PageData {
      driverJson {
        seoData {
          static1 {
            title
            description
          }
        }
      }
    }
  `);

  const { title, description } = Data?.driverJson?.seoData?.static1;

  return (
    <ThemeProvider theme={driverTheme}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Seo
          pageUrl={location.pathname}
          customTitle={title}
          customDescription={description}
        />

        <ResetCSS />
        <GlobalStyle />

        <TermsConditionsBackup />
      </AlertProvider>
    </ThemeProvider>
  );
};

export default TermsConditionsBackupPage;
